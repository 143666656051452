/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { ImageExtension } from '../model'
// @ts-ignore
import { ImageSigningRequest } from '../model'
// @ts-ignore
import { ImageType } from '../model'
// @ts-ignore
import { ProviderType } from '../model'
// @ts-ignore
import { UploadedImage } from '../model'
/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Sign an access URL to an image, and send a 307 Temporary Redirect to the signed URL.  (Meant to be used as `src` attribute of an `img` tag if the user is already logged-in.)
     * @summary Get Image
     * @param {ImageType} imageType
     * @param {string} imageUuid
     * @param {ImageExtension} extension
     * @param {ProviderType} [provider] Used with &#x60;ImageType.ITEM&#x60; and &#x60;ImageType.HERO&#x60;, ignored otherwise. Defaults to &#x60;ProviderType.UBER_EATS&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImageImagesImageTypeImageUuidExtensionGet: async (
      imageType: ImageType,
      imageUuid: string,
      extension: ImageExtension,
      provider?: ProviderType,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageType' is not null or undefined
      assertParamExists(
        'getImageImagesImageTypeImageUuidExtensionGet',
        'imageType',
        imageType
      )
      // verify required parameter 'imageUuid' is not null or undefined
      assertParamExists(
        'getImageImagesImageTypeImageUuidExtensionGet',
        'imageUuid',
        imageUuid
      )
      // verify required parameter 'extension' is not null or undefined
      assertParamExists(
        'getImageImagesImageTypeImageUuidExtensionGet',
        'extension',
        extension
      )
      const localVarPath = `/images/{image_type}/{image_uuid}.{extension}`
        .replace(`{${'image_type'}}`, encodeURIComponent(String(imageType)))
        .replace(`{${'image_uuid'}}`, encodeURIComponent(String(imageUuid)))
        .replace(`{${'extension'}}`, encodeURIComponent(String(extension)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (provider !== undefined) {
        localVarQueryParameter['provider'] = provider
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Batch sign access URLs for multiple images at once. Any image that is not found will be skipped and the empty string `\"\"` will be returned as the URL for that image.  The output array has the same size as the input array, and the order of the output array is the same as the input array.
     * @summary Sign Image Urls
     * @param {Array<ImageSigningRequest>} imageSigningRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signImageUrlsImagesSignPost: async (
      imageSigningRequest: Array<ImageSigningRequest>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageSigningRequest' is not null or undefined
      assertParamExists(
        'signImageUrlsImagesSignPost',
        'imageSigningRequest',
        imageSigningRequest
      )
      const localVarPath = `/images/sign`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        imageSigningRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Upload Images
     * @param {ImageType} imageType
     * @param {any} uberEats
     * @param {any} deliveroo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImagesImagesUploadPost: async (
      imageType: ImageType,
      uberEats: any,
      deliveroo: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageType' is not null or undefined
      assertParamExists('uploadImagesImagesUploadPost', 'imageType', imageType)
      // verify required parameter 'uberEats' is not null or undefined
      assertParamExists('uploadImagesImagesUploadPost', 'uberEats', uberEats)
      // verify required parameter 'deliveroo' is not null or undefined
      assertParamExists('uploadImagesImagesUploadPost', 'deliveroo', deliveroo)
      const localVarPath = `/images/upload`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)()

      if (imageType !== undefined) {
        localVarQueryParameter['image_type'] = imageType
      }

      if (uberEats !== undefined) {
        localVarFormParams.append('uber_eats', uberEats as any)
      }

      if (deliveroo !== undefined) {
        localVarFormParams.append('deliveroo', deliveroo as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Upload Single Image
     * @param {ImageType} imageType
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSingleImageImagesUploadImageTypePost: async (
      imageType: ImageType,
      image: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageType' is not null or undefined
      assertParamExists(
        'uploadSingleImageImagesUploadImageTypePost',
        'imageType',
        imageType
      )
      // verify required parameter 'image' is not null or undefined
      assertParamExists(
        'uploadSingleImageImagesUploadImageTypePost',
        'image',
        image
      )
      const localVarPath = `/images/upload/{image_type}`.replace(
        `{${'image_type'}}`,
        encodeURIComponent(String(imageType))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)()

      if (image !== undefined) {
        localVarFormParams.append('image', image as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
  return {
    /**
     * Sign an access URL to an image, and send a 307 Temporary Redirect to the signed URL.  (Meant to be used as `src` attribute of an `img` tag if the user is already logged-in.)
     * @summary Get Image
     * @param {ImageType} imageType
     * @param {string} imageUuid
     * @param {ImageExtension} extension
     * @param {ProviderType} [provider] Used with &#x60;ImageType.ITEM&#x60; and &#x60;ImageType.HERO&#x60;, ignored otherwise. Defaults to &#x60;ProviderType.UBER_EATS&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getImageImagesImageTypeImageUuidExtensionGet(
      imageType: ImageType,
      imageUuid: string,
      extension: ImageExtension,
      provider?: ProviderType,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getImageImagesImageTypeImageUuidExtensionGet(
        imageType,
        imageUuid,
        extension,
        provider,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Batch sign access URLs for multiple images at once. Any image that is not found will be skipped and the empty string `\"\"` will be returned as the URL for that image.  The output array has the same size as the input array, and the order of the output array is the same as the input array.
     * @summary Sign Image Urls
     * @param {Array<ImageSigningRequest>} imageSigningRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signImageUrlsImagesSignPost(
      imageSigningRequest: Array<ImageSigningRequest>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signImageUrlsImagesSignPost(
        imageSigningRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Upload Images
     * @param {ImageType} imageType
     * @param {any} uberEats
     * @param {any} deliveroo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadImagesImagesUploadPost(
      imageType: ImageType,
      uberEats: any,
      deliveroo: any,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedImage>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImagesImagesUploadPost(
        imageType,
        uberEats,
        deliveroo,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Upload Single Image
     * @param {ImageType} imageType
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadSingleImageImagesUploadImageTypePost(
      imageType: ImageType,
      image: any,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedImage>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSingleImageImagesUploadImageTypePost(
        imageType,
        image,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ImagesApiFp(configuration)
  return {
    /**
     * Sign an access URL to an image, and send a 307 Temporary Redirect to the signed URL.  (Meant to be used as `src` attribute of an `img` tag if the user is already logged-in.)
     * @summary Get Image
     * @param {ImageType} imageType
     * @param {string} imageUuid
     * @param {ImageExtension} extension
     * @param {ProviderType} [provider] Used with &#x60;ImageType.ITEM&#x60; and &#x60;ImageType.HERO&#x60;, ignored otherwise. Defaults to &#x60;ProviderType.UBER_EATS&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImageImagesImageTypeImageUuidExtensionGet(
      imageType: ImageType,
      imageUuid: string,
      extension: ImageExtension,
      provider?: ProviderType,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .getImageImagesImageTypeImageUuidExtensionGet(
          imageType,
          imageUuid,
          extension,
          provider,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Batch sign access URLs for multiple images at once. Any image that is not found will be skipped and the empty string `\"\"` will be returned as the URL for that image.  The output array has the same size as the input array, and the order of the output array is the same as the input array.
     * @summary Sign Image Urls
     * @param {Array<ImageSigningRequest>} imageSigningRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signImageUrlsImagesSignPost(
      imageSigningRequest: Array<ImageSigningRequest>,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .signImageUrlsImagesSignPost(imageSigningRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Upload Images
     * @param {ImageType} imageType
     * @param {any} uberEats
     * @param {any} deliveroo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImagesImagesUploadPost(
      imageType: ImageType,
      uberEats: any,
      deliveroo: any,
      options?: any
    ): AxiosPromise<UploadedImage> {
      return localVarFp
        .uploadImagesImagesUploadPost(imageType, uberEats, deliveroo, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Upload Single Image
     * @param {ImageType} imageType
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadSingleImageImagesUploadImageTypePost(
      imageType: ImageType,
      image: any,
      options?: any
    ): AxiosPromise<UploadedImage> {
      return localVarFp
        .uploadSingleImageImagesUploadImageTypePost(imageType, image, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ImagesApi - interface
 * @export
 * @interface ImagesApi
 */
export interface ImagesApiInterface {
  /**
   * Sign an access URL to an image, and send a 307 Temporary Redirect to the signed URL.  (Meant to be used as `src` attribute of an `img` tag if the user is already logged-in.)
   * @summary Get Image
   * @param {ImageType} imageType
   * @param {string} imageUuid
   * @param {ImageExtension} extension
   * @param {ProviderType} [provider] Used with &#x60;ImageType.ITEM&#x60; and &#x60;ImageType.HERO&#x60;, ignored otherwise. Defaults to &#x60;ProviderType.UBER_EATS&#x60;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApiInterface
   */
  getImageImagesImageTypeImageUuidExtensionGet(
    imageType: ImageType,
    imageUuid: string,
    extension: ImageExtension,
    provider?: ProviderType,
    options?: any
  ): AxiosPromise<void>

  /**
   * Batch sign access URLs for multiple images at once. Any image that is not found will be skipped and the empty string `\"\"` will be returned as the URL for that image.  The output array has the same size as the input array, and the order of the output array is the same as the input array.
   * @summary Sign Image Urls
   * @param {Array<ImageSigningRequest>} imageSigningRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApiInterface
   */
  signImageUrlsImagesSignPost(
    imageSigningRequest: Array<ImageSigningRequest>,
    options?: any
  ): AxiosPromise<Array<string>>

  /**
   *
   * @summary Upload Images
   * @param {ImageType} imageType
   * @param {any} uberEats
   * @param {any} deliveroo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApiInterface
   */
  uploadImagesImagesUploadPost(
    imageType: ImageType,
    uberEats: any,
    deliveroo: any,
    options?: any
  ): AxiosPromise<UploadedImage>

  /**
   *
   * @summary Upload Single Image
   * @param {ImageType} imageType
   * @param {any} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApiInterface
   */
  uploadSingleImageImagesUploadImageTypePost(
    imageType: ImageType,
    image: any,
    options?: any
  ): AxiosPromise<UploadedImage>
}

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI implements ImagesApiInterface {
  /**
   * Sign an access URL to an image, and send a 307 Temporary Redirect to the signed URL.  (Meant to be used as `src` attribute of an `img` tag if the user is already logged-in.)
   * @summary Get Image
   * @param {ImageType} imageType
   * @param {string} imageUuid
   * @param {ImageExtension} extension
   * @param {ProviderType} [provider] Used with &#x60;ImageType.ITEM&#x60; and &#x60;ImageType.HERO&#x60;, ignored otherwise. Defaults to &#x60;ProviderType.UBER_EATS&#x60;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public getImageImagesImageTypeImageUuidExtensionGet(
    imageType: ImageType,
    imageUuid: string,
    extension: ImageExtension,
    provider?: ProviderType,
    options?: any
  ) {
    return ImagesApiFp(this.configuration)
      .getImageImagesImageTypeImageUuidExtensionGet(
        imageType,
        imageUuid,
        extension,
        provider,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Batch sign access URLs for multiple images at once. Any image that is not found will be skipped and the empty string `\"\"` will be returned as the URL for that image.  The output array has the same size as the input array, and the order of the output array is the same as the input array.
   * @summary Sign Image Urls
   * @param {Array<ImageSigningRequest>} imageSigningRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public signImageUrlsImagesSignPost(
    imageSigningRequest: Array<ImageSigningRequest>,
    options?: any
  ) {
    return ImagesApiFp(this.configuration)
      .signImageUrlsImagesSignPost(imageSigningRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Upload Images
   * @param {ImageType} imageType
   * @param {any} uberEats
   * @param {any} deliveroo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public uploadImagesImagesUploadPost(
    imageType: ImageType,
    uberEats: any,
    deliveroo: any,
    options?: any
  ) {
    return ImagesApiFp(this.configuration)
      .uploadImagesImagesUploadPost(imageType, uberEats, deliveroo, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Upload Single Image
   * @param {ImageType} imageType
   * @param {any} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public uploadSingleImageImagesUploadImageTypePost(
    imageType: ImageType,
    image: any,
    options?: any
  ) {
    return ImagesApiFp(this.configuration)
      .uploadSingleImageImagesUploadImageTypePost(imageType, image, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
