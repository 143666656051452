/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CancelledOrder } from './cancelled-order'

/**
 *
 * @export
 * @interface DeliverooWebhookRequestCancelledOrder
 */
export interface DeliverooWebhookRequestCancelledOrder {
  /**
   *
   * @type {string}
   * @memberof DeliverooWebhookRequestCancelledOrder
   */
  event: DeliverooWebhookRequestCancelledOrderEventEnum
  /**
   *
   * @type {string}
   * @memberof DeliverooWebhookRequestCancelledOrder
   */
  cancelled_at: string
  /**
   *
   * @type {string}
   * @memberof DeliverooWebhookRequestCancelledOrder
   */
  location_id: string
  /**
   *
   * @type {CancelledOrder}
   * @memberof DeliverooWebhookRequestCancelledOrder
   */
  order: CancelledOrder
}

/**
 * @export
 * @enum {string}
 */
export enum DeliverooWebhookRequestCancelledOrderEventEnum {
  CancelOrder = 'cancel_order',
}
