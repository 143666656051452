














































































import Vue from 'vue'
import Logo from '@/components/Logo.vue'
import LogoVariant from '@/utils/enum-logo-variant'

import {
  authAPI,
  isLoggedInToKitchen,
  logIn,
  LogInError,
} from '@/utils/authentication'
import { Route } from 'vue-router/types/router'

export function getRedirectURL({ query }: Route, defaultRedirectURL = '/') {
  let redirectURL = defaultRedirectURL
  const redirect = query.redirect
  if (redirect) {
    // The redirect parameter was set
    if (redirect instanceof Array) {
      if (redirect[0]) redirectURL = redirect[0]
    } else {
      redirectURL = redirect
    }
  }
  return redirectURL
}

export default Vue.extend({
  components: {
    Logo,
  },
  async beforeRouteEnter(to, from, next) {
    await Vue.$accessor.auth.checkLogin()
    // `.isLoggedIn` might have changed since the last line
    if (isLoggedInToKitchen()) {
      // We are still logged-in, even after the check, we can redirect
      next(getRedirectURL(to))
    }
    // Else: We are logged-out, and we can proceed with the log-in page
    next()
  },
  data: () => ({
    username: '',
    password: '',
    passwordResetMode: false,
    errorMessage: null as null | string,
    loading: false,
  }),
  computed: {
    _LogoVariant: () => LogoVariant,
    isLoggedInToKitchenLazy: isLoggedInToKitchen,
  },
  methods: {
    togglePasswordResetMode() {
      this.passwordResetMode = !this.passwordResetMode
      this.password = ''
      this.errorMessage = null
    },
    formSubmit() {
      if (this.loading) return
      this.loading = true

      // Force username to be lowercase
      this.username = this.username.toLocaleLowerCase()

      if (this.passwordResetMode) {
        // Submit password request
        authAPI
          .requestPasswordResetAuthResetPost(this.username)
          .then(() => {
            this.$router.push({
              name: 'EmailOK',
              query: { username: this.username },
            })
            this.loading = false
          })
          .catch((reason) => {
            this.$sentry.captureException(reason)
            this.errorMessage =
              "Le mot de passe n'a pas pu être réinitialisé. " +
              'Merci de réessayer ultérieurement.'
            this.loading = false
          })
      } else {
        // Submit password
        const { username, password } = this
        logIn({ username, password }).then((logInError) => {
          if (logInError !== null) {
            // Show error to user
            switch (logInError) {
              case LogInError.InvalidUsernamePasswordPair:
                this.errorMessage =
                  "Le nom d'utilisateur ou le mot de passe est invalide."
                break
              case LogInError.FirebaseError:
                this.errorMessage =
                  'L’accès aux commandes a été refusé pour une raison inconnue. ' +
                  'Nos équipes en sont informées et sont déjà sur le coup.'
                this.$sentry.captureException(logInError, {
                  user: { username },
                })
                break
              case LogInError.NoResponse:
                this.errorMessage =
                  "Aucune réponse n'a été reçue. Veuillez vérifier votre connexion à internet."
                break
              case LogInError.NoKitchenAccess:
                this.errorMessage =
                  "Ce compte utilisateur n'a accès à aucune cuisine. Veuillez utiliser un autre compte."
                break
              case LogInError.InvalidParameters:
              case LogInError.Other:
                this.errorMessage =
                  'Une erreur inconnue est survenue. Merci de réessayer'
                break
              case LogInError.AmbiguousKitchenAccess:
                this.$router.push({
                  name: 'LoginEnvironment',
                  query: this.$route.query ?? {},
                })
                return
              default: {
                // Check that the check is exhaustive using the compiler
                const exhaustiveCheck: never = logInError
                throw new Error(`Unhandled error case: ${exhaustiveCheck}`)
              }
            }
          } else {
            // Successfully logged-in, redirect
            this.$router.push(getRedirectURL(this.$router.currentRoute))
          }
          this.loading = false
        })
      }
    },
  },
})
