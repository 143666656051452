








import Vue from 'vue'
import { isLoggedInToKitchen, updateSentryUser } from '@/utils/authentication'

export default Vue.extend({
  name: 'App',
  metaInfo: {
    title: 'Devor®',
  },

  created() {
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta?.progress) {
        const meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }

      const movingToDifferentPage = to.name !== from.name

      if (movingToDifferentPage) {
        this.$Progress.start()
      }

      updateSentryUser()
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // Some of the matched routes require authentication.
        const loginCheckPromise = movingToDifferentPage
          ? Vue.$accessor.auth.checkLogin()
          : Promise.resolve()
        loginCheckPromise.then(() => {
          updateSentryUser()
          if (isLoggedInToKitchen()) {
            return next()
          } else {
            return next({
              name: 'Login',
              query: { redirect: to.fullPath },
            })
          }
        })
      } else {
        return next() // `next()` must be called
      }
    })
    //  hook the progress bar to finish after we've finished moving router-view
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.$router.afterEach((to, _from) => {
      if (!to.matched.some((record) => !!record.meta.manualProgressFinish))
        //  finish the progress bar
        this.$Progress.finish()
    })
  },
})
