/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { DevorOrderInDB } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
/**
 * CustomerSupportApi - axios parameter creator
 * @export
 */
export const CustomerSupportApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Search Customer Order
     * @param {string} displayIdStr A display ID to look-up. Can only contain letters and number.
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 15. Defaults to 15.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCustomerOrderSupportBackofficeOrderGet: async (
      displayIdStr: string,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'displayIdStr' is not null or undefined
      assertParamExists(
        'searchCustomerOrderSupportBackofficeOrderGet',
        'displayIdStr',
        displayIdStr
      )
      const localVarPath = `/support/backoffice/order`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (displayIdStr !== undefined) {
        localVarQueryParameter['display_id_str'] = displayIdStr
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Search Middleoffice Customer Order
     * @param {string} [displayIdStr] A display ID to look-up. Can only contain letters and number.
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 50. Defaults to 15.
     * @param {Array<string>} [brands] A list of brand IDs to filter by. Ignored if the list is empty.
     * @param {string} [minDatetime] The minimum datetime to look for.
     * @param {string} [maxDatetime] The maximum datetime to look for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet: async (
      displayIdStr?: string,
      limit?: number,
      brands?: Array<string>,
      minDatetime?: string,
      maxDatetime?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/support/middleoffice/order`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (displayIdStr !== undefined) {
        localVarQueryParameter['display_id_str'] = displayIdStr
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (brands) {
        localVarQueryParameter['brands'] = brands
      }

      if (minDatetime !== undefined) {
        localVarQueryParameter['min_datetime'] =
          (minDatetime as any) instanceof Date
            ? (minDatetime as any).toISOString()
            : minDatetime
      }

      if (maxDatetime !== undefined) {
        localVarQueryParameter['max_datetime'] =
          (maxDatetime as any) instanceof Date
            ? (maxDatetime as any).toISOString()
            : maxDatetime
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CustomerSupportApi - functional programming interface
 * @export
 */
export const CustomerSupportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomerSupportApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Search Customer Order
     * @param {string} displayIdStr A display ID to look-up. Can only contain letters and number.
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 15. Defaults to 15.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchCustomerOrderSupportBackofficeOrderGet(
      displayIdStr: string,
      limit?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorOrderInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchCustomerOrderSupportBackofficeOrderGet(
        displayIdStr,
        limit,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Search Middleoffice Customer Order
     * @param {string} [displayIdStr] A display ID to look-up. Can only contain letters and number.
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 50. Defaults to 15.
     * @param {Array<string>} [brands] A list of brand IDs to filter by. Ignored if the list is empty.
     * @param {string} [minDatetime] The minimum datetime to look for.
     * @param {string} [maxDatetime] The maximum datetime to look for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
      displayIdStr?: string,
      limit?: number,
      brands?: Array<string>,
      minDatetime?: string,
      maxDatetime?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorOrderInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
        displayIdStr,
        limit,
        brands,
        minDatetime,
        maxDatetime,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * CustomerSupportApi - factory interface
 * @export
 */
export const CustomerSupportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CustomerSupportApiFp(configuration)
  return {
    /**
     *
     * @summary Search Customer Order
     * @param {string} displayIdStr A display ID to look-up. Can only contain letters and number.
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 15. Defaults to 15.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCustomerOrderSupportBackofficeOrderGet(
      displayIdStr: string,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<DevorOrderInDB>> {
      return localVarFp
        .searchCustomerOrderSupportBackofficeOrderGet(
          displayIdStr,
          limit,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Search Middleoffice Customer Order
     * @param {string} [displayIdStr] A display ID to look-up. Can only contain letters and number.
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 50. Defaults to 15.
     * @param {Array<string>} [brands] A list of brand IDs to filter by. Ignored if the list is empty.
     * @param {string} [minDatetime] The minimum datetime to look for.
     * @param {string} [maxDatetime] The maximum datetime to look for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
      displayIdStr?: string,
      limit?: number,
      brands?: Array<string>,
      minDatetime?: string,
      maxDatetime?: string,
      options?: any
    ): AxiosPromise<Array<DevorOrderInDB>> {
      return localVarFp
        .searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
          displayIdStr,
          limit,
          brands,
          minDatetime,
          maxDatetime,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CustomerSupportApi - interface
 * @export
 * @interface CustomerSupportApi
 */
export interface CustomerSupportApiInterface {
  /**
   *
   * @summary Search Customer Order
   * @param {string} displayIdStr A display ID to look-up. Can only contain letters and number.
   * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 15. Defaults to 15.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerSupportApiInterface
   */
  searchCustomerOrderSupportBackofficeOrderGet(
    displayIdStr: string,
    limit?: number,
    options?: any
  ): AxiosPromise<Array<DevorOrderInDB>>

  /**
   *
   * @summary Search Middleoffice Customer Order
   * @param {string} [displayIdStr] A display ID to look-up. Can only contain letters and number.
   * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 50. Defaults to 15.
   * @param {Array<string>} [brands] A list of brand IDs to filter by. Ignored if the list is empty.
   * @param {string} [minDatetime] The minimum datetime to look for.
   * @param {string} [maxDatetime] The maximum datetime to look for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerSupportApiInterface
   */
  searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
    displayIdStr?: string,
    limit?: number,
    brands?: Array<string>,
    minDatetime?: string,
    maxDatetime?: string,
    options?: any
  ): AxiosPromise<Array<DevorOrderInDB>>
}

/**
 * CustomerSupportApi - object-oriented interface
 * @export
 * @class CustomerSupportApi
 * @extends {BaseAPI}
 */
export class CustomerSupportApi
  extends BaseAPI
  implements CustomerSupportApiInterface {
  /**
   *
   * @summary Search Customer Order
   * @param {string} displayIdStr A display ID to look-up. Can only contain letters and number.
   * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 15. Defaults to 15.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerSupportApi
   */
  public searchCustomerOrderSupportBackofficeOrderGet(
    displayIdStr: string,
    limit?: number,
    options?: any
  ) {
    return CustomerSupportApiFp(this.configuration)
      .searchCustomerOrderSupportBackofficeOrderGet(
        displayIdStr,
        limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Search Middleoffice Customer Order
   * @param {string} [displayIdStr] A display ID to look-up. Can only contain letters and number.
   * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 50. Defaults to 15.
   * @param {Array<string>} [brands] A list of brand IDs to filter by. Ignored if the list is empty.
   * @param {string} [minDatetime] The minimum datetime to look for.
   * @param {string} [maxDatetime] The maximum datetime to look for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerSupportApi
   */
  public searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
    displayIdStr?: string,
    limit?: number,
    brands?: Array<string>,
    minDatetime?: string,
    maxDatetime?: string,
    options?: any
  ) {
    return CustomerSupportApiFp(this.configuration)
      .searchMiddleofficeCustomerOrderSupportMiddleofficeOrderGet(
        displayIdStr,
        limit,
        brands,
        minDatetime,
        maxDatetime,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
