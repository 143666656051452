import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { KitchenLightInDB } from '@/api-client'
import { getKitchens } from '@/utils/authentication'

const state = () => ({
  kitchens: {} as Record<string, KitchenLightInDB>,
})

const getters = getterTree(state, {
  kitchen:
    (state) =>
    (kitchenID: string): KitchenLightInDB | null =>
      state.kitchens[kitchenID] ?? null,
})

const mutations = mutationTree(state, {
  setKitchens(state, newKitchens: Record<string, KitchenLightInDB>) {
    state.kitchens = newKitchens
  },
  clearAll(state) {
    state.kitchens = {}
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    async updateKitchens({ commit }) {
      const kitchens = (await getKitchens()) ?? []
      const kitchensMapping = Object.fromEntries(
        kitchens.map((kitchen) => [kitchen._id, kitchen])
      )
      commit('setKitchens', kitchensMapping)
    },
  }
)

export const cache = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
