import { ScopeType } from '@/api-client/model/scope-type'
import { UserAccountScope } from '@/api-client/model/user-account-scope'

export function isValue<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

/** Return `true` if scopeType is a Kitchen scope */
export function isKitchenScopeType(
  scopeType: ScopeType
): scopeType is ScopeType.Kitchen {
  return scopeType == ScopeType.Kitchen
}

/** Return `true` if scope is a Kitchen scope */
export function isKitchenScope(
  scope: UserAccountScope
): scope is UserAccountScope & { scope_type: ScopeType.Kitchen } {
  return isKitchenScopeType(scope.scope_type)
}
