/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DeliverooOrder } from './deliveroo-order'

/**
 *
 * @export
 * @interface DeliverooWebhookRequestNewOrder
 */
export interface DeliverooWebhookRequestNewOrder {
  /**
   *
   * @type {string}
   * @memberof DeliverooWebhookRequestNewOrder
   */
  event: DeliverooWebhookRequestNewOrderEventEnum
  /**
   *
   * @type {string}
   * @memberof DeliverooWebhookRequestNewOrder
   */
  restaurant_acknowledged_at: string
  /**
   *
   * @type {string}
   * @memberof DeliverooWebhookRequestNewOrder
   */
  location_id: string
  /**
   *
   * @type {DeliverooOrder}
   * @memberof DeliverooWebhookRequestNewOrder
   */
  order: DeliverooOrder
}

/**
 * @export
 * @enum {string}
 */
export enum DeliverooWebhookRequestNewOrderEventEnum {
  NewOrder = 'new_order',
}
