/* eslint no-console: ["error", { allow: ["info"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Severity } from '@sentry/browser'
import { connectPrinter, EPosT } from '@/utils/epos-printing'

/**
 * The `epson` global variable was injected
 * by the ePOS SDK script included in public.html
 */
declare let epson: any

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function POSPrintPlugin(Vue: typeof _Vue): void {
  // Initialize App
  const ePos: EPosT = Vue.observable({
    device: new epson.ePOSDevice(),
    printer: null,
  })

  /** The category to log to Sentry breadcrumbs */
  const category = 'epos'

  function onDisconnect() {
    Sentry.addBreadcrumb({
      category,
      message: 'Device was disconnected.',
      data: {
        address: Vue.$accessor.settings.printerIP,
        port: Vue.$accessor.settings.printerPort,
      },
    })
    ePos.printer = null
    Vue.$accessor.printer.setDisconnected()
  }

  Vue.set(ePos.device, 'onreconnecting', () => {
    Vue.$accessor.printer.setConnecting()
    Sentry.addBreadcrumb({
      category: category + '.event',
      message: 'Device is reconnecting...',
      level: Severity.Log,
      data: {
        address: Vue.$accessor.settings.printerIP,
        port: Vue.$accessor.settings.printerPort,
      },
    })
    console.info('Device is reconnecting...')
  })
  Vue.set(ePos.device, 'onreconnect', () => {
    if (ePos.printer !== null) {
      Vue.$accessor.printer.setConnected()
      Sentry.addBreadcrumb({
        category: category + '.event',
        message: 'Printer is reconnected.',
        level: Severity.Log,
        data: {
          address: Vue.$accessor.settings.printerIP,
          port: Vue.$accessor.settings.printerPort,
        },
      })
      console.info('Device was successfully reconnected.')
    } else {
      onDisconnect()
    }
  })
  Vue.set(ePos.device, 'ondisconnect', onDisconnect)

  if (Vue.$accessor.settings.printerIP) {
    connectPrinter(
      {
        address: Vue.$accessor.settings.printerIP,
        port: Vue.$accessor.settings.printerPort,
      },
      ePos,
      // Avoid triggering a Sentry event on connection error,
      // since this connect was not triggered by the user:
      false
    )
  }
  // TODO: handle reconnect
  Vue.$ePos = ePos
  Vue.prototype.$ePos = ePos
}
