/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { AvailableRestaurant } from '../model'
// @ts-ignore
import { DevorOrderInDB } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
/**
 * FlyntApi - axios parameter creator
 * @export
 */
export const FlyntApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Get a list of all available restaurants (kitchen x brand), and their associated Franchisee, if any.
     * @summary Get All Restaurants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRestaurantsPartnersRestaurantsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/partners/restaurants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication HTTPBasic required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Fetch and return a list of orders using filtering parameters.  The `limit` parameter is the maximum number of orders to return. The limit is applied on the `placed_at` attribute, giving priority to older orders by default. Set `limit_by_latest` to `True` to give priority to newer orders.
     * @summary Get Orders History
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 2500. Defaults to 2000.
     * @param {Array<string>} [kitchens] A list of kitchen IDs to filter orders by. Ignored if the list is empty.
     * @param {Array<string>} [brands] A list of brand IDs to filter orders by. Ignored if the list is empty.
     * @param {string} [minDatetime] An optional lower bound on the orders &#x60;placed_at&#x60; attribute.
     * @param {string} [maxDatetime] An optional upper bound on the orders &#x60;placed_at&#x60; attribute.
     * @param {boolean} [limitByLatest] When set to true, will limit orders starting with the most recents first (meaning the older orders could be cut out by the limit).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersHistoryPartnersOrdersGet: async (
      limit?: number,
      kitchens?: Array<string>,
      brands?: Array<string>,
      minDatetime?: string,
      maxDatetime?: string,
      limitByLatest?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/partners/orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication HTTPBasic required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration)

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (kitchens) {
        localVarQueryParameter['kitchens'] = kitchens
      }

      if (brands) {
        localVarQueryParameter['brands'] = brands
      }

      if (minDatetime !== undefined) {
        localVarQueryParameter['min_datetime'] =
          (minDatetime as any) instanceof Date
            ? (minDatetime as any).toISOString()
            : minDatetime
      }

      if (maxDatetime !== undefined) {
        localVarQueryParameter['max_datetime'] =
          (maxDatetime as any) instanceof Date
            ? (maxDatetime as any).toISOString()
            : maxDatetime
      }

      if (limitByLatest !== undefined) {
        localVarQueryParameter['limit_by_latest'] = limitByLatest
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FlyntApi - functional programming interface
 * @export
 */
export const FlyntApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FlyntApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a list of all available restaurants (kitchen x brand), and their associated Franchisee, if any.
     * @summary Get All Restaurants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRestaurantsPartnersRestaurantsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AvailableRestaurant>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRestaurantsPartnersRestaurantsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Fetch and return a list of orders using filtering parameters.  The `limit` parameter is the maximum number of orders to return. The limit is applied on the `placed_at` attribute, giving priority to older orders by default. Set `limit_by_latest` to `True` to give priority to newer orders.
     * @summary Get Orders History
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 2500. Defaults to 2000.
     * @param {Array<string>} [kitchens] A list of kitchen IDs to filter orders by. Ignored if the list is empty.
     * @param {Array<string>} [brands] A list of brand IDs to filter orders by. Ignored if the list is empty.
     * @param {string} [minDatetime] An optional lower bound on the orders &#x60;placed_at&#x60; attribute.
     * @param {string} [maxDatetime] An optional upper bound on the orders &#x60;placed_at&#x60; attribute.
     * @param {boolean} [limitByLatest] When set to true, will limit orders starting with the most recents first (meaning the older orders could be cut out by the limit).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrdersHistoryPartnersOrdersGet(
      limit?: number,
      kitchens?: Array<string>,
      brands?: Array<string>,
      minDatetime?: string,
      maxDatetime?: string,
      limitByLatest?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorOrderInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersHistoryPartnersOrdersGet(
        limit,
        kitchens,
        brands,
        minDatetime,
        maxDatetime,
        limitByLatest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * FlyntApi - factory interface
 * @export
 */
export const FlyntApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FlyntApiFp(configuration)
  return {
    /**
     * Get a list of all available restaurants (kitchen x brand), and their associated Franchisee, if any.
     * @summary Get All Restaurants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRestaurantsPartnersRestaurantsGet(
      options?: any
    ): AxiosPromise<Array<AvailableRestaurant>> {
      return localVarFp
        .getAllRestaurantsPartnersRestaurantsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Fetch and return a list of orders using filtering parameters.  The `limit` parameter is the maximum number of orders to return. The limit is applied on the `placed_at` attribute, giving priority to older orders by default. Set `limit_by_latest` to `True` to give priority to newer orders.
     * @summary Get Orders History
     * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 2500. Defaults to 2000.
     * @param {Array<string>} [kitchens] A list of kitchen IDs to filter orders by. Ignored if the list is empty.
     * @param {Array<string>} [brands] A list of brand IDs to filter orders by. Ignored if the list is empty.
     * @param {string} [minDatetime] An optional lower bound on the orders &#x60;placed_at&#x60; attribute.
     * @param {string} [maxDatetime] An optional upper bound on the orders &#x60;placed_at&#x60; attribute.
     * @param {boolean} [limitByLatest] When set to true, will limit orders starting with the most recents first (meaning the older orders could be cut out by the limit).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersHistoryPartnersOrdersGet(
      limit?: number,
      kitchens?: Array<string>,
      brands?: Array<string>,
      minDatetime?: string,
      maxDatetime?: string,
      limitByLatest?: boolean,
      options?: any
    ): AxiosPromise<Array<DevorOrderInDB>> {
      return localVarFp
        .getOrdersHistoryPartnersOrdersGet(
          limit,
          kitchens,
          brands,
          minDatetime,
          maxDatetime,
          limitByLatest,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * FlyntApi - interface
 * @export
 * @interface FlyntApi
 */
export interface FlyntApiInterface {
  /**
   * Get a list of all available restaurants (kitchen x brand), and their associated Franchisee, if any.
   * @summary Get All Restaurants
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlyntApiInterface
   */
  getAllRestaurantsPartnersRestaurantsGet(
    options?: any
  ): AxiosPromise<Array<AvailableRestaurant>>

  /**
   * Fetch and return a list of orders using filtering parameters.  The `limit` parameter is the maximum number of orders to return. The limit is applied on the `placed_at` attribute, giving priority to older orders by default. Set `limit_by_latest` to `True` to give priority to newer orders.
   * @summary Get Orders History
   * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 2500. Defaults to 2000.
   * @param {Array<string>} [kitchens] A list of kitchen IDs to filter orders by. Ignored if the list is empty.
   * @param {Array<string>} [brands] A list of brand IDs to filter orders by. Ignored if the list is empty.
   * @param {string} [minDatetime] An optional lower bound on the orders &#x60;placed_at&#x60; attribute.
   * @param {string} [maxDatetime] An optional upper bound on the orders &#x60;placed_at&#x60; attribute.
   * @param {boolean} [limitByLatest] When set to true, will limit orders starting with the most recents first (meaning the older orders could be cut out by the limit).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlyntApiInterface
   */
  getOrdersHistoryPartnersOrdersGet(
    limit?: number,
    kitchens?: Array<string>,
    brands?: Array<string>,
    minDatetime?: string,
    maxDatetime?: string,
    limitByLatest?: boolean,
    options?: any
  ): AxiosPromise<Array<DevorOrderInDB>>
}

/**
 * FlyntApi - object-oriented interface
 * @export
 * @class FlyntApi
 * @extends {BaseAPI}
 */
export class FlyntApi extends BaseAPI implements FlyntApiInterface {
  /**
   * Get a list of all available restaurants (kitchen x brand), and their associated Franchisee, if any.
   * @summary Get All Restaurants
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlyntApi
   */
  public getAllRestaurantsPartnersRestaurantsGet(options?: any) {
    return FlyntApiFp(this.configuration)
      .getAllRestaurantsPartnersRestaurantsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Fetch and return a list of orders using filtering parameters.  The `limit` parameter is the maximum number of orders to return. The limit is applied on the `placed_at` attribute, giving priority to older orders by default. Set `limit_by_latest` to `True` to give priority to newer orders.
   * @summary Get Orders History
   * @param {number} [limit] A limit on the number of orders to return. The value must be between 1 and 2500. Defaults to 2000.
   * @param {Array<string>} [kitchens] A list of kitchen IDs to filter orders by. Ignored if the list is empty.
   * @param {Array<string>} [brands] A list of brand IDs to filter orders by. Ignored if the list is empty.
   * @param {string} [minDatetime] An optional lower bound on the orders &#x60;placed_at&#x60; attribute.
   * @param {string} [maxDatetime] An optional upper bound on the orders &#x60;placed_at&#x60; attribute.
   * @param {boolean} [limitByLatest] When set to true, will limit orders starting with the most recents first (meaning the older orders could be cut out by the limit).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlyntApi
   */
  public getOrdersHistoryPartnersOrdersGet(
    limit?: number,
    kitchens?: Array<string>,
    brands?: Array<string>,
    minDatetime?: string,
    maxDatetime?: string,
    limitByLatest?: boolean,
    options?: any
  ) {
    return FlyntApiFp(this.configuration)
      .getOrdersHistoryPartnersOrdersGet(
        limit,
        kitchens,
        brands,
        minDatetime,
        maxDatetime,
        limitByLatest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
