/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Meta } from './meta'

/**
 *
 * @export
 * @interface UberWebhookRequestCancelledOrder
 */
export interface UberWebhookRequestCancelledOrder {
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestCancelledOrder
   */
  event_id: string
  /**
   *
   * @type {Meta}
   * @memberof UberWebhookRequestCancelledOrder
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestCancelledOrder
   */
  event_time: string
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestCancelledOrder
   */
  resource_href: string
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestCancelledOrder
   */
  event_type: UberWebhookRequestCancelledOrderEventTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum UberWebhookRequestCancelledOrderEventTypeEnum {
  OrdersCancel = 'orders.cancel',
}
