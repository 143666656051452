import { getterTree, mutationTree, actionTree } from 'typed-vuex'

const state = () => ({
  printerIP: null as null | string,
  printerPort: '8043',
  lastSelectedKitchen: null as null | string,
  // Kitchen settings (with defaults)
  acknowledgementTimeSeconds: 180,
  kdsDisplayTimeSeconds: 90,
})

const getters = getterTree(state, {
  printerIP: (state) => state.printerIP,
  printerPort: (state) => state.printerPort,
  lastSelectedKitchen: (state) => state.lastSelectedKitchen,
  acknowledgementTimeSeconds: (state) => state.acknowledgementTimeSeconds,
  kdsDisplayTimeSeconds: (state) => state.kdsDisplayTimeSeconds,
})

const mutations = mutationTree(state, {
  setPrinterIP(state, newPrinterIP: string) {
    state.printerIP = newPrinterIP
  },
  setPrinterPort(state, newPrinterPort: string) {
    const valueNumber = Number(newPrinterPort)
    if (isNaN(valueNumber) || valueNumber < 0) return
    state.printerPort = String(Math.round(valueNumber))
  },
  resetPrinter(state) {
    state.printerIP = null
    state.printerPort = '8043'
  },
  setKitchenSelection(state, newKitchenSelection: string) {
    state.lastSelectedKitchen = newKitchenSelection
  },
  resetKitchenSelection(state) {
    state.lastSelectedKitchen = null
  },
  setAcknowledgementTimeSeconds(state, newValue: number) {
    state.acknowledgementTimeSeconds = newValue
  },
  setKdsDisplayTimeSeconds(state, newValue: number) {
    state.kdsDisplayTimeSeconds = newValue
  },
})

const actions = actionTree({ state, getters, mutations }, {})

export const settings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
