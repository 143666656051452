/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { UserAccountClient } from '../model'
// @ts-ignore
import { UserAccountClientBO } from '../model'
// @ts-ignore
import { UserAccountCreateWithPassword } from '../model'
// @ts-ignore
import { UserAccountDelete } from '../model'
// @ts-ignore
import { UserAccountUpdate } from '../model'
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create User
     * @param {UserAccountCreateWithPassword} userAccountCreateWithPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsersPost: async (
      userAccountCreateWithPassword: UserAccountCreateWithPassword,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userAccountCreateWithPassword' is not null or undefined
      assertParamExists(
        'createUserUsersPost',
        'userAccountCreateWithPassword',
        userAccountCreateWithPassword
      )
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userAccountCreateWithPassword,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Remove a user account.  The current user _must_ have the `backoffice` scope or all the scopes of the user they are trying to delete.  If the user is a master user for a franchisee, this call will fail with a `NotFoundError` (HTTP Error 400).
     * @summary Delete Franchisee User
     * @param {UserAccountDelete} userAccountDelete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFranchiseeUserUsersDelete: async (
      userAccountDelete: UserAccountDelete,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userAccountDelete' is not null or undefined
      assertParamExists(
        'deleteFranchiseeUserUsersDelete',
        'userAccountDelete',
        userAccountDelete
      )
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userAccountDelete,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get All Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsersUsersAllGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/all`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a list of all users associated to this Middleoffice user: users that use a kitchen of this franchisee, or users part of the same middleoffice.  Note: only works if the currently logged-in user has a Middleoffice scope.
     * @summary Get Associated Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedUsersUsersAssociatedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/associated`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get User
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUsersUsernameGet: async (
      username: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('getUserUsersUsernameGet', 'username', username)
      const localVarPath = `/users/{username}`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Mark Onboarded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markOnboardedUsersOnboardedPost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/onboarded`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update User Account
     * @param {UserAccountUpdate} userAccountUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAccountUsersPut: async (
      userAccountUpdate: UserAccountUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userAccountUpdate' is not null or undefined
      assertParamExists(
        'updateUserAccountUsersPut',
        'userAccountUpdate',
        userAccountUpdate
      )
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userAccountUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Create User
     * @param {UserAccountCreateWithPassword} userAccountCreateWithPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserUsersPost(
      userAccountCreateWithPassword: UserAccountCreateWithPassword,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserAccountClient>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUsersPost(
        userAccountCreateWithPassword,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Remove a user account.  The current user _must_ have the `backoffice` scope or all the scopes of the user they are trying to delete.  If the user is a master user for a franchisee, this call will fail with a `NotFoundError` (HTTP Error 400).
     * @summary Delete Franchisee User
     * @param {UserAccountDelete} userAccountDelete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFranchiseeUserUsersDelete(
      userAccountDelete: UserAccountDelete,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFranchiseeUserUsersDelete(
        userAccountDelete,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get All Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllUsersUsersAllGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<UserAccountClientBO>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsersUsersAllGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Get a list of all users associated to this Middleoffice user: users that use a kitchen of this franchisee, or users part of the same middleoffice.  Note: only works if the currently logged-in user has a Middleoffice scope.
     * @summary Get Associated Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociatedUsersUsersAssociatedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<UserAccountClient>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedUsersUsersAssociatedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get User
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserUsersUsernameGet(
      username: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserAccountClientBO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUsersUsernameGet(
        username,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Mark Onboarded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markOnboardedUsersOnboardedPost(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markOnboardedUsersOnboardedPost(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update User Account
     * @param {UserAccountUpdate} userAccountUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserAccountUsersPut(
      userAccountUpdate: UserAccountUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAccountUsersPut(
        userAccountUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration)
  return {
    /**
     *
     * @summary Create User
     * @param {UserAccountCreateWithPassword} userAccountCreateWithPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsersPost(
      userAccountCreateWithPassword: UserAccountCreateWithPassword,
      options?: any
    ): AxiosPromise<UserAccountClient> {
      return localVarFp
        .createUserUsersPost(userAccountCreateWithPassword, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Remove a user account.  The current user _must_ have the `backoffice` scope or all the scopes of the user they are trying to delete.  If the user is a master user for a franchisee, this call will fail with a `NotFoundError` (HTTP Error 400).
     * @summary Delete Franchisee User
     * @param {UserAccountDelete} userAccountDelete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFranchiseeUserUsersDelete(
      userAccountDelete: UserAccountDelete,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFranchiseeUserUsersDelete(userAccountDelete, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get All Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsersUsersAllGet(
      options?: any
    ): AxiosPromise<Array<UserAccountClientBO>> {
      return localVarFp
        .getAllUsersUsersAllGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a list of all users associated to this Middleoffice user: users that use a kitchen of this franchisee, or users part of the same middleoffice.  Note: only works if the currently logged-in user has a Middleoffice scope.
     * @summary Get Associated Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedUsersUsersAssociatedGet(
      options?: any
    ): AxiosPromise<Array<UserAccountClient>> {
      return localVarFp
        .getAssociatedUsersUsersAssociatedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get User
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUsersUsernameGet(
      username: string,
      options?: any
    ): AxiosPromise<UserAccountClientBO> {
      return localVarFp
        .getUserUsersUsernameGet(username, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Mark Onboarded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markOnboardedUsersOnboardedPost(options?: any): AxiosPromise<void> {
      return localVarFp
        .markOnboardedUsersOnboardedPost(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update User Account
     * @param {UserAccountUpdate} userAccountUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAccountUsersPut(
      userAccountUpdate: UserAccountUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserAccountUsersPut(userAccountUpdate, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
  /**
   *
   * @summary Create User
   * @param {UserAccountCreateWithPassword} userAccountCreateWithPassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  createUserUsersPost(
    userAccountCreateWithPassword: UserAccountCreateWithPassword,
    options?: any
  ): AxiosPromise<UserAccountClient>

  /**
   * Remove a user account.  The current user _must_ have the `backoffice` scope or all the scopes of the user they are trying to delete.  If the user is a master user for a franchisee, this call will fail with a `NotFoundError` (HTTP Error 400).
   * @summary Delete Franchisee User
   * @param {UserAccountDelete} userAccountDelete
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  deleteFranchiseeUserUsersDelete(
    userAccountDelete: UserAccountDelete,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get All Users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getAllUsersUsersAllGet(
    options?: any
  ): AxiosPromise<Array<UserAccountClientBO>>

  /**
   * Get a list of all users associated to this Middleoffice user: users that use a kitchen of this franchisee, or users part of the same middleoffice.  Note: only works if the currently logged-in user has a Middleoffice scope.
   * @summary Get Associated Users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getAssociatedUsersUsersAssociatedGet(
    options?: any
  ): AxiosPromise<Array<UserAccountClient>>

  /**
   *
   * @summary Get User
   * @param {string} username
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserUsersUsernameGet(
    username: string,
    options?: any
  ): AxiosPromise<UserAccountClientBO>

  /**
   *
   * @summary Mark Onboarded
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  markOnboardedUsersOnboardedPost(options?: any): AxiosPromise<void>

  /**
   *
   * @summary Update User Account
   * @param {UserAccountUpdate} userAccountUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateUserAccountUsersPut(
    userAccountUpdate: UserAccountUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
  /**
   *
   * @summary Create User
   * @param {UserAccountCreateWithPassword} userAccountCreateWithPassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUserUsersPost(
    userAccountCreateWithPassword: UserAccountCreateWithPassword,
    options?: any
  ) {
    return UsersApiFp(this.configuration)
      .createUserUsersPost(userAccountCreateWithPassword, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Remove a user account.  The current user _must_ have the `backoffice` scope or all the scopes of the user they are trying to delete.  If the user is a master user for a franchisee, this call will fail with a `NotFoundError` (HTTP Error 400).
   * @summary Delete Franchisee User
   * @param {UserAccountDelete} userAccountDelete
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteFranchiseeUserUsersDelete(
    userAccountDelete: UserAccountDelete,
    options?: any
  ) {
    return UsersApiFp(this.configuration)
      .deleteFranchiseeUserUsersDelete(userAccountDelete, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get All Users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getAllUsersUsersAllGet(options?: any) {
    return UsersApiFp(this.configuration)
      .getAllUsersUsersAllGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a list of all users associated to this Middleoffice user: users that use a kitchen of this franchisee, or users part of the same middleoffice.  Note: only works if the currently logged-in user has a Middleoffice scope.
   * @summary Get Associated Users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getAssociatedUsersUsersAssociatedGet(options?: any) {
    return UsersApiFp(this.configuration)
      .getAssociatedUsersUsersAssociatedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get User
   * @param {string} username
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserUsersUsernameGet(username: string, options?: any) {
    return UsersApiFp(this.configuration)
      .getUserUsersUsernameGet(username, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Mark Onboarded
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public markOnboardedUsersOnboardedPost(options?: any) {
    return UsersApiFp(this.configuration)
      .markOnboardedUsersOnboardedPost(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update User Account
   * @param {UserAccountUpdate} userAccountUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserAccountUsersPut(
    userAccountUpdate: UserAccountUpdate,
    options?: any
  ) {
    return UsersApiFp(this.configuration)
      .updateUserAccountUsersPut(userAccountUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
