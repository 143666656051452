import firebase from 'firebase/app'
import * as Sentry from '@sentry/vue'

import { DevorMoney } from '@/api-client/model/devor-money'
import { DevorFulfillmentType } from '@/api-client/model/devor-fulfillment-type'

// Polyfills
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/fr'
import { PrinterStatus } from './epos-printing'

export function timeFormatted(
  firebaseTimestamp: firebase.firestore.Timestamp
): string {
  return firebaseTimestamp.toDate().toLocaleTimeString(undefined, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function secondsIntervalFormatted(secondsDiff: number): string {
  const minuteDiff = Math.floor(secondsDiff / 60)
  const seconds = Math.floor(secondsDiff % 60)
  return (
    String(minuteDiff).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
  )
}

export function moneyFormatted(money: DevorMoney): string {
  let formatter
  const formatterOptions = {
    style: 'currency',
    currency: money.currency_code,
    currencyDisplay: 'narrowSymbol',
    // We do not define minimumFractionDigits to
    // let the formatter determine it from the currency.
  }
  try {
    formatter = Intl.NumberFormat('fr-FR', formatterOptions)
  } catch (e) {
    if (e instanceof RangeError) {
      // Probably Safari not supporting the narrowSymbol
      formatter = Intl.NumberFormat('fr-FR', {
        ...formatterOptions,
        currencyDisplay: 'symbol',
      })
    } else {
      // Unhandled error, rethrow it
      throw e
    }
  }
  // Since the amount is in subunit of the currency, we use
  // the formatter to determine wether the currency has digits.
  const minimumFractionDigits =
    formatter.resolvedOptions().minimumFractionDigits
  const valueFractional =
    minimumFractionDigits > 0
      ? money.amount / 10 ** minimumFractionDigits
      : money.amount
  return formatter.format(valueFractional)
}

export function formatPrinterStatus(printerStatus: PrinterStatus): string {
  switch (printerStatus) {
    case 'connected':
      return 'connectée'
    case 'disconnected':
      return 'déconnectée'
    case 'connecting':
      return 'connexion...'
  }
}

export function fulfillmentTypeFormatted(
  fulfillmentType: DevorFulfillmentType
): string {
  switch (fulfillmentType) {
    case DevorFulfillmentType.Delivery:
      return 'Livraison'
    case DevorFulfillmentType.PickUp:
      return 'À emporter'
    case DevorFulfillmentType.Other:
      return 'Autre'
    default: {
      const unknownFulfillmentType: never = fulfillmentType
      Sentry.captureMessage(
        `Unexpected fulfillment type encountered: ${unknownFulfillmentType}`
      )
      return '(Inconnu)'
    }
  }
}

export function formatItemTag(tagName: string): string {
  switch (tagName) {
    case 'untagged':
      return 'autres'
    default:
      return tagName
  }
}
