import firebase from 'firebase/app'
import 'firebase/firestore' // Required to use Timestamp
import { ProviderType } from '../api-client/model/provider-type'
import { DevorFulfillmentType } from '../api-client/model/devor-fulfillment-type'
import { DevorOrderAcceptationState } from '../api-client/model/devor-order-acceptation-state'
import { DevorOrderPreparationState } from '../api-client/model/devor-order-preparation-state'
import { GroupingTagType } from '../api-client'
import { FirestoreDevorOrder } from '@/interfaces/firestore'

/** This timestamp will be set to when the app first loaded */
const placedAtTimestamp = firebase.firestore.Timestamp.now()

export function buildTestOrder(): FirestoreDevorOrder {
  return {
    firestoreID: 'uO42OF2pOHR3KJ5nE0J3',
    display_id: '000X0',
    provider_order_id: 'DUMMY Provider ID',
    asap: true,
    is_remake: false,
    total_price: { amount: 3840, currency_code: 'EUR' },
    eater: {
      first_name: 'Prénom',
      last_name: 'N.',
      phone: '+33 6 12 34 56 78',
      phone_code: '123 45 678',
    },
    restaurant: {
      kitchen_id: '5ffadb21ca5edd5c39b724b8',
      kitchen_name: 'Test Kitchen',
      name: 'Dark Kitchen - Test Store',
      short_name: 'Test Store',
    },
    provider: ProviderType.UberEats,
    fulfillment_type: DevorFulfillmentType.Delivery,
    current_state: {
      acceptation: DevorOrderAcceptationState.Accepted,
      preparation: DevorOrderPreparationState.Finished,
    },
    placed_at: placedAtTimestamp,
    estimated_ready_for_pickup_at: firebase.firestore.Timestamp.now(),
    cart: {
      items: [
        {
          item_id: '6052248be1130fb878f15135',
          title: 'Dessert test',
          operational_title: 'Dessert test',
          show_on_kds: true,
          quantity: 1,
          price: {
            item_price: {
              unit_price: { amount: 400, currency_code: 'EUR' },
              total_price: { currency_code: 'EUR', amount: 400 },
            },
            item_excluding_options: {
              unit_price: { currency_code: 'EUR', amount: 400 },
              total_price: { amount: 400, currency_code: 'EUR' },
            },
          },
          special_requests: {},
          extra_instructions: ['Note sur le dessert'],
          grouping_tag: '604a28f5e64b63ce486692d7',
        },
        {
          item_id: '60522688e1130fb878f15137',
          title: 'Super Menu de test',
          operational_title: 'Menu test',
          show_on_kds: false,
          quantity: 1,
          price: {
            item_price: {
              unit_price: { amount: 3500, currency_code: 'EUR' },
              total_price: { currency_code: 'EUR', amount: 3500 },
            },
            item_excluding_options: {
              unit_price: { currency_code: 'EUR', amount: 3500 },
              total_price: { amount: 3500, currency_code: 'EUR' },
            },
          },
          special_requests: {},
          extra_instructions: [
            'Note 1 sur le menu de test',
            'Note 2 sur le menu de test',
          ],
          grouping_tag: '604a28f5e64b63ce486692dd',
          selected_modifier_groups: [
            {
              title: 'Burger Saint Burger',
              selected_items: [
                {
                  item_id: '6052271be1130fb878f15138',
                  title: 'Fabuleux Plat de test',
                  operational_title: 'Plat test',
                  show_on_kds: false,
                  quantity: 1,
                  price: {
                    item_price: {
                      unit_price: { amount: 400, currency_code: 'EUR' },
                      total_price: { currency_code: 'EUR', amount: 400 },
                    },
                    item_excluding_options: {
                      unit_price: { currency_code: 'EUR', amount: 400 },
                      total_price: { amount: 400, currency_code: 'EUR' },
                    },
                  },
                  special_requests: {},
                  grouping_tag: '604a28f5e64b63ce486692d9',
                  selected_modifier_groups: [
                    {
                      title: 'Suppl\u00e9ments',
                      selected_items: [
                        {
                          item_id: '60522749e1130fb878f15139',
                          title: 'Suppléments sur plat test',
                          operational_title: 'Suppléments sur plat test',
                          show_on_kds: false,
                          quantity: 1,
                          price: {
                            item_price: {
                              unit_price: {
                                amount: 400,
                                currency_code: 'EUR',
                              },
                              total_price: {
                                currency_code: 'EUR',
                                amount: 400,
                              },
                            },
                            item_excluding_options: {
                              unit_price: {
                                currency_code: 'EUR',
                                amount: 400,
                              },
                              total_price: {
                                amount: 400,
                                currency_code: 'EUR',
                              },
                            },
                          },
                          special_requests: {},
                          grouping_tag: '604a28f5e64b63ce486692dc',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: 'Ton deuxi\u00e8me burger \u00e0 prix choc !',
              selected_items: [
                {
                  item_id: '60522770e1130fb878f1513a',
                  title: 'Second plat test',
                  operational_title: 'Second plat test',
                  show_on_kds: false,
                  quantity: 1,
                  price: {
                    item_price: {
                      unit_price: { amount: 1400, currency_code: 'EUR' },
                      total_price: { currency_code: 'EUR', amount: 1400 },
                    },
                    item_excluding_options: {
                      unit_price: { currency_code: 'EUR', amount: 1400 },
                      total_price: { amount: 1400, currency_code: 'EUR' },
                    },
                  },
                  special_requests: {},
                  grouping_tag: '604a28f5e64b63ce486692d9',
                  selected_modifier_groups: [
                    {
                      title: 'Suppl\u00e9ments',
                      selected_items: [
                        {
                          item_id: '6052278ee1130fb878f1513b',
                          title: 'Suppléments second plat test',
                          operational_title: 'Suppléments second plat test',
                          show_on_kds: false,
                          quantity: 1,
                          price: {
                            item_price: {
                              unit_price: {
                                amount: 50,
                                currency_code: 'EUR',
                              },
                              total_price: {
                                currency_code: 'EUR',
                                amount: 50,
                              },
                            },
                            item_excluding_options: {
                              unit_price: {
                                currency_code: 'EUR',
                                amount: 50,
                              },
                              total_price: {
                                amount: 50,
                                currency_code: 'EUR',
                              },
                            },
                          },
                          special_requests: {},
                          grouping_tag: '604a28f5e64b63ce486692dc',
                        },
                        {
                          item_id: '605227e8e1130fb878f1513c',
                          title: 'Deuxième supplément second plat test',
                          operational_title:
                            'Deuxième supplément second plat test',
                          show_on_kds: false,
                          quantity: 1,
                          price: {
                            item_price: {
                              unit_price: {
                                amount: 400,
                                currency_code: 'EUR',
                              },
                              total_price: {
                                currency_code: 'EUR',
                                amount: 400,
                              },
                            },
                            item_excluding_options: {
                              unit_price: {
                                currency_code: 'EUR',
                                amount: 400,
                              },
                              total_price: {
                                amount: 400,
                                currency_code: 'EUR',
                              },
                            },
                          },
                          special_requests: {},
                          grouping_tag: '604a28f5e64b63ce486692dc',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: "Choix de l'accompagnement",
              selected_items: [
                {
                  item_id: '60522813e1130fb878f1513d',
                  title: 'Accompagnement',
                  operational_title: 'Accompagnement',
                  show_on_kds: false,
                  quantity: 1,
                  price: {
                    item_price: {
                      unit_price: { amount: 0, currency_code: 'EUR' },
                      total_price: { currency_code: 'EUR', amount: 0 },
                    },
                    item_excluding_options: {
                      unit_price: { currency_code: 'EUR', amount: 0 },
                      total_price: { amount: 0, currency_code: 'EUR' },
                    },
                  },
                  special_requests: {},
                  grouping_tag: '604a28f5e64b63ce486692da',
                  selected_modifier_groups: [
                    {
                      title: 'Choix de la sauce',
                      selected_items: [
                        {
                          item_id: '6052283fe1130fb878f1513e',
                          title: 'Supplément accompagnement de test',
                          operational_title: 'Supplément accompagnement',
                          show_on_kds: false,
                          quantity: 1,
                          price: {
                            item_price: {
                              unit_price: {
                                amount: 0,
                                currency_code: 'EUR',
                              },
                              total_price: {
                                currency_code: 'EUR',
                                amount: 0,
                              },
                            },
                            item_excluding_options: {
                              unit_price: {
                                currency_code: 'EUR',
                                amount: 0,
                              },
                              total_price: {
                                amount: 0,
                                currency_code: 'EUR',
                              },
                            },
                          },
                          special_requests: {},
                          grouping_tag: '604a28f5e64b63ce486692dc',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: 'Choix de la boisson',
              selected_items: [
                {
                  item_id: '6052285ee1130fb878f1513f',
                  title: 'Boisson de test',
                  operational_title: 'Boisson',
                  show_on_kds: false,
                  quantity: 1,
                  price: {
                    item_price: {
                      unit_price: { amount: 50, currency_code: 'EUR' },
                      total_price: { currency_code: 'EUR', amount: 50 },
                    },
                    item_excluding_options: {
                      unit_price: { currency_code: 'EUR', amount: 50 },
                      total_price: { amount: 50, currency_code: 'EUR' },
                    },
                  },
                  special_requests: {},
                  grouping_tag: '604a28f5e64b63ce486692d8',
                },
              ],
            },
          ],
        },
      ],
      special_instructions: ['Note globale'],
    },
    grouping_tags: [
      {
        name: 'menu',
        object_id: '604a28f5e64b63ce486692dd',
        should_ungroup: false,
        tag_type: GroupingTagType.Menu,
      },
      {
        name: 'main',
        object_id: '604a28f5e64b63ce486692d9',
        should_ungroup: false,
        tag_type: GroupingTagType.Product,
      },
      {
        name: 'side',
        object_id: '604a28f5e64b63ce486692da',
        should_ungroup: false,
        tag_type: GroupingTagType.Product,
      },
      {
        name: 'dessert',
        object_id: '604a28f5e64b63ce486692d7',
        should_ungroup: false,
        tag_type: GroupingTagType.Product,
      },
      {
        name: 'drink',
        object_id: '604a28f5e64b63ce486692d8',
        should_ungroup: false,
        tag_type: GroupingTagType.Product,
      },
      {
        name: 'sauce',
        object_id: '604a28f5e64b63ce486692db',
        should_ungroup: true,
        tag_type: GroupingTagType.Option,
      },
      {
        name: 'topping',
        object_id: '604a28f5e64b63ce486692dc',
        should_ungroup: false,
        tag_type: GroupingTagType.Option,
      },
    ],
  }
}
