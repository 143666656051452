import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import type { LoggedInUserWithScopes } from '@/api-client/model'
import { ScopeType } from '@/api-client/model'
import { getUser } from '@/utils/authentication'
import { accessor } from '.'
import { isKitchenScope, isValue } from '@/utils/helpers'

const state = () => ({
  user: null as null | LoggedInUserWithScopes,
})

const getters = getterTree(state, {
  username: (state) => state.user?.username ?? null,
  isLoggedIn: (state) => isValue(state.user),
  kitchenScopes: (state) => state.user?.scopes.filter(isKitchenScope) ?? null,
  hasMultipleKitchens: (state): boolean =>
    (state.user?.scopes.filter(isKitchenScope) || []).length > 1,
})

const mutations = mutationTree(state, {
  setUser(state, newUser: LoggedInUserWithScopes | null | undefined) {
    if (newUser)
      state.user = {
        ...newUser,
        // Filter scopes to save some time on subsequent filtering
        scopes: newUser.scopes.filter(isKitchenScope),
      }
    else state.user = null
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    async resetUser({ commit }) {
      commit('setUser', null)
    },
    async checkLogin({ commit }) {
      const user = await getUser()
      if (user) {
        if (
          // Ensure we have at least one Kitchen scope
          !user.scopes.some((scope) => scope.scope_type === ScopeType.Kitchen)
        ) {
          // Otherwise, delete user and return...
          commit('setUser', null)
          return
        }

        // Update kitchen names but do not wait
        accessor.cache.updateKitchens()
      }
      commit('setUser', user)
    },
  }
)

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
