/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { FranchiseClient } from '../model'
// @ts-ignore
import { FranchiseCreationResponse } from '../model'
// @ts-ignore
import { FranchiseDocumentType } from '../model'
// @ts-ignore
import { FranchiseInDB } from '../model'
// @ts-ignore
import { FranchiseeInfoCreate } from '../model'
// @ts-ignore
import { FranchiseeInfoUpdate } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
/**
 * FranchiseesApi - axios parameter creator
 * @export
 */
export const FranchiseesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Franchisee
     * @param {FranchiseeInfoCreate} franchiseeInfoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFranchiseeFranchiseesPost: async (
      franchiseeInfoCreate: FranchiseeInfoCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeInfoCreate' is not null or undefined
      assertParamExists(
        'createFranchiseeFranchiseesPost',
        'franchiseeInfoCreate',
        franchiseeInfoCreate
      )
      const localVarPath = `/franchisees`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        franchiseeInfoCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Franchisee
     * @param {string} franchiseeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFranchiseeFranchiseesFranchiseeIdDelete: async (
      franchiseeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'deleteFranchiseeFranchiseesFranchiseeIdDelete',
        'franchiseeId',
        franchiseeId
      )
      const localVarPath = `/franchisees/{franchisee_id}`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Associated Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesFullFranchiseesAssociatedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees/associated`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Associated Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesLightFranchiseesAssociatedLightGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees/associated/light`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Franchisee Document
     * @param {FranchiseDocumentType} documentType
     * @param {string} franchiseeId The ID of the franchisee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet: async (
      documentType: FranchiseDocumentType,
      franchiseeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentType' is not null or undefined
      assertParamExists(
        'getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet',
        'documentType',
        documentType
      )
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet',
        'franchiseeId',
        franchiseeId
      )
      const localVarPath = `/franchisees/{franchisee_id}/{document_type}`
        .replace(
          `{${'document_type'}}`,
          encodeURIComponent(String(documentType))
        )
        .replace(
          `{${'franchisee_id'}}`,
          encodeURIComponent(String(franchiseeId))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Franchisee
     * @param {string} franchiseeId The ID of the franchisee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseeFranchiseesFranchiseeIdGet: async (
      franchiseeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'getFranchiseeFranchiseesFranchiseeIdGet',
        'franchiseeId',
        franchiseeId
      )
      const localVarPath = `/franchisees/{franchisee_id}`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesFullFranchiseesFullGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees/full`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesLightFranchiseesGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Franchisee From Backoffice
     * @param {string} franchiseeId
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut: async (
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut',
        'franchiseeId',
        franchiseeId
      )
      // verify required parameter 'franchiseeInfoUpdate' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut',
        'franchiseeInfoUpdate',
        franchiseeInfoUpdate
      )
      const localVarPath = `/franchisees/{franchisee_id}`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        franchiseeInfoUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Franchisee From Middleoffice
     * @param {string} franchiseeId The ID of the franchisee
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut: async (
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut',
        'franchiseeId',
        franchiseeId
      )
      // verify required parameter 'franchiseeInfoUpdate' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut',
        'franchiseeInfoUpdate',
        franchiseeInfoUpdate
      )
      const localVarPath = `/franchisees/{franchisee_id}/me`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        franchiseeInfoUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Upload New Franchisee Document
     * @param {FranchiseDocumentType} documentType
     * @param {string} franchiseeId The ID of the franchisee
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut: async (
      documentType: FranchiseDocumentType,
      franchiseeId: string,
      file: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentType' is not null or undefined
      assertParamExists(
        'uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut',
        'documentType',
        documentType
      )
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut',
        'franchiseeId',
        franchiseeId
      )
      // verify required parameter 'file' is not null or undefined
      assertParamExists(
        'uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut',
        'file',
        file
      )
      const localVarPath = `/franchisees/{franchisee_id}/{document_type}`
        .replace(
          `{${'document_type'}}`,
          encodeURIComponent(String(documentType))
        )
        .replace(
          `{${'franchisee_id'}}`,
          encodeURIComponent(String(franchiseeId))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)()

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FranchiseesApi - functional programming interface
 * @export
 */
export const FranchiseesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FranchiseesApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Create Franchisee
     * @param {FranchiseeInfoCreate} franchiseeInfoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFranchiseeFranchiseesPost(
      franchiseeInfoCreate: FranchiseeInfoCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FranchiseCreationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFranchiseeFranchiseesPost(
        franchiseeInfoCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Franchisee
     * @param {string} franchiseeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFranchiseeFranchiseesFranchiseeIdDelete(
      franchiseeId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFranchiseeFranchiseesFranchiseeIdDelete(
        franchiseeId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Associated Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociatedFranchiseesFullFranchiseesAssociatedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseClient>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedFranchiseesFullFranchiseesAssociatedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Associated Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Franchisee Document
     * @param {FranchiseDocumentType} documentType
     * @param {string} franchiseeId The ID of the franchisee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
      documentType: FranchiseDocumentType,
      franchiseeId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
        documentType,
        franchiseeId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Franchisee
     * @param {string} franchiseeId The ID of the franchisee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseeFranchiseesFranchiseeIdGet(
      franchiseeId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FranchiseClient>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseeFranchiseesFranchiseeIdGet(
        franchiseeId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseesFullFranchiseesFullGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseClient>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseesFullFranchiseesFullGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseesLightFranchiseesGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseesLightFranchiseesGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Franchisee From Backoffice
     * @param {string} franchiseeId
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Franchisee From Middleoffice
     * @param {string} franchiseeId The ID of the franchisee
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Upload New Franchisee Document
     * @param {FranchiseDocumentType} documentType
     * @param {string} franchiseeId The ID of the franchisee
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
      documentType: FranchiseDocumentType,
      franchiseeId: string,
      file: any,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
        documentType,
        franchiseeId,
        file,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * FranchiseesApi - factory interface
 * @export
 */
export const FranchiseesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FranchiseesApiFp(configuration)
  return {
    /**
     *
     * @summary Create Franchisee
     * @param {FranchiseeInfoCreate} franchiseeInfoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFranchiseeFranchiseesPost(
      franchiseeInfoCreate: FranchiseeInfoCreate,
      options?: any
    ): AxiosPromise<FranchiseCreationResponse> {
      return localVarFp
        .createFranchiseeFranchiseesPost(franchiseeInfoCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Franchisee
     * @param {string} franchiseeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFranchiseeFranchiseesFranchiseeIdDelete(
      franchiseeId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFranchiseeFranchiseesFranchiseeIdDelete(franchiseeId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Associated Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesFullFranchiseesAssociatedGet(
      options?: any
    ): AxiosPromise<Array<FranchiseClient>> {
      return localVarFp
        .getAssociatedFranchiseesFullFranchiseesAssociatedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Associated Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
      options?: any
    ): AxiosPromise<Array<FranchiseInDB>> {
      return localVarFp
        .getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Franchisee Document
     * @param {FranchiseDocumentType} documentType
     * @param {string} franchiseeId The ID of the franchisee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
      documentType: FranchiseDocumentType,
      franchiseeId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
          documentType,
          franchiseeId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Franchisee
     * @param {string} franchiseeId The ID of the franchisee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseeFranchiseesFranchiseeIdGet(
      franchiseeId: string,
      options?: any
    ): AxiosPromise<FranchiseClient> {
      return localVarFp
        .getFranchiseeFranchiseesFranchiseeIdGet(franchiseeId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesFullFranchiseesFullGet(
      options?: any
    ): AxiosPromise<Array<FranchiseClient>> {
      return localVarFp
        .getFranchiseesFullFranchiseesFullGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesLightFranchiseesGet(
      options?: any
    ): AxiosPromise<Array<FranchiseInDB>> {
      return localVarFp
        .getFranchiseesLightFranchiseesGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Franchisee From Backoffice
     * @param {string} franchiseeId
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
          franchiseeId,
          franchiseeInfoUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Franchisee From Middleoffice
     * @param {string} franchiseeId The ID of the franchisee
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
          franchiseeId,
          franchiseeInfoUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Upload New Franchisee Document
     * @param {FranchiseDocumentType} documentType
     * @param {string} franchiseeId The ID of the franchisee
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
      documentType: FranchiseDocumentType,
      franchiseeId: string,
      file: any,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
          documentType,
          franchiseeId,
          file,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * FranchiseesApi - interface
 * @export
 * @interface FranchiseesApi
 */
export interface FranchiseesApiInterface {
  /**
   *
   * @summary Create Franchisee
   * @param {FranchiseeInfoCreate} franchiseeInfoCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  createFranchiseeFranchiseesPost(
    franchiseeInfoCreate: FranchiseeInfoCreate,
    options?: any
  ): AxiosPromise<FranchiseCreationResponse>

  /**
   *
   * @summary Delete Franchisee
   * @param {string} franchiseeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  deleteFranchiseeFranchiseesFranchiseeIdDelete(
    franchiseeId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get Associated Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  getAssociatedFranchiseesFullFranchiseesAssociatedGet(
    options?: any
  ): AxiosPromise<Array<FranchiseClient>>

  /**
   *
   * @summary Get Associated Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
    options?: any
  ): AxiosPromise<Array<FranchiseInDB>>

  /**
   *
   * @summary Get Franchisee Document
   * @param {FranchiseDocumentType} documentType
   * @param {string} franchiseeId The ID of the franchisee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
    documentType: FranchiseDocumentType,
    franchiseeId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get Franchisee
   * @param {string} franchiseeId The ID of the franchisee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  getFranchiseeFranchiseesFranchiseeIdGet(
    franchiseeId: string,
    options?: any
  ): AxiosPromise<FranchiseClient>

  /**
   *
   * @summary Get Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  getFranchiseesFullFranchiseesFullGet(
    options?: any
  ): AxiosPromise<Array<FranchiseClient>>

  /**
   *
   * @summary Get Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  getFranchiseesLightFranchiseesGet(
    options?: any
  ): AxiosPromise<Array<FranchiseInDB>>

  /**
   *
   * @summary Update Franchisee From Backoffice
   * @param {string} franchiseeId
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Franchisee From Middleoffice
   * @param {string} franchiseeId The ID of the franchisee
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Upload New Franchisee Document
   * @param {FranchiseDocumentType} documentType
   * @param {string} franchiseeId The ID of the franchisee
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApiInterface
   */
  uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
    documentType: FranchiseDocumentType,
    franchiseeId: string,
    file: any,
    options?: any
  ): AxiosPromise<void>
}

/**
 * FranchiseesApi - object-oriented interface
 * @export
 * @class FranchiseesApi
 * @extends {BaseAPI}
 */
export class FranchiseesApi extends BaseAPI implements FranchiseesApiInterface {
  /**
   *
   * @summary Create Franchisee
   * @param {FranchiseeInfoCreate} franchiseeInfoCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public createFranchiseeFranchiseesPost(
    franchiseeInfoCreate: FranchiseeInfoCreate,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .createFranchiseeFranchiseesPost(franchiseeInfoCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Franchisee
   * @param {string} franchiseeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public deleteFranchiseeFranchiseesFranchiseeIdDelete(
    franchiseeId: string,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .deleteFranchiseeFranchiseesFranchiseeIdDelete(franchiseeId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Associated Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public getAssociatedFranchiseesFullFranchiseesAssociatedGet(options?: any) {
    return FranchiseesApiFp(this.configuration)
      .getAssociatedFranchiseesFullFranchiseesAssociatedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Associated Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Franchisee Document
   * @param {FranchiseDocumentType} documentType
   * @param {string} franchiseeId The ID of the franchisee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
    documentType: FranchiseDocumentType,
    franchiseeId: string,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .getFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypeGet(
        documentType,
        franchiseeId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Franchisee
   * @param {string} franchiseeId The ID of the franchisee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public getFranchiseeFranchiseesFranchiseeIdGet(
    franchiseeId: string,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .getFranchiseeFranchiseesFranchiseeIdGet(franchiseeId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public getFranchiseesFullFranchiseesFullGet(options?: any) {
    return FranchiseesApiFp(this.configuration)
      .getFranchiseesFullFranchiseesFullGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public getFranchiseesLightFranchiseesGet(options?: any) {
    return FranchiseesApiFp(this.configuration)
      .getFranchiseesLightFranchiseesGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Franchisee From Backoffice
   * @param {string} franchiseeId
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Franchisee From Middleoffice
   * @param {string} franchiseeId The ID of the franchisee
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Upload New Franchisee Document
   * @param {FranchiseDocumentType} documentType
   * @param {string} franchiseeId The ID of the franchisee
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FranchiseesApi
   */
  public uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
    documentType: FranchiseDocumentType,
    franchiseeId: string,
    file: any,
    options?: any
  ) {
    return FranchiseesApiFp(this.configuration)
      .uploadNewFranchiseeDocumentFranchiseesFranchiseeIdDocumentTypePut(
        documentType,
        franchiseeId,
        file,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
