import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import { isLoggedInToKitchen } from '@/utils/authentication'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "layouts" */ '@/layouts/LayoutDefault.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', redirect: { name: 'Home' } },
      {
        path: 'orders/:id?',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      },
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "layouts" */ '@/layouts/SecondaryPages.vue'
          ),
        children: [
          {
            path: 'dispos',
            name: 'Availabilities',
            component: () => import('@/views/Availabilities.vue'),
            meta: { manualProgressFinish: true },
          },
          {
            path: 'pause',
            name: 'Pause',
            component: () => import('@/views/Pause.vue'),
            meta: { manualProgressFinish: true },
          },
          {
            path: 'imprimante',
            name: 'PrinterSetup',
            component: () =>
              // The printer will be put in the same chunk as the Home page
              import(/* webpackChunkName: "home" */ '@/views/Printer.vue'),
          },
          {
            path: 'kds',
            name: 'DisplaySetup',
            component: () =>
              // The display-setup will be put in the same chunk as the Home page
              import(/* webpackChunkName: "home" */ '@/views/DisplaySetup.vue'),
          },
          {
            path: 'parametres',
            name: 'HomeSetup',
            component: () =>
              // The setup will be put in the same chunk as the Home page
              import(/* webpackChunkName: "home" */ '@/views/HomeSetup.vue'),
          },
          {
            path: 'ventes',
            name: 'Sales',
            component: () =>
              // The sales will be put in the same chunk as the Home page
              import(/* webpackChunkName: "home" */ '@/views/Sales.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/choix-cuisine',
    name: 'LoginEnvironment',
    component: () => import('@/views/LoginEnvironment.vue'),
  },
  {
    path: '/reinitialisation-mot-de-passe',
    component: () => import('@/layouts/PasswordResetLayout.vue'),
    children: [
      {
        path: 'email-ok',
        name: 'EmailOK',
        component: () => import('@/views/password-reset/EmailSent.vue'),
        props: (route) => {
          const username = route.query.username
          if (!username || typeof username !== 'string') return {}
          return { username }
        },
      },
      {
        path: 'ok',
        name: 'ResetDone',
        component: () =>
          import(
            /* webpackChunkName: "password-reset-flow" */ '@/views/password-reset/ResetDone.vue'
          ),
        props: (route) => {
          const username = route.query.username
          if (!username || typeof username !== 'string') return {}
          return { username }
        },
      },
      {
        path: 'nouveau',
        name: 'NewPasswordForm',
        component: () =>
          import(
            /* webpackChunkName: "password-reset-flow" */ '@/views/password-reset/NewPasswordForm.vue'
          ),
        props: (route) => {
          let username: null | string = null
          let resetId: null | string = null
          {
            const usernameQuery = route.query.username
            if (usernameQuery && typeof usernameQuery === 'string')
              username = usernameQuery
          }
          {
            const idQuery = route.query.id
            if (idQuery && typeof idQuery === 'string') resetId = idQuery
          }
          return { username, resetId }
        },
      },
    ],
  },
  {
    path: '/kds-display',
    name: 'KDS-Display',
    component: () =>
      import(/* webpackChunkName: "kds" */ '@/views/Display.vue'),
    meta: { requiresAuth: false },
  },
  { path: '*', redirect: { name: 'Home' } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, _from, next) => {
  // This navigation guard is mainly used for the first navigation.
  // A stronger, smarter guard is implemented in `App.vue`.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLoggedInToKitchen()) {
      next()
    } else {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next() // `next()` must be called
  }
})

export default router
