/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BrandInDB } from '../model'
// @ts-ignore
import { BrandInDBWithMenu } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { UnpublishedBrands } from '../model'
/**
 * BrandsApi - axios parameter creator
 * @export
 */
export const BrandsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandBrandsBrandIdGet: async (
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists('getBrandBrandsBrandIdGet', 'brandId', brandId)
      const localVarPath = `/brands/{brand_id}`.replace(
        `{${'brand_id'}}`,
        encodeURIComponent(String(brandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandKitchenMenusBrandsBrandIdGet: async (
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getBrandKitchenMenusBrandsBrandIdGet',
        'brandId',
        brandId
      )
      const localVarPath = `/kitchen-menus/brands/{brand_id}`.replace(
        `{${'brand_id'}}`,
        encodeURIComponent(String(brandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandWithMenuBrandsMenusBrandIdGet: async (
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getBrandWithMenuBrandsMenusBrandIdGet',
        'brandId',
        brandId
      )
      const localVarPath = `/brands/menus/{brand_id}`.replace(
        `{${'brand_id'}}`,
        encodeURIComponent(String(brandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet: async (
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet',
        'brandId',
        brandId
      )
      const localVarPath = `/kitchen-menus/brands/menus/{brand_id}`.replace(
        `{${'brand_id'}}`,
        encodeURIComponent(String(brandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsBrandsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/brands`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsKitchenMenusBrandsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/brands`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsWithStagedMenuBrandsMenusGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsWithStagedMenuKitchenMenusBrandsMenusGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/brands/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedBrandsBrandsUnpublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands/unpublished`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/brands/unpublished`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BrandsApi - functional programming interface
 * @export
 */
export const BrandsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BrandsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandBrandsBrandIdGet(
      brandId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandBrandsBrandIdGet(
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandKitchenMenusBrandsBrandIdGet(
      brandId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandKitchenMenusBrandsBrandIdGet(
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandWithMenuBrandsMenusBrandIdGet(
      brandId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BrandInDBWithMenu>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandWithMenuBrandsMenusBrandIdGet(
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
      brandId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BrandInDBWithMenu>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandsBrandsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsBrandsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandsKitchenMenusBrandsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsKitchenMenusBrandsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandsWithStagedMenuBrandsMenusGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandInDBWithMenu>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsWithStagedMenuBrandsMenusGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandInDBWithMenu>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUnpublishedBrandsBrandsUnpublishedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<UnpublishedBrands>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUnpublishedBrandsBrandsUnpublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<UnpublishedBrands>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * BrandsApi - factory interface
 * @export
 */
export const BrandsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BrandsApiFp(configuration)
  return {
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandBrandsBrandIdGet(
      brandId: string,
      options?: any
    ): AxiosPromise<BrandInDB> {
      return localVarFp
        .getBrandBrandsBrandIdGet(brandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandKitchenMenusBrandsBrandIdGet(
      brandId: string,
      options?: any
    ): AxiosPromise<BrandInDB> {
      return localVarFp
        .getBrandKitchenMenusBrandsBrandIdGet(brandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandWithMenuBrandsMenusBrandIdGet(
      brandId: string,
      options?: any
    ): AxiosPromise<BrandInDBWithMenu> {
      return localVarFp
        .getBrandWithMenuBrandsMenusBrandIdGet(brandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
      brandId: string,
      options?: any
    ): AxiosPromise<BrandInDBWithMenu> {
      return localVarFp
        .getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(brandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsBrandsGet(options?: any): AxiosPromise<Array<BrandInDB>> {
      return localVarFp
        .getBrandsBrandsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsKitchenMenusBrandsGet(
      options?: any
    ): AxiosPromise<Array<BrandInDB>> {
      return localVarFp
        .getBrandsKitchenMenusBrandsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsWithStagedMenuBrandsMenusGet(
      options?: any
    ): AxiosPromise<Array<BrandInDBWithMenu>> {
      return localVarFp
        .getBrandsWithStagedMenuBrandsMenusGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
      options?: any
    ): AxiosPromise<Array<BrandInDBWithMenu>> {
      return localVarFp
        .getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedBrandsBrandsUnpublishedGet(
      options?: any
    ): AxiosPromise<Array<UnpublishedBrands>> {
      return localVarFp
        .getUnpublishedBrandsBrandsUnpublishedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
      options?: any
    ): AxiosPromise<Array<UnpublishedBrands>> {
      return localVarFp
        .getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * BrandsApi - interface
 * @export
 * @interface BrandsApi
 */
export interface BrandsApiInterface {
  /**
   *
   * @summary Get Brand
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandBrandsBrandIdGet(
    brandId: string,
    options?: any
  ): AxiosPromise<BrandInDB>

  /**
   *
   * @summary Get Brand
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandKitchenMenusBrandsBrandIdGet(
    brandId: string,
    options?: any
  ): AxiosPromise<BrandInDB>

  /**
   *
   * @summary Get Brand With Menu
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandWithMenuBrandsMenusBrandIdGet(
    brandId: string,
    options?: any
  ): AxiosPromise<BrandInDBWithMenu>

  /**
   *
   * @summary Get Brand With Menu
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
    brandId: string,
    options?: any
  ): AxiosPromise<BrandInDBWithMenu>

  /**
   *
   * @summary Get Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandsBrandsGet(options?: any): AxiosPromise<Array<BrandInDB>>

  /**
   *
   * @summary Get Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandsKitchenMenusBrandsGet(options?: any): AxiosPromise<Array<BrandInDB>>

  /**
   *
   * @summary Get Brands With Staged Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandsWithStagedMenuBrandsMenusGet(
    options?: any
  ): AxiosPromise<Array<BrandInDBWithMenu>>

  /**
   *
   * @summary Get Brands With Staged Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
    options?: any
  ): AxiosPromise<Array<BrandInDBWithMenu>>

  /**
   *
   * @summary Get Unpublished Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getUnpublishedBrandsBrandsUnpublishedGet(
    options?: any
  ): AxiosPromise<Array<UnpublishedBrands>>

  /**
   *
   * @summary Get Unpublished Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApiInterface
   */
  getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
    options?: any
  ): AxiosPromise<Array<UnpublishedBrands>>
}

/**
 * BrandsApi - object-oriented interface
 * @export
 * @class BrandsApi
 * @extends {BaseAPI}
 */
export class BrandsApi extends BaseAPI implements BrandsApiInterface {
  /**
   *
   * @summary Get Brand
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandBrandsBrandIdGet(brandId: string, options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandBrandsBrandIdGet(brandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brand
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandKitchenMenusBrandsBrandIdGet(brandId: string, options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandKitchenMenusBrandsBrandIdGet(brandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brand With Menu
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandWithMenuBrandsMenusBrandIdGet(brandId: string, options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandWithMenuBrandsMenusBrandIdGet(brandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brand With Menu
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
    brandId: string,
    options?: any
  ) {
    return BrandsApiFp(this.configuration)
      .getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(brandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandsBrandsGet(options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandsBrandsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandsKitchenMenusBrandsGet(options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandsKitchenMenusBrandsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brands With Staged Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandsWithStagedMenuBrandsMenusGet(options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandsWithStagedMenuBrandsMenusGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brands With Staged Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(options?: any) {
    return BrandsApiFp(this.configuration)
      .getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Unpublished Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getUnpublishedBrandsBrandsUnpublishedGet(options?: any) {
    return BrandsApiFp(this.configuration)
      .getUnpublishedBrandsBrandsUnpublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Unpublished Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsApi
   */
  public getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(options?: any) {
    return BrandsApiFp(this.configuration)
      .getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
