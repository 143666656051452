/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { DeliverooWebhookRequestCancelledOrder } from '../model'
// @ts-ignore
import { DeliverooWebhookRequestNewOrder } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { UberWebhookRequestCancelledOrder } from '../model'
// @ts-ignore
import { UberWebhookRequestNewOrder } from '../model'
/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Deliveroo Webhook
     * @param {string} xDeliverooSequenceGuid
     * @param {string} xDeliverooHmacSha256
     * @param {DeliverooWebhookRequestNewOrder | DeliverooWebhookRequestCancelledOrder} deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliverooWebhookDeliverooWebhookPost: async (
      xDeliverooSequenceGuid: string,
      xDeliverooHmacSha256: string,
      deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder:
        | DeliverooWebhookRequestNewOrder
        | DeliverooWebhookRequestCancelledOrder,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'xDeliverooSequenceGuid' is not null or undefined
      assertParamExists(
        'deliverooWebhookDeliverooWebhookPost',
        'xDeliverooSequenceGuid',
        xDeliverooSequenceGuid
      )
      // verify required parameter 'xDeliverooHmacSha256' is not null or undefined
      assertParamExists(
        'deliverooWebhookDeliverooWebhookPost',
        'xDeliverooHmacSha256',
        xDeliverooHmacSha256
      )
      // verify required parameter 'deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder' is not null or undefined
      assertParamExists(
        'deliverooWebhookDeliverooWebhookPost',
        'deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder',
        deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder
      )
      const localVarPath = `/deliveroo/webhook`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (
        xDeliverooSequenceGuid !== undefined &&
        xDeliverooSequenceGuid !== null
      ) {
        localVarHeaderParameter['x-deliveroo-sequence-guid'] = String(
          xDeliverooSequenceGuid
        )
      }

      if (xDeliverooHmacSha256 !== undefined && xDeliverooHmacSha256 !== null) {
        localVarHeaderParameter['x-deliveroo-hmac-sha256'] = String(
          xDeliverooHmacSha256
        )
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Uber Eats Webhook
     * @param {string} xUberSignature
     * @param {UberWebhookRequestNewOrder | UberWebhookRequestCancelledOrder} uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder
     * @param {'production' | 'sandbox'} [xEnvironment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uberEatsWebhookUberEatsWebhookPost: async (
      xUberSignature: string,
      uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder:
        | UberWebhookRequestNewOrder
        | UberWebhookRequestCancelledOrder,
      xEnvironment?: 'production' | 'sandbox',
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'xUberSignature' is not null or undefined
      assertParamExists(
        'uberEatsWebhookUberEatsWebhookPost',
        'xUberSignature',
        xUberSignature
      )
      // verify required parameter 'uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder' is not null or undefined
      assertParamExists(
        'uberEatsWebhookUberEatsWebhookPost',
        'uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder',
        uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder
      )
      const localVarPath = `/uber-eats/webhook`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xEnvironment !== undefined && xEnvironment !== null) {
        localVarHeaderParameter['x-environment'] = String(xEnvironment)
      }

      if (xUberSignature !== undefined && xUberSignature !== null) {
        localVarHeaderParameter['x-uber-signature'] = String(xUberSignature)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Deliveroo Webhook
     * @param {string} xDeliverooSequenceGuid
     * @param {string} xDeliverooHmacSha256
     * @param {DeliverooWebhookRequestNewOrder | DeliverooWebhookRequestCancelledOrder} deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliverooWebhookDeliverooWebhookPost(
      xDeliverooSequenceGuid: string,
      xDeliverooHmacSha256: string,
      deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder:
        | DeliverooWebhookRequestNewOrder
        | DeliverooWebhookRequestCancelledOrder,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deliverooWebhookDeliverooWebhookPost(
        xDeliverooSequenceGuid,
        xDeliverooHmacSha256,
        deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Uber Eats Webhook
     * @param {string} xUberSignature
     * @param {UberWebhookRequestNewOrder | UberWebhookRequestCancelledOrder} uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder
     * @param {'production' | 'sandbox'} [xEnvironment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uberEatsWebhookUberEatsWebhookPost(
      xUberSignature: string,
      uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder:
        | UberWebhookRequestNewOrder
        | UberWebhookRequestCancelledOrder,
      xEnvironment?: 'production' | 'sandbox',
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uberEatsWebhookUberEatsWebhookPost(
        xUberSignature,
        uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder,
        xEnvironment,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WebhooksApiFp(configuration)
  return {
    /**
     *
     * @summary Deliveroo Webhook
     * @param {string} xDeliverooSequenceGuid
     * @param {string} xDeliverooHmacSha256
     * @param {DeliverooWebhookRequestNewOrder | DeliverooWebhookRequestCancelledOrder} deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliverooWebhookDeliverooWebhookPost(
      xDeliverooSequenceGuid: string,
      xDeliverooHmacSha256: string,
      deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder:
        | DeliverooWebhookRequestNewOrder
        | DeliverooWebhookRequestCancelledOrder,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .deliverooWebhookDeliverooWebhookPost(
          xDeliverooSequenceGuid,
          xDeliverooHmacSha256,
          deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Uber Eats Webhook
     * @param {string} xUberSignature
     * @param {UberWebhookRequestNewOrder | UberWebhookRequestCancelledOrder} uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder
     * @param {'production' | 'sandbox'} [xEnvironment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uberEatsWebhookUberEatsWebhookPost(
      xUberSignature: string,
      uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder:
        | UberWebhookRequestNewOrder
        | UberWebhookRequestCancelledOrder,
      xEnvironment?: 'production' | 'sandbox',
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .uberEatsWebhookUberEatsWebhookPost(
          xUberSignature,
          uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder,
          xEnvironment,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * WebhooksApi - interface
 * @export
 * @interface WebhooksApi
 */
export interface WebhooksApiInterface {
  /**
   *
   * @summary Deliveroo Webhook
   * @param {string} xDeliverooSequenceGuid
   * @param {string} xDeliverooHmacSha256
   * @param {DeliverooWebhookRequestNewOrder | DeliverooWebhookRequestCancelledOrder} deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApiInterface
   */
  deliverooWebhookDeliverooWebhookPost(
    xDeliverooSequenceGuid: string,
    xDeliverooHmacSha256: string,
    deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder:
      | DeliverooWebhookRequestNewOrder
      | DeliverooWebhookRequestCancelledOrder,
    options?: any
  ): AxiosPromise<any>

  /**
   *
   * @summary Uber Eats Webhook
   * @param {string} xUberSignature
   * @param {UberWebhookRequestNewOrder | UberWebhookRequestCancelledOrder} uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder
   * @param {'production' | 'sandbox'} [xEnvironment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApiInterface
   */
  uberEatsWebhookUberEatsWebhookPost(
    xUberSignature: string,
    uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder:
      | UberWebhookRequestNewOrder
      | UberWebhookRequestCancelledOrder,
    xEnvironment?: 'production' | 'sandbox',
    options?: any
  ): AxiosPromise<any>
}

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI implements WebhooksApiInterface {
  /**
   *
   * @summary Deliveroo Webhook
   * @param {string} xDeliverooSequenceGuid
   * @param {string} xDeliverooHmacSha256
   * @param {DeliverooWebhookRequestNewOrder | DeliverooWebhookRequestCancelledOrder} deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public deliverooWebhookDeliverooWebhookPost(
    xDeliverooSequenceGuid: string,
    xDeliverooHmacSha256: string,
    deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder:
      | DeliverooWebhookRequestNewOrder
      | DeliverooWebhookRequestCancelledOrder,
    options?: any
  ) {
    return WebhooksApiFp(this.configuration)
      .deliverooWebhookDeliverooWebhookPost(
        xDeliverooSequenceGuid,
        xDeliverooHmacSha256,
        deliverooWebhookRequestNewOrderDeliverooWebhookRequestCancelledOrder,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Uber Eats Webhook
   * @param {string} xUberSignature
   * @param {UberWebhookRequestNewOrder | UberWebhookRequestCancelledOrder} uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder
   * @param {'production' | 'sandbox'} [xEnvironment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public uberEatsWebhookUberEatsWebhookPost(
    xUberSignature: string,
    uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder:
      | UberWebhookRequestNewOrder
      | UberWebhookRequestCancelledOrder,
    xEnvironment?: 'production' | 'sandbox',
    options?: any
  ) {
    return WebhooksApiFp(this.configuration)
      .uberEatsWebhookUberEatsWebhookPost(
        xUberSignature,
        uberWebhookRequestNewOrderUberWebhookRequestCancelledOrder,
        xEnvironment,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
