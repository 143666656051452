/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost } from '../model'
// @ts-ignore
import { DevorCancellationRequest } from '../model'
// @ts-ignore
import { DevorOrderPreparationState } from '../model'
// @ts-ignore
import { FranchiseInDB } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { ItemAvailability } from '../model'
// @ts-ignore
import { KitchenLightInDB } from '../model'
// @ts-ignore
import { KitchenLightInDBWithFranchisee } from '../model'
// @ts-ignore
import { KitchenPauseDetail } from '../model'
// @ts-ignore
import { KitchenPausingRequest } from '../model'
// @ts-ignore
import { UnpublishedKitchenBrandProvider } from '../model'
/**
 * KitchensApi - axios parameter creator
 * @export
 */
export const KitchensApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
     * @summary Acknowledge Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost: async (
      firestoreOrderId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'firestoreOrderId' is not null or undefined
      assertParamExists(
        'acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost',
        'firestoreOrderId',
        firestoreOrderId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/{firestore_order_id}/acknowledge`
        .replace(
          `{${'firestore_order_id'}}`,
          encodeURIComponent(String(firestoreOrderId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
     * @summary Acknowledge Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost: async (
      kitchenId: string,
      requestBody: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists(
        'acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost',
        'requestBody',
        requestBody
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/acknowledge`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Cancel an order from its Firestore ID.
     * @summary Cancel Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorCancellationRequest} devorCancellationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost: async (
      firestoreOrderId: string,
      kitchenId: string,
      devorCancellationRequest: DevorCancellationRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'firestoreOrderId' is not null or undefined
      assertParamExists(
        'cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost',
        'firestoreOrderId',
        firestoreOrderId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'devorCancellationRequest' is not null or undefined
      assertParamExists(
        'cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost',
        'devorCancellationRequest',
        devorCancellationRequest
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/{firestore_order_id}/cancel`
        .replace(
          `{${'firestore_order_id'}}`,
          encodeURIComponent(String(firestoreOrderId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorCancellationRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Change Item Availability
     * @param {string} kitchenId The ID of the kitchen
     * @param {BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost} [bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost: async (
      kitchenId: string,
      bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost?: BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/item-availabilities`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Clear Old Firestore Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {string} [ordersBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost: async (
      kitchenId: string,
      ordersBefore?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/clear`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ordersBefore !== undefined) {
        localVarQueryParameter['orders_before'] =
          (ordersBefore as any) instanceof Date
            ? (ordersBefore as any).toISOString()
            : ordersBefore
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get And Update Kitchen Pause Detail
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet: async (
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/pause`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return the first unpublished kitchen menu it finds. Raise a 400 error if no unpublished menu exists.
     * @summary Get Any Unpublished Kitchen Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchens/any-unpublished`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Associated Franchisee
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet: async (
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/franchisee`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Kitchen Items
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet: async (
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/item-availabilities`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchenKitchensKitchenIdGet: async (
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getKitchenKitchensKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all kitchens this user has access to.
     * @summary Get Kitchens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchensKitchensGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/kitchens`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all kitchens this user has access to, adding the franchisee ID.
     * @summary Get Kitchens With Franchisee Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchensWithFranchiseeIdKitchensFullGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchens/full`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return a list of unpublished kitchen menus (each consisting of a kitchen, a brand, and a provider).
     * @summary Get Unpublished Kitchen Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedKitchenMenusKitchensUnpublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchens/unpublished`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Pause all restaurants in the provided kitchen.  The pausing is performed on a best-effort basis: any restaurant that cannot be paused is just skipped, without preventing this call from succeeding.
     * @summary Pause Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {KitchenPausingRequest} [kitchenPausingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pauseKitchenKitchensKitchenIdPausePost: async (
      kitchenId: string,
      kitchenPausingRequest?: KitchenPausingRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'pauseKitchenKitchensKitchenIdPausePost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/pause`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        kitchenPausingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Un-pause all restaurants of a given kitchen.  If any restaurant cannot be un-paused by an implemented provider, this method will return a 206 (without reverting restaurants that eventually were un-paused).  Please note that a provider that does not support pausing/un-pausing will not lead to an error by itself.
     * @summary Unpause Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {KitchenPausingRequest} [kitchenPausingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpauseKitchenKitchensKitchenIdUnpausePost: async (
      kitchenId: string,
      kitchenPausingRequest?: KitchenPausingRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'unpauseKitchenKitchensKitchenIdUnpausePost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/unpause`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        kitchenPausingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
     * @summary Update Preparation State
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost: async (
      firestoreOrderId: string,
      kitchenId: string,
      newPreparationState: DevorOrderPreparationState,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'firestoreOrderId' is not null or undefined
      assertParamExists(
        'updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost',
        'firestoreOrderId',
        firestoreOrderId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'newPreparationState' is not null or undefined
      assertParamExists(
        'updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost',
        'newPreparationState',
        newPreparationState
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/{firestore_order_id}/preparation-state`
        .replace(
          `{${'firestore_order_id'}}`,
          encodeURIComponent(String(firestoreOrderId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (newPreparationState !== undefined) {
        localVarQueryParameter['new_preparation_state'] = newPreparationState
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * KitchensApi - functional programming interface
 * @export
 */
export const KitchensApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = KitchensApiAxiosParamCreator(configuration)
  return {
    /**
     * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
     * @summary Acknowledge Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
      firestoreOrderId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
        firestoreOrderId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
     * @summary Acknowledge Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
      kitchenId: string,
      requestBody: Array<string>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
        kitchenId,
        requestBody,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Cancel an order from its Firestore ID.
     * @summary Cancel Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorCancellationRequest} devorCancellationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
      firestoreOrderId: string,
      kitchenId: string,
      devorCancellationRequest: DevorCancellationRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
        firestoreOrderId,
        kitchenId,
        devorCancellationRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Change Item Availability
     * @param {string} kitchenId The ID of the kitchen
     * @param {BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost} [bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
      kitchenId: string,
      bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost?: BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
        kitchenId,
        bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Clear Old Firestore Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {string} [ordersBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
      kitchenId: string,
      ordersBefore?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
        kitchenId,
        ordersBefore,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get And Update Kitchen Pause Detail
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<KitchenPauseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return the first unpublished kitchen menu it finds. Raise a 400 error if no unpublished menu exists.
     * @summary Get Any Unpublished Kitchen Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UnpublishedKitchenBrandProvider>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Associated Franchisee
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FranchiseInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Kitchen Items
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ItemAvailability>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getKitchenKitchensKitchenIdGet(
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<KitchenLightInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getKitchenKitchensKitchenIdGet(
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Get all kitchens this user has access to.
     * @summary Get Kitchens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getKitchensKitchensGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<KitchenLightInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getKitchensKitchensGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Get all kitchens this user has access to, adding the franchisee ID.
     * @summary Get Kitchens With Franchisee Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getKitchensWithFranchiseeIdKitchensFullGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<KitchenLightInDBWithFranchisee>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getKitchensWithFranchiseeIdKitchensFullGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return a list of unpublished kitchen menus (each consisting of a kitchen, a brand, and a provider).
     * @summary Get Unpublished Kitchen Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUnpublishedKitchenMenusKitchensUnpublishedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<UnpublishedKitchenBrandProvider>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUnpublishedKitchenMenusKitchensUnpublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Pause all restaurants in the provided kitchen.  The pausing is performed on a best-effort basis: any restaurant that cannot be paused is just skipped, without preventing this call from succeeding.
     * @summary Pause Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {KitchenPausingRequest} [kitchenPausingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pauseKitchenKitchensKitchenIdPausePost(
      kitchenId: string,
      kitchenPausingRequest?: KitchenPausingRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<KitchenPauseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pauseKitchenKitchensKitchenIdPausePost(
        kitchenId,
        kitchenPausingRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Un-pause all restaurants of a given kitchen.  If any restaurant cannot be un-paused by an implemented provider, this method will return a 206 (without reverting restaurants that eventually were un-paused).  Please note that a provider that does not support pausing/un-pausing will not lead to an error by itself.
     * @summary Unpause Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {KitchenPausingRequest} [kitchenPausingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unpauseKitchenKitchensKitchenIdUnpausePost(
      kitchenId: string,
      kitchenPausingRequest?: KitchenPausingRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<KitchenPauseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unpauseKitchenKitchensKitchenIdUnpausePost(
        kitchenId,
        kitchenPausingRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
     * @summary Update Preparation State
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
      firestoreOrderId: string,
      kitchenId: string,
      newPreparationState: DevorOrderPreparationState,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
        firestoreOrderId,
        kitchenId,
        newPreparationState,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * KitchensApi - factory interface
 * @export
 */
export const KitchensApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = KitchensApiFp(configuration)
  return {
    /**
     * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
     * @summary Acknowledge Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
      firestoreOrderId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
          firestoreOrderId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
     * @summary Acknowledge Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
      kitchenId: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
          kitchenId,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Cancel an order from its Firestore ID.
     * @summary Cancel Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorCancellationRequest} devorCancellationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
      firestoreOrderId: string,
      kitchenId: string,
      devorCancellationRequest: DevorCancellationRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
          firestoreOrderId,
          kitchenId,
          devorCancellationRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Change Item Availability
     * @param {string} kitchenId The ID of the kitchen
     * @param {BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost} [bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
      kitchenId: string,
      bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost?: BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
          kitchenId,
          bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Clear Old Firestore Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {string} [ordersBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
      kitchenId: string,
      ordersBefore?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
          kitchenId,
          ordersBefore,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get And Update Kitchen Pause Detail
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
      kitchenId: string,
      options?: any
    ): AxiosPromise<KitchenPauseDetail> {
      return localVarFp
        .getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Return the first unpublished kitchen menu it finds. Raise a 400 error if no unpublished menu exists.
     * @summary Get Any Unpublished Kitchen Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(
      options?: any
    ): AxiosPromise<UnpublishedKitchenBrandProvider> {
      return localVarFp
        .getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Associated Franchisee
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(
      kitchenId: string,
      options?: any
    ): AxiosPromise<FranchiseInDB> {
      return localVarFp
        .getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Kitchen Items
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
      kitchenId: string,
      options?: any
    ): AxiosPromise<Array<ItemAvailability>> {
      return localVarFp
        .getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchenKitchensKitchenIdGet(
      kitchenId: string,
      options?: any
    ): AxiosPromise<KitchenLightInDB> {
      return localVarFp
        .getKitchenKitchensKitchenIdGet(kitchenId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get all kitchens this user has access to.
     * @summary Get Kitchens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchensKitchensGet(
      options?: any
    ): AxiosPromise<Array<KitchenLightInDB>> {
      return localVarFp
        .getKitchensKitchensGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get all kitchens this user has access to, adding the franchisee ID.
     * @summary Get Kitchens With Franchisee Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKitchensWithFranchiseeIdKitchensFullGet(
      options?: any
    ): AxiosPromise<Array<KitchenLightInDBWithFranchisee>> {
      return localVarFp
        .getKitchensWithFranchiseeIdKitchensFullGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return a list of unpublished kitchen menus (each consisting of a kitchen, a brand, and a provider).
     * @summary Get Unpublished Kitchen Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedKitchenMenusKitchensUnpublishedGet(
      options?: any
    ): AxiosPromise<Array<UnpublishedKitchenBrandProvider>> {
      return localVarFp
        .getUnpublishedKitchenMenusKitchensUnpublishedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Pause all restaurants in the provided kitchen.  The pausing is performed on a best-effort basis: any restaurant that cannot be paused is just skipped, without preventing this call from succeeding.
     * @summary Pause Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {KitchenPausingRequest} [kitchenPausingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pauseKitchenKitchensKitchenIdPausePost(
      kitchenId: string,
      kitchenPausingRequest?: KitchenPausingRequest,
      options?: any
    ): AxiosPromise<KitchenPauseDetail> {
      return localVarFp
        .pauseKitchenKitchensKitchenIdPausePost(
          kitchenId,
          kitchenPausingRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Un-pause all restaurants of a given kitchen.  If any restaurant cannot be un-paused by an implemented provider, this method will return a 206 (without reverting restaurants that eventually were un-paused).  Please note that a provider that does not support pausing/un-pausing will not lead to an error by itself.
     * @summary Unpause Kitchen
     * @param {string} kitchenId The ID of the kitchen
     * @param {KitchenPausingRequest} [kitchenPausingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpauseKitchenKitchensKitchenIdUnpausePost(
      kitchenId: string,
      kitchenPausingRequest?: KitchenPausingRequest,
      options?: any
    ): AxiosPromise<KitchenPauseDetail> {
      return localVarFp
        .unpauseKitchenKitchensKitchenIdUnpausePost(
          kitchenId,
          kitchenPausingRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
     * @summary Update Preparation State
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
      firestoreOrderId: string,
      kitchenId: string,
      newPreparationState: DevorOrderPreparationState,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
          firestoreOrderId,
          kitchenId,
          newPreparationState,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * KitchensApi - interface
 * @export
 * @interface KitchensApi
 */
export interface KitchensApiInterface {
  /**
   * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
   * @summary Acknowledge Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
    firestoreOrderId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
   * @summary Acknowledge Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
    kitchenId: string,
    requestBody: Array<string>,
    options?: any
  ): AxiosPromise<void>

  /**
   * Cancel an order from its Firestore ID.
   * @summary Cancel Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorCancellationRequest} devorCancellationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
    firestoreOrderId: string,
    kitchenId: string,
    devorCancellationRequest: DevorCancellationRequest,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Change Item Availability
   * @param {string} kitchenId The ID of the kitchen
   * @param {BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost} [bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
    kitchenId: string,
    bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost?: BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Clear Old Firestore Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {string} [ordersBefore]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
    kitchenId: string,
    ordersBefore?: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get And Update Kitchen Pause Detail
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
    kitchenId: string,
    options?: any
  ): AxiosPromise<KitchenPauseDetail>

  /**
   * Return the first unpublished kitchen menu it finds. Raise a 400 error if no unpublished menu exists.
   * @summary Get Any Unpublished Kitchen Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(
    options?: any
  ): AxiosPromise<UnpublishedKitchenBrandProvider>

  /**
   *
   * @summary Get Associated Franchisee
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(
    kitchenId: string,
    options?: any
  ): AxiosPromise<FranchiseInDB>

  /**
   *
   * @summary Get Kitchen Items
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
    kitchenId: string,
    options?: any
  ): AxiosPromise<Array<ItemAvailability>>

  /**
   *
   * @summary Get Kitchen
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getKitchenKitchensKitchenIdGet(
    kitchenId: string,
    options?: any
  ): AxiosPromise<KitchenLightInDB>

  /**
   * Get all kitchens this user has access to.
   * @summary Get Kitchens
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getKitchensKitchensGet(options?: any): AxiosPromise<Array<KitchenLightInDB>>

  /**
   * Get all kitchens this user has access to, adding the franchisee ID.
   * @summary Get Kitchens With Franchisee Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getKitchensWithFranchiseeIdKitchensFullGet(
    options?: any
  ): AxiosPromise<Array<KitchenLightInDBWithFranchisee>>

  /**
   * Return a list of unpublished kitchen menus (each consisting of a kitchen, a brand, and a provider).
   * @summary Get Unpublished Kitchen Menus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  getUnpublishedKitchenMenusKitchensUnpublishedGet(
    options?: any
  ): AxiosPromise<Array<UnpublishedKitchenBrandProvider>>

  /**
   * Pause all restaurants in the provided kitchen.  The pausing is performed on a best-effort basis: any restaurant that cannot be paused is just skipped, without preventing this call from succeeding.
   * @summary Pause Kitchen
   * @param {string} kitchenId The ID of the kitchen
   * @param {KitchenPausingRequest} [kitchenPausingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  pauseKitchenKitchensKitchenIdPausePost(
    kitchenId: string,
    kitchenPausingRequest?: KitchenPausingRequest,
    options?: any
  ): AxiosPromise<KitchenPauseDetail>

  /**
   * Un-pause all restaurants of a given kitchen.  If any restaurant cannot be un-paused by an implemented provider, this method will return a 206 (without reverting restaurants that eventually were un-paused).  Please note that a provider that does not support pausing/un-pausing will not lead to an error by itself.
   * @summary Unpause Kitchen
   * @param {string} kitchenId The ID of the kitchen
   * @param {KitchenPausingRequest} [kitchenPausingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  unpauseKitchenKitchensKitchenIdUnpausePost(
    kitchenId: string,
    kitchenPausingRequest?: KitchenPausingRequest,
    options?: any
  ): AxiosPromise<KitchenPauseDetail>

  /**
   * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
   * @summary Update Preparation State
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApiInterface
   */
  updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
    firestoreOrderId: string,
    kitchenId: string,
    newPreparationState: DevorOrderPreparationState,
    options?: any
  ): AxiosPromise<void>
}

/**
 * KitchensApi - object-oriented interface
 * @export
 * @class KitchensApi
 * @extends {BaseAPI}
 */
export class KitchensApi extends BaseAPI implements KitchensApiInterface {
  /**
   * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
   * @summary Acknowledge Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
    firestoreOrderId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
        firestoreOrderId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
   * @summary Acknowledge Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
    kitchenId: string,
    requestBody: Array<string>,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
        kitchenId,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Cancel an order from its Firestore ID.
   * @summary Cancel Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorCancellationRequest} devorCancellationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
    firestoreOrderId: string,
    kitchenId: string,
    devorCancellationRequest: DevorCancellationRequest,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
        firestoreOrderId,
        kitchenId,
        devorCancellationRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Change Item Availability
   * @param {string} kitchenId The ID of the kitchen
   * @param {BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost} [bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
    kitchenId: string,
    bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost?: BodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .changeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost(
        kitchenId,
        bodyChangeItemAvailabilityKitchensKitchenIdItemAvailabilitiesPost,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Clear Old Firestore Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {string} [ordersBefore]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
    kitchenId: string,
    ordersBefore?: string,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
        kitchenId,
        ordersBefore,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get And Update Kitchen Pause Detail
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
    kitchenId: string,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return the first unpublished kitchen menu it finds. Raise a 400 error if no unpublished menu exists.
   * @summary Get Any Unpublished Kitchen Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(options?: any) {
    return KitchensApiFp(this.configuration)
      .getAnyUnpublishedKitchenMenuKitchensAnyUnpublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Associated Franchisee
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(
    kitchenId: string,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .getAssociatedFranchiseeKitchensKitchenIdFranchiseeGet(kitchenId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Kitchen Items
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(
    kitchenId: string,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .getKitchenItemsKitchensKitchenIdItemAvailabilitiesGet(kitchenId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Kitchen
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getKitchenKitchensKitchenIdGet(kitchenId: string, options?: any) {
    return KitchensApiFp(this.configuration)
      .getKitchenKitchensKitchenIdGet(kitchenId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all kitchens this user has access to.
   * @summary Get Kitchens
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getKitchensKitchensGet(options?: any) {
    return KitchensApiFp(this.configuration)
      .getKitchensKitchensGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all kitchens this user has access to, adding the franchisee ID.
   * @summary Get Kitchens With Franchisee Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getKitchensWithFranchiseeIdKitchensFullGet(options?: any) {
    return KitchensApiFp(this.configuration)
      .getKitchensWithFranchiseeIdKitchensFullGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return a list of unpublished kitchen menus (each consisting of a kitchen, a brand, and a provider).
   * @summary Get Unpublished Kitchen Menus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public getUnpublishedKitchenMenusKitchensUnpublishedGet(options?: any) {
    return KitchensApiFp(this.configuration)
      .getUnpublishedKitchenMenusKitchensUnpublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Pause all restaurants in the provided kitchen.  The pausing is performed on a best-effort basis: any restaurant that cannot be paused is just skipped, without preventing this call from succeeding.
   * @summary Pause Kitchen
   * @param {string} kitchenId The ID of the kitchen
   * @param {KitchenPausingRequest} [kitchenPausingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public pauseKitchenKitchensKitchenIdPausePost(
    kitchenId: string,
    kitchenPausingRequest?: KitchenPausingRequest,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .pauseKitchenKitchensKitchenIdPausePost(
        kitchenId,
        kitchenPausingRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Un-pause all restaurants of a given kitchen.  If any restaurant cannot be un-paused by an implemented provider, this method will return a 206 (without reverting restaurants that eventually were un-paused).  Please note that a provider that does not support pausing/un-pausing will not lead to an error by itself.
   * @summary Unpause Kitchen
   * @param {string} kitchenId The ID of the kitchen
   * @param {KitchenPausingRequest} [kitchenPausingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public unpauseKitchenKitchensKitchenIdUnpausePost(
    kitchenId: string,
    kitchenPausingRequest?: KitchenPausingRequest,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .unpauseKitchenKitchensKitchenIdUnpausePost(
        kitchenId,
        kitchenPausingRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
   * @summary Update Preparation State
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchensApi
   */
  public updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
    firestoreOrderId: string,
    kitchenId: string,
    newPreparationState: DevorOrderPreparationState,
    options?: any
  ) {
    return KitchensApiFp(this.configuration)
      .updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
        firestoreOrderId,
        kitchenId,
        newPreparationState,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
