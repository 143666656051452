/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BodyResetPasswordAuthResetPut } from '../model'
// @ts-ignore
import { FrontendType } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { LogInResponse } from '../model'
// @ts-ignore
import { LoggedInUserWithScopes } from '../model'
// @ts-ignore
import { ScopeType } from '../model'
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get Authenticated User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthenticatedUserAuthGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Firebase Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFirebaseTokenAuthFirebaseAuthGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/firebase-auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Log-in endpoint. Add scope types in query parameters for the server to check if the user has authorization on those scopes, and in which contexts. Leave empty to get all scopes.
     * @summary Login
     * @param {string} username
     * @param {string} password
     * @param {ScopeType} [scopeType]
     * @param {string} [grantType]
     * @param {string} [scope]
     * @param {string} [clientId]
     * @param {string} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginAuthLoginPost: async (
      username: string,
      password: string,
      scopeType?: ScopeType,
      grantType?: string,
      scope?: string,
      clientId?: string,
      clientSecret?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('loginAuthLoginPost', 'username', username)
      // verify required parameter 'password' is not null or undefined
      assertParamExists('loginAuthLoginPost', 'password', password)
      const localVarPath = `/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (scopeType !== undefined) {
        localVarQueryParameter['scope_type'] = scopeType
      }

      if (grantType !== undefined) {
        localVarFormParams.set('grant_type', grantType as any)
      }

      if (username !== undefined) {
        localVarFormParams.set('username', username as any)
      }

      if (password !== undefined) {
        localVarFormParams.set('password', password as any)
      }

      if (scope !== undefined) {
        localVarFormParams.set('scope', scope as any)
      }

      if (clientId !== undefined) {
        localVarFormParams.set('client_id', clientId as any)
      }

      if (clientSecret !== undefined) {
        localVarFormParams.set('client_secret', clientSecret as any)
      }

      localVarHeaderParameter['Content-Type'] =
        'application/x-www-form-urlencoded'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutAuthDelete: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Request Password Reset
     * @param {string} username
     * @param {FrontendType} [frontendType] The frontend type from which the user is requesting the password reset. Used to know which URL to use in the reset password email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPasswordResetAuthResetPost: async (
      username: string,
      frontendType?: FrontendType,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'requestPasswordResetAuthResetPost',
        'username',
        username
      )
      const localVarPath = `/auth/reset`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (username !== undefined) {
        localVarQueryParameter['username'] = username
      }

      if (frontendType !== undefined) {
        localVarQueryParameter['frontend_type'] = frontendType
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Reset Password
     * @param {BodyResetPasswordAuthResetPut} bodyResetPasswordAuthResetPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordAuthResetPut: async (
      bodyResetPasswordAuthResetPut: BodyResetPasswordAuthResetPut,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bodyResetPasswordAuthResetPut' is not null or undefined
      assertParamExists(
        'resetPasswordAuthResetPut',
        'bodyResetPasswordAuthResetPut',
        bodyResetPasswordAuthResetPut
      )
      const localVarPath = `/auth/reset`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyResetPasswordAuthResetPut,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Get Authenticated User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuthenticatedUserAuthGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LoggedInUserWithScopes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatedUserAuthGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Firebase Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFirebaseTokenAuthFirebaseAuthGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogInResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFirebaseTokenAuthFirebaseAuthGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Log-in endpoint. Add scope types in query parameters for the server to check if the user has authorization on those scopes, and in which contexts. Leave empty to get all scopes.
     * @summary Login
     * @param {string} username
     * @param {string} password
     * @param {ScopeType} [scopeType]
     * @param {string} [grantType]
     * @param {string} [scope]
     * @param {string} [clientId]
     * @param {string} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginAuthLoginPost(
      username: string,
      password: string,
      scopeType?: ScopeType,
      grantType?: string,
      scope?: string,
      clientId?: string,
      clientSecret?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LoggedInUserWithScopes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuthLoginPost(
        username,
        password,
        scopeType,
        grantType,
        scope,
        clientId,
        clientSecret,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logoutAuthDelete(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logoutAuthDelete(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Request Password Reset
     * @param {string} username
     * @param {FrontendType} [frontendType] The frontend type from which the user is requesting the password reset. Used to know which URL to use in the reset password email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestPasswordResetAuthResetPost(
      username: string,
      frontendType?: FrontendType,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestPasswordResetAuthResetPost(
        username,
        frontendType,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Reset Password
     * @param {BodyResetPasswordAuthResetPut} bodyResetPasswordAuthResetPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPasswordAuthResetPut(
      bodyResetPasswordAuthResetPut: BodyResetPasswordAuthResetPut,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordAuthResetPut(
        bodyResetPasswordAuthResetPut,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthenticationApiFp(configuration)
  return {
    /**
     *
     * @summary Get Authenticated User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthenticatedUserAuthGet(
      options?: any
    ): AxiosPromise<LoggedInUserWithScopes> {
      return localVarFp
        .getAuthenticatedUserAuthGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Firebase Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFirebaseTokenAuthFirebaseAuthGet(
      options?: any
    ): AxiosPromise<LogInResponse> {
      return localVarFp
        .getFirebaseTokenAuthFirebaseAuthGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Log-in endpoint. Add scope types in query parameters for the server to check if the user has authorization on those scopes, and in which contexts. Leave empty to get all scopes.
     * @summary Login
     * @param {string} username
     * @param {string} password
     * @param {ScopeType} [scopeType]
     * @param {string} [grantType]
     * @param {string} [scope]
     * @param {string} [clientId]
     * @param {string} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginAuthLoginPost(
      username: string,
      password: string,
      scopeType?: ScopeType,
      grantType?: string,
      scope?: string,
      clientId?: string,
      clientSecret?: string,
      options?: any
    ): AxiosPromise<LoggedInUserWithScopes> {
      return localVarFp
        .loginAuthLoginPost(
          username,
          password,
          scopeType,
          grantType,
          scope,
          clientId,
          clientSecret,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutAuthDelete(options?: any): AxiosPromise<void> {
      return localVarFp
        .logoutAuthDelete(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Request Password Reset
     * @param {string} username
     * @param {FrontendType} [frontendType] The frontend type from which the user is requesting the password reset. Used to know which URL to use in the reset password email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPasswordResetAuthResetPost(
      username: string,
      frontendType?: FrontendType,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .requestPasswordResetAuthResetPost(username, frontendType, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Reset Password
     * @param {BodyResetPasswordAuthResetPut} bodyResetPasswordAuthResetPut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordAuthResetPut(
      bodyResetPasswordAuthResetPut: BodyResetPasswordAuthResetPut,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetPasswordAuthResetPut(bodyResetPasswordAuthResetPut, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AuthenticationApi - interface
 * @export
 * @interface AuthenticationApi
 */
export interface AuthenticationApiInterface {
  /**
   *
   * @summary Get Authenticated User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  getAuthenticatedUserAuthGet(
    options?: any
  ): AxiosPromise<LoggedInUserWithScopes>

  /**
   *
   * @summary Get Firebase Token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  getFirebaseTokenAuthFirebaseAuthGet(
    options?: any
  ): AxiosPromise<LogInResponse>

  /**
   * Log-in endpoint. Add scope types in query parameters for the server to check if the user has authorization on those scopes, and in which contexts. Leave empty to get all scopes.
   * @summary Login
   * @param {string} username
   * @param {string} password
   * @param {ScopeType} [scopeType]
   * @param {string} [grantType]
   * @param {string} [scope]
   * @param {string} [clientId]
   * @param {string} [clientSecret]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  loginAuthLoginPost(
    username: string,
    password: string,
    scopeType?: ScopeType,
    grantType?: string,
    scope?: string,
    clientId?: string,
    clientSecret?: string,
    options?: any
  ): AxiosPromise<LoggedInUserWithScopes>

  /**
   *
   * @summary Logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  logoutAuthDelete(options?: any): AxiosPromise<void>

  /**
   *
   * @summary Request Password Reset
   * @param {string} username
   * @param {FrontendType} [frontendType] The frontend type from which the user is requesting the password reset. Used to know which URL to use in the reset password email.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  requestPasswordResetAuthResetPost(
    username: string,
    frontendType?: FrontendType,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Reset Password
   * @param {BodyResetPasswordAuthResetPut} bodyResetPasswordAuthResetPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  resetPasswordAuthResetPut(
    bodyResetPasswordAuthResetPut: BodyResetPasswordAuthResetPut,
    options?: any
  ): AxiosPromise<void>
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi
  extends BaseAPI
  implements AuthenticationApiInterface {
  /**
   *
   * @summary Get Authenticated User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getAuthenticatedUserAuthGet(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .getAuthenticatedUserAuthGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Firebase Token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getFirebaseTokenAuthFirebaseAuthGet(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .getFirebaseTokenAuthFirebaseAuthGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Log-in endpoint. Add scope types in query parameters for the server to check if the user has authorization on those scopes, and in which contexts. Leave empty to get all scopes.
   * @summary Login
   * @param {string} username
   * @param {string} password
   * @param {ScopeType} [scopeType]
   * @param {string} [grantType]
   * @param {string} [scope]
   * @param {string} [clientId]
   * @param {string} [clientSecret]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public loginAuthLoginPost(
    username: string,
    password: string,
    scopeType?: ScopeType,
    grantType?: string,
    scope?: string,
    clientId?: string,
    clientSecret?: string,
    options?: any
  ) {
    return AuthenticationApiFp(this.configuration)
      .loginAuthLoginPost(
        username,
        password,
        scopeType,
        grantType,
        scope,
        clientId,
        clientSecret,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public logoutAuthDelete(options?: any) {
    return AuthenticationApiFp(this.configuration)
      .logoutAuthDelete(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Request Password Reset
   * @param {string} username
   * @param {FrontendType} [frontendType] The frontend type from which the user is requesting the password reset. Used to know which URL to use in the reset password email.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public requestPasswordResetAuthResetPost(
    username: string,
    frontendType?: FrontendType,
    options?: any
  ) {
    return AuthenticationApiFp(this.configuration)
      .requestPasswordResetAuthResetPost(username, frontendType, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Reset Password
   * @param {BodyResetPasswordAuthResetPut} bodyResetPasswordAuthResetPut
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public resetPasswordAuthResetPut(
    bodyResetPasswordAuthResetPut: BodyResetPasswordAuthResetPut,
    options?: any
  ) {
    return AuthenticationApiFp(this.configuration)
      .resetPasswordAuthResetPut(bodyResetPasswordAuthResetPut, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
