import Vue from 'vue'
import Vuex, { GetterTree, MutationTree, ActionTree } from 'vuex'
import { useAccessor } from 'typed-vuex'
import createPersistedState from 'vuex-persistedstate'

// Import modules
import { auth } from '@/store/auth.module'
import { cache } from '@/store/cache.module'
import { settings } from '@/store/settings.module'
import { printer } from '@/store/printer.module'

Vue.use(Vuex)

export const state = () => ({})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {}

export const mutations: MutationTree<RootState> = {}

export const actions: ActionTree<RootState, RootState> = {}

// Persist parts of the store to local storage
const printerPersistedState = createPersistedState({
  key: 'devor-frontoffice-vuex',
  paths: ['settings'],
})

const storePattern = {
  state,
  mutations,
  actions,
  modules: {
    auth,
    cache,
    printer,
    settings,
  },
}
const store = new Vuex.Store({
  ...storePattern,
  plugins: [printerPersistedState],
})

export const accessor = useAccessor(store, storePattern)

// Inject accessor globally
Vue.$accessor = Vue.prototype.$accessor = accessor

export default store
