/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { CreatedResponse } from '../model'
// @ts-ignore
import { DevorMenuItemCreate } from '../model'
// @ts-ignore
import { DevorMenuItemInDB } from '../model'
// @ts-ignore
import { DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides } from '../model'
// @ts-ignore
import { DevorMenuItemUpdate } from '../model'
// @ts-ignore
import { GetItemPriceOverridesResponse } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { ItemOverride } from '../model'
// @ts-ignore
import { StagedItemResponse } from '../model'
/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Item
     * @param {DevorMenuItemCreate} devorMenuItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createItemKitchenMenusItemsPost: async (
      devorMenuItemCreate: DevorMenuItemCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuItemCreate' is not null or undefined
      assertParamExists(
        'createItemKitchenMenusItemsPost',
        'devorMenuItemCreate',
        devorMenuItemCreate
      )
      const localVarPath = `/kitchen-menus/items`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuItemCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteItemKitchenMenusItemsItemIdDelete: async (
      itemId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'deleteItemKitchenMenusItemsItemIdDelete',
        'itemId',
        itemId
      )
      const localVarPath = `/kitchen-menus/items/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemKitchenMenusItemsItemIdGet: async (
      itemId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('getItemKitchenMenusItemsItemIdGet', 'itemId', itemId)
      const localVarPath = `/kitchen-menus/items/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet: async (
      itemId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet',
        'itemId',
        itemId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/items/{item_id}/price-overrides/{kitchen_id}`
        .replace(`{${'item_id'}}`, encodeURIComponent(String(itemId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemsKitchenMenusItemsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/items`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet: async (
      brandId: string,
      itemId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet',
        'brandId',
        brandId
      )
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet',
        'itemId',
        itemId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/items/staged/{brand_id}/{item_id}/{kitchen_id}`
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'item_id'}}`, encodeURIComponent(String(itemId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Set Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ItemOverride>} itemOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost: async (
      itemId: string,
      kitchenId: string,
      itemOverride: Array<ItemOverride>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost',
        'itemId',
        itemId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'itemOverride' is not null or undefined
      assertParamExists(
        'setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost',
        'itemOverride',
        itemOverride
      )
      const localVarPath = `/kitchen-menus/items/{item_id}/price-overrides/{kitchen_id}`
        .replace(`{${'item_id'}}`, encodeURIComponent(String(itemId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        itemOverride,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Item
     * @param {DevorMenuItemUpdate} devorMenuItemUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateItemKitchenMenusItemsUpdatePost: async (
      devorMenuItemUpdate: DevorMenuItemUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuItemUpdate' is not null or undefined
      assertParamExists(
        'updateItemKitchenMenusItemsUpdatePost',
        'devorMenuItemUpdate',
        devorMenuItemUpdate
      )
      const localVarPath = `/kitchen-menus/items/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuItemUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Create Item
     * @param {DevorMenuItemCreate} devorMenuItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createItemKitchenMenusItemsPost(
      devorMenuItemCreate: DevorMenuItemCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createItemKitchenMenusItemsPost(
        devorMenuItemCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteItemKitchenMenusItemsItemIdDelete(
      itemId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemKitchenMenusItemsItemIdDelete(
        itemId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemKitchenMenusItemsItemIdGet(
      itemId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuItemInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getItemKitchenMenusItemsItemIdGet(
        itemId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
      itemId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetItemPriceOverridesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
        itemId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemsKitchenMenusItemsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        Array<DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides>
      >
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsKitchenMenusItemsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
      brandId: string,
      itemId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<StagedItemResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
        brandId,
        itemId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Set Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ItemOverride>} itemOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
      itemId: string,
      kitchenId: string,
      itemOverride: Array<ItemOverride>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
        itemId,
        kitchenId,
        itemOverride,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Item
     * @param {DevorMenuItemUpdate} devorMenuItemUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateItemKitchenMenusItemsUpdatePost(
      devorMenuItemUpdate: DevorMenuItemUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemKitchenMenusItemsUpdatePost(
        devorMenuItemUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ItemsApiFp(configuration)
  return {
    /**
     *
     * @summary Create Item
     * @param {DevorMenuItemCreate} devorMenuItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createItemKitchenMenusItemsPost(
      devorMenuItemCreate: DevorMenuItemCreate,
      options?: any
    ): AxiosPromise<CreatedResponse> {
      return localVarFp
        .createItemKitchenMenusItemsPost(devorMenuItemCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteItemKitchenMenusItemsItemIdDelete(
      itemId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteItemKitchenMenusItemsItemIdDelete(itemId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemKitchenMenusItemsItemIdGet(
      itemId: string,
      options?: any
    ): AxiosPromise<DevorMenuItemInDB> {
      return localVarFp
        .getItemKitchenMenusItemsItemIdGet(itemId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
      itemId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<GetItemPriceOverridesResponse> {
      return localVarFp
        .getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
          itemId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemsKitchenMenusItemsGet(
      options?: any
    ): AxiosPromise<
      Array<DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides>
    > {
      return localVarFp
        .getItemsKitchenMenusItemsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
      brandId: string,
      itemId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<StagedItemResponse> {
      return localVarFp
        .getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
          brandId,
          itemId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Set Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ItemOverride>} itemOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
      itemId: string,
      kitchenId: string,
      itemOverride: Array<ItemOverride>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
          itemId,
          kitchenId,
          itemOverride,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Item
     * @param {DevorMenuItemUpdate} devorMenuItemUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateItemKitchenMenusItemsUpdatePost(
      devorMenuItemUpdate: DevorMenuItemUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateItemKitchenMenusItemsUpdatePost(devorMenuItemUpdate, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ItemsApi - interface
 * @export
 * @interface ItemsApi
 */
export interface ItemsApiInterface {
  /**
   *
   * @summary Create Item
   * @param {DevorMenuItemCreate} devorMenuItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  createItemKitchenMenusItemsPost(
    devorMenuItemCreate: DevorMenuItemCreate,
    options?: any
  ): AxiosPromise<CreatedResponse>

  /**
   *
   * @summary Delete Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  deleteItemKitchenMenusItemsItemIdDelete(
    itemId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  getItemKitchenMenusItemsItemIdGet(
    itemId: string,
    options?: any
  ): AxiosPromise<DevorMenuItemInDB>

  /**
   *
   * @summary Get Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
    itemId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<GetItemPriceOverridesResponse>

  /**
   *
   * @summary Get Items
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  getItemsKitchenMenusItemsGet(
    options?: any
  ): AxiosPromise<Array<DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides>>

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
    brandId: string,
    itemId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<StagedItemResponse>

  /**
   *
   * @summary Set Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ItemOverride>} itemOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
    itemId: string,
    kitchenId: string,
    itemOverride: Array<ItemOverride>,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Item
   * @param {DevorMenuItemUpdate} devorMenuItemUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApiInterface
   */
  updateItemKitchenMenusItemsUpdatePost(
    devorMenuItemUpdate: DevorMenuItemUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI implements ItemsApiInterface {
  /**
   *
   * @summary Create Item
   * @param {DevorMenuItemCreate} devorMenuItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public createItemKitchenMenusItemsPost(
    devorMenuItemCreate: DevorMenuItemCreate,
    options?: any
  ) {
    return ItemsApiFp(this.configuration)
      .createItemKitchenMenusItemsPost(devorMenuItemCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public deleteItemKitchenMenusItemsItemIdDelete(
    itemId: string,
    options?: any
  ) {
    return ItemsApiFp(this.configuration)
      .deleteItemKitchenMenusItemsItemIdDelete(itemId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public getItemKitchenMenusItemsItemIdGet(itemId: string, options?: any) {
    return ItemsApiFp(this.configuration)
      .getItemKitchenMenusItemsItemIdGet(itemId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
    itemId: string,
    kitchenId: string,
    options?: any
  ) {
    return ItemsApiFp(this.configuration)
      .getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
        itemId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Items
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public getItemsKitchenMenusItemsGet(options?: any) {
    return ItemsApiFp(this.configuration)
      .getItemsKitchenMenusItemsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
    brandId: string,
    itemId: string,
    kitchenId: string,
    options?: any
  ) {
    return ItemsApiFp(this.configuration)
      .getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
        brandId,
        itemId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Set Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ItemOverride>} itemOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
    itemId: string,
    kitchenId: string,
    itemOverride: Array<ItemOverride>,
    options?: any
  ) {
    return ItemsApiFp(this.configuration)
      .setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
        itemId,
        kitchenId,
        itemOverride,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Item
   * @param {DevorMenuItemUpdate} devorMenuItemUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ItemsApi
   */
  public updateItemKitchenMenusItemsUpdatePost(
    devorMenuItemUpdate: DevorMenuItemUpdate,
    options?: any
  ) {
    return ItemsApiFp(this.configuration)
      .updateItemKitchenMenusItemsUpdatePost(devorMenuItemUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
