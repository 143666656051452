/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { CreatedResponse } from '../model'
// @ts-ignore
import { DevorMenuCreate } from '../model'
// @ts-ignore
import { DevorMenuDraftInDB } from '../model'
// @ts-ignore
import { DevorMenuEntityTypeEnum } from '../model'
// @ts-ignore
import { DevorMenuPublishedInDB } from '../model'
// @ts-ignore
import { DevorMenuUpdate } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { MissingOverridesResponse } from '../model'
/**
 * MenusApi - axios parameter creator
 * @export
 */
export const MenusApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Archive Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveMenuKitchenMenusMenusMenuIdArchivePost: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'archiveMenuKitchenMenusMenusMenuIdArchivePost',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}/archive`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Compute and return a menu as it would be if this middleoffice user were to publish it.
     * @summary Compute Publishable Menu
     * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost: async (
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuStagingId' is not null or undefined
      assertParamExists(
        'computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost',
        'menuStagingId',
        menuStagingId
      )
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost',
        'brandId',
        brandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_staging_id}/preview-publishable/{kitchen_id}/{brand_id}`
        .replace(
          `{${'menu_staging_id'}}`,
          encodeURIComponent(String(menuStagingId))
        )
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
     * @summary Compute Staging Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}/preview-staging`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Menu
     * @param {DevorMenuCreate} devorMenuCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMenuKitchenMenusMenusPost: async (
      devorMenuCreate: DevorMenuCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuCreate' is not null or undefined
      assertParamExists(
        'createMenuKitchenMenusMenusPost',
        'devorMenuCreate',
        devorMenuCreate
      )
      const localVarPath = `/kitchen-menus/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMenuKitchenMenusMenusMenuIdDelete: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'deleteMenuKitchenMenusMenusMenuIdDelete',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get All Menus
     * @param {boolean} [archived] If True, the response will include menus that are archived.
     * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMenusKitchenMenusMenusGet: async (
      archived?: boolean,
      nonArchived?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (archived !== undefined) {
        localVarQueryParameter['archived'] = archived
      }

      if (nonArchived !== undefined) {
        localVarQueryParameter['non_archived'] = nonArchived
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Entities Used In An Active Menu
     * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet: async (
      entityTypes: Array<DevorMenuEntityTypeEnum>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityTypes' is not null or undefined
      assertParamExists(
        'getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet',
        'entityTypes',
        entityTypes
      )
      const localVarPath = `/kitchen-menus/menus/entities/used-in-active-menu`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (entityTypes) {
        localVarQueryParameter['entity_types'] = entityTypes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenuKitchenMenusMenusMenuIdGet: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists('getMenuKitchenMenusMenusMenuIdGet', 'menuId', menuId)
      const localVarPath = `/kitchen-menus/menus/{menu_id}`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Missing Staged Menu Overrides
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet: async (
      brandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet',
        'brandId',
        brandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/menus/missing-overrides/{kitchen_id}/{brand_id}`
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Published Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedMenusKitchenMenusMenusPublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/menus/published`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Brand Menu For Kitchen
     * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost: async (
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuStagingId' is not null or undefined
      assertParamExists(
        'publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost',
        'menuStagingId',
        menuStagingId
      )
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost',
        'brandId',
        brandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_staging_id}/publish/{kitchen_id}/{brand_id}`
        .replace(
          `{${'menu_staging_id'}}`,
          encodeURIComponent(String(menuStagingId))
        )
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Stage For Publication
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}/stage-for-publication`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Menu
     * @param {DevorMenuUpdate} devorMenuUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMenuKitchenMenusMenusUpdatePost: async (
      devorMenuUpdate: DevorMenuUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuUpdate' is not null or undefined
      assertParamExists(
        'updateMenuKitchenMenusMenusUpdatePost',
        'devorMenuUpdate',
        devorMenuUpdate
      )
      const localVarPath = `/kitchen-menus/menus/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MenusApi - functional programming interface
 * @export
 */
export const MenusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MenusApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Archive Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveMenuKitchenMenusMenusMenuIdArchivePost(
      menuId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveMenuKitchenMenusMenusMenuIdArchivePost(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Compute and return a menu as it would be if this middleoffice user were to publish it.
     * @summary Compute Publishable Menu
     * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuPublishedInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
     * @summary Compute Staging Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
      menuId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuPublishedInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Menu
     * @param {DevorMenuCreate} devorMenuCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMenuKitchenMenusMenusPost(
      devorMenuCreate: DevorMenuCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMenuKitchenMenusMenusPost(
        devorMenuCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMenuKitchenMenusMenusMenuIdDelete(
      menuId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMenuKitchenMenusMenusMenuIdDelete(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get All Menus
     * @param {boolean} [archived] If True, the response will include menus that are archived.
     * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMenusKitchenMenusMenusGet(
      archived?: boolean,
      nonArchived?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorMenuDraftInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMenusKitchenMenusMenusGet(
        archived,
        nonArchived,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Entities Used In An Active Menu
     * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
      entityTypes: Array<DevorMenuEntityTypeEnum>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
        entityTypes,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMenuKitchenMenusMenusMenuIdGet(
      menuId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuDraftInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuKitchenMenusMenusMenuIdGet(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Missing Staged Menu Overrides
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
      brandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MissingOverridesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
        brandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Published Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPublishedMenusKitchenMenusMenusPublishedGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedMenusKitchenMenusMenusPublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Publish Brand Menu For Kitchen
     * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Stage For Publication
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
      menuId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Menu
     * @param {DevorMenuUpdate} devorMenuUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMenuKitchenMenusMenusUpdatePost(
      devorMenuUpdate: DevorMenuUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMenuKitchenMenusMenusUpdatePost(
        devorMenuUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * MenusApi - factory interface
 * @export
 */
export const MenusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MenusApiFp(configuration)
  return {
    /**
     *
     * @summary Archive Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveMenuKitchenMenusMenusMenuIdArchivePost(
      menuId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .archiveMenuKitchenMenusMenusMenuIdArchivePost(menuId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Compute and return a menu as it would be if this middleoffice user were to publish it.
     * @summary Compute Publishable Menu
     * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<DevorMenuPublishedInDB> {
      return localVarFp
        .computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
          menuStagingId,
          brandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
     * @summary Compute Staging Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
      menuId: string,
      options?: any
    ): AxiosPromise<DevorMenuPublishedInDB> {
      return localVarFp
        .computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
          menuId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Menu
     * @param {DevorMenuCreate} devorMenuCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMenuKitchenMenusMenusPost(
      devorMenuCreate: DevorMenuCreate,
      options?: any
    ): AxiosPromise<CreatedResponse> {
      return localVarFp
        .createMenuKitchenMenusMenusPost(devorMenuCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMenuKitchenMenusMenusMenuIdDelete(
      menuId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteMenuKitchenMenusMenusMenuIdDelete(menuId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get All Menus
     * @param {boolean} [archived] If True, the response will include menus that are archived.
     * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMenusKitchenMenusMenusGet(
      archived?: boolean,
      nonArchived?: boolean,
      options?: any
    ): AxiosPromise<Array<DevorMenuDraftInDB>> {
      return localVarFp
        .getAllMenusKitchenMenusMenusGet(archived, nonArchived, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Entities Used In An Active Menu
     * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
      entityTypes: Array<DevorMenuEntityTypeEnum>,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
          entityTypes,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenuKitchenMenusMenusMenuIdGet(
      menuId: string,
      options?: any
    ): AxiosPromise<DevorMenuDraftInDB> {
      return localVarFp
        .getMenuKitchenMenusMenusMenuIdGet(menuId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Missing Staged Menu Overrides
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
      brandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<MissingOverridesResponse> {
      return localVarFp
        .getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
          brandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Published Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedMenusKitchenMenusMenusPublishedGet(
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getPublishedMenusKitchenMenusMenusPublishedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Brand Menu For Kitchen
     * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
          menuStagingId,
          brandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Stage For Publication
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
      menuId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
          menuId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Menu
     * @param {DevorMenuUpdate} devorMenuUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMenuKitchenMenusMenusUpdatePost(
      devorMenuUpdate: DevorMenuUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateMenuKitchenMenusMenusUpdatePost(devorMenuUpdate, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * MenusApi - interface
 * @export
 * @interface MenusApi
 */
export interface MenusApiInterface {
  /**
   *
   * @summary Archive Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  archiveMenuKitchenMenusMenusMenuIdArchivePost(
    menuId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Compute and return a menu as it would be if this middleoffice user were to publish it.
   * @summary Compute Publishable Menu
   * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<DevorMenuPublishedInDB>

  /**
   * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
   * @summary Compute Staging Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
    menuId: string,
    options?: any
  ): AxiosPromise<DevorMenuPublishedInDB>

  /**
   *
   * @summary Create Menu
   * @param {DevorMenuCreate} devorMenuCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  createMenuKitchenMenusMenusPost(
    devorMenuCreate: DevorMenuCreate,
    options?: any
  ): AxiosPromise<CreatedResponse>

  /**
   *
   * @summary Delete Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  deleteMenuKitchenMenusMenusMenuIdDelete(
    menuId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get All Menus
   * @param {boolean} [archived] If True, the response will include menus that are archived.
   * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  getAllMenusKitchenMenusMenusGet(
    archived?: boolean,
    nonArchived?: boolean,
    options?: any
  ): AxiosPromise<Array<DevorMenuDraftInDB>>

  /**
   *
   * @summary Get Entities Used In An Active Menu
   * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
    entityTypes: Array<DevorMenuEntityTypeEnum>,
    options?: any
  ): AxiosPromise<Array<string>>

  /**
   *
   * @summary Get Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  getMenuKitchenMenusMenusMenuIdGet(
    menuId: string,
    options?: any
  ): AxiosPromise<DevorMenuDraftInDB>

  /**
   *
   * @summary Get Missing Staged Menu Overrides
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
    brandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<MissingOverridesResponse>

  /**
   *
   * @summary Get Published Menus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  getPublishedMenusKitchenMenusMenusPublishedGet(
    options?: any
  ): AxiosPromise<Array<string>>

  /**
   *
   * @summary Publish Brand Menu For Kitchen
   * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Stage For Publication
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
    menuId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Menu
   * @param {DevorMenuUpdate} devorMenuUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApiInterface
   */
  updateMenuKitchenMenusMenusUpdatePost(
    devorMenuUpdate: DevorMenuUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * MenusApi - object-oriented interface
 * @export
 * @class MenusApi
 * @extends {BaseAPI}
 */
export class MenusApi extends BaseAPI implements MenusApiInterface {
  /**
   *
   * @summary Archive Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public archiveMenuKitchenMenusMenusMenuIdArchivePost(
    menuId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .archiveMenuKitchenMenusMenusMenuIdArchivePost(menuId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Compute and return a menu as it would be if this middleoffice user were to publish it.
   * @summary Compute Publishable Menu
   * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
   * @summary Compute Staging Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
    menuId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
        menuId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Menu
   * @param {DevorMenuCreate} devorMenuCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public createMenuKitchenMenusMenusPost(
    devorMenuCreate: DevorMenuCreate,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .createMenuKitchenMenusMenusPost(devorMenuCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public deleteMenuKitchenMenusMenusMenuIdDelete(
    menuId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .deleteMenuKitchenMenusMenusMenuIdDelete(menuId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get All Menus
   * @param {boolean} [archived] If True, the response will include menus that are archived.
   * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public getAllMenusKitchenMenusMenusGet(
    archived?: boolean,
    nonArchived?: boolean,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .getAllMenusKitchenMenusMenusGet(archived, nonArchived, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Entities Used In An Active Menu
   * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
    entityTypes: Array<DevorMenuEntityTypeEnum>,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
        entityTypes,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public getMenuKitchenMenusMenusMenuIdGet(menuId: string, options?: any) {
    return MenusApiFp(this.configuration)
      .getMenuKitchenMenusMenusMenuIdGet(menuId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Missing Staged Menu Overrides
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
    brandId: string,
    kitchenId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
        brandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Published Menus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public getPublishedMenusKitchenMenusMenusPublishedGet(options?: any) {
    return MenusApiFp(this.configuration)
      .getPublishedMenusKitchenMenusMenusPublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Publish Brand Menu For Kitchen
   * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Stage For Publication
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
    menuId: string,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
        menuId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Menu
   * @param {DevorMenuUpdate} devorMenuUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenusApi
   */
  public updateMenuKitchenMenusMenusUpdatePost(
    devorMenuUpdate: DevorMenuUpdate,
    options?: any
  ) {
    return MenusApiFp(this.configuration)
      .updateMenuKitchenMenusMenusUpdatePost(devorMenuUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
