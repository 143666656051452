/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { CreatedResponse } from '../model'
// @ts-ignore
import { DevorMenuModifierCreate } from '../model'
// @ts-ignore
import { DevorMenuModifierInDB } from '../model'
// @ts-ignore
import { DevorMenuModifierUpdate } from '../model'
// @ts-ignore
import { GetModifierPriceOverridesResponse } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { ModifierOverride } from '../model'
// @ts-ignore
import { StagedModifierResponse } from '../model'
/**
 * ModifiersApi - axios parameter creator
 * @export
 */
export const ModifiersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Modifier
     * @param {DevorMenuModifierCreate} devorMenuModifierCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModifierKitchenMenusModifiersPost: async (
      devorMenuModifierCreate: DevorMenuModifierCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuModifierCreate' is not null or undefined
      assertParamExists(
        'createModifierKitchenMenusModifiersPost',
        'devorMenuModifierCreate',
        devorMenuModifierCreate
      )
      const localVarPath = `/kitchen-menus/modifiers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuModifierCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModifierKitchenMenusModifiersModifierIdDelete: async (
      modifierId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'deleteModifierKitchenMenusModifiersModifierIdDelete',
        'modifierId',
        modifierId
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}`.replace(
        `{${'modifier_id'}}`,
        encodeURIComponent(String(modifierId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierKitchenMenusModifiersModifierIdGet: async (
      modifierId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'getModifierKitchenMenusModifiersModifierIdGet',
        'modifierId',
        modifierId
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}`.replace(
        `{${'modifier_id'}}`,
        encodeURIComponent(String(modifierId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet: async (
      modifierId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet',
        'modifierId',
        modifierId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}/price-overrides/{kitchen_id}`
        .replace(`{${'modifier_id'}}`, encodeURIComponent(String(modifierId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Modifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifiersKitchenMenusModifiersGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/modifiers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet: async (
      brandId: string,
      modifierId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet',
        'brandId',
        brandId
      )
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet',
        'modifierId',
        modifierId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/modifiers/staged/{brand_id}/{modifier_id}/{kitchen_id}`
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'modifier_id'}}`, encodeURIComponent(String(modifierId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Set Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ModifierOverride>} modifierOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost: async (
      modifierId: string,
      kitchenId: string,
      modifierOverride: Array<ModifierOverride>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost',
        'modifierId',
        modifierId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'modifierOverride' is not null or undefined
      assertParamExists(
        'setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost',
        'modifierOverride',
        modifierOverride
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}/price-overrides/{kitchen_id}`
        .replace(`{${'modifier_id'}}`, encodeURIComponent(String(modifierId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        modifierOverride,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Modifier
     * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModifierKitchenMenusModifiersUpdatePost: async (
      devorMenuModifierUpdate: DevorMenuModifierUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuModifierUpdate' is not null or undefined
      assertParamExists(
        'updateModifierKitchenMenusModifiersUpdatePost',
        'devorMenuModifierUpdate',
        devorMenuModifierUpdate
      )
      const localVarPath = `/kitchen-menus/modifiers/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuModifierUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ModifiersApi - functional programming interface
 * @export
 */
export const ModifiersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModifiersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Create Modifier
     * @param {DevorMenuModifierCreate} devorMenuModifierCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createModifierKitchenMenusModifiersPost(
      devorMenuModifierCreate: DevorMenuModifierCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createModifierKitchenMenusModifiersPost(
        devorMenuModifierCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteModifierKitchenMenusModifiersModifierIdDelete(
      modifierId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModifierKitchenMenusModifiersModifierIdDelete(
        modifierId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModifierKitchenMenusModifiersModifierIdGet(
      modifierId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuModifierInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModifierKitchenMenusModifiersModifierIdGet(
        modifierId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
      modifierId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetModifierPriceOverridesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
        modifierId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Modifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModifiersKitchenMenusModifiersGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorMenuModifierInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModifiersKitchenMenusModifiersGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
      brandId: string,
      modifierId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<StagedModifierResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
        brandId,
        modifierId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Set Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ModifierOverride>} modifierOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
      modifierId: string,
      kitchenId: string,
      modifierOverride: Array<ModifierOverride>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
        modifierId,
        kitchenId,
        modifierOverride,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Modifier
     * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModifierKitchenMenusModifiersUpdatePost(
      devorMenuModifierUpdate: DevorMenuModifierUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateModifierKitchenMenusModifiersUpdatePost(
        devorMenuModifierUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * ModifiersApi - factory interface
 * @export
 */
export const ModifiersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ModifiersApiFp(configuration)
  return {
    /**
     *
     * @summary Create Modifier
     * @param {DevorMenuModifierCreate} devorMenuModifierCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModifierKitchenMenusModifiersPost(
      devorMenuModifierCreate: DevorMenuModifierCreate,
      options?: any
    ): AxiosPromise<CreatedResponse> {
      return localVarFp
        .createModifierKitchenMenusModifiersPost(
          devorMenuModifierCreate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModifierKitchenMenusModifiersModifierIdDelete(
      modifierId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteModifierKitchenMenusModifiersModifierIdDelete(
          modifierId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierKitchenMenusModifiersModifierIdGet(
      modifierId: string,
      options?: any
    ): AxiosPromise<DevorMenuModifierInDB> {
      return localVarFp
        .getModifierKitchenMenusModifiersModifierIdGet(modifierId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
      modifierId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<GetModifierPriceOverridesResponse> {
      return localVarFp
        .getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
          modifierId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Modifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifiersKitchenMenusModifiersGet(
      options?: any
    ): AxiosPromise<Array<DevorMenuModifierInDB>> {
      return localVarFp
        .getModifiersKitchenMenusModifiersGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
      brandId: string,
      modifierId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<StagedModifierResponse> {
      return localVarFp
        .getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
          brandId,
          modifierId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Set Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ModifierOverride>} modifierOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
      modifierId: string,
      kitchenId: string,
      modifierOverride: Array<ModifierOverride>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
          modifierId,
          kitchenId,
          modifierOverride,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Modifier
     * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModifierKitchenMenusModifiersUpdatePost(
      devorMenuModifierUpdate: DevorMenuModifierUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateModifierKitchenMenusModifiersUpdatePost(
          devorMenuModifierUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ModifiersApi - interface
 * @export
 * @interface ModifiersApi
 */
export interface ModifiersApiInterface {
  /**
   *
   * @summary Create Modifier
   * @param {DevorMenuModifierCreate} devorMenuModifierCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  createModifierKitchenMenusModifiersPost(
    devorMenuModifierCreate: DevorMenuModifierCreate,
    options?: any
  ): AxiosPromise<CreatedResponse>

  /**
   *
   * @summary Delete Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  deleteModifierKitchenMenusModifiersModifierIdDelete(
    modifierId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  getModifierKitchenMenusModifiersModifierIdGet(
    modifierId: string,
    options?: any
  ): AxiosPromise<DevorMenuModifierInDB>

  /**
   *
   * @summary Get Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
    modifierId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<GetModifierPriceOverridesResponse>

  /**
   *
   * @summary Get Modifiers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  getModifiersKitchenMenusModifiersGet(
    options?: any
  ): AxiosPromise<Array<DevorMenuModifierInDB>>

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
    brandId: string,
    modifierId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<StagedModifierResponse>

  /**
   *
   * @summary Set Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ModifierOverride>} modifierOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
    modifierId: string,
    kitchenId: string,
    modifierOverride: Array<ModifierOverride>,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Modifier
   * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApiInterface
   */
  updateModifierKitchenMenusModifiersUpdatePost(
    devorMenuModifierUpdate: DevorMenuModifierUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * ModifiersApi - object-oriented interface
 * @export
 * @class ModifiersApi
 * @extends {BaseAPI}
 */
export class ModifiersApi extends BaseAPI implements ModifiersApiInterface {
  /**
   *
   * @summary Create Modifier
   * @param {DevorMenuModifierCreate} devorMenuModifierCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public createModifierKitchenMenusModifiersPost(
    devorMenuModifierCreate: DevorMenuModifierCreate,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .createModifierKitchenMenusModifiersPost(devorMenuModifierCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public deleteModifierKitchenMenusModifiersModifierIdDelete(
    modifierId: string,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .deleteModifierKitchenMenusModifiersModifierIdDelete(modifierId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public getModifierKitchenMenusModifiersModifierIdGet(
    modifierId: string,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .getModifierKitchenMenusModifiersModifierIdGet(modifierId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
    modifierId: string,
    kitchenId: string,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
        modifierId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Modifiers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public getModifiersKitchenMenusModifiersGet(options?: any) {
    return ModifiersApiFp(this.configuration)
      .getModifiersKitchenMenusModifiersGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
    brandId: string,
    modifierId: string,
    kitchenId: string,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
        brandId,
        modifierId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Set Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ModifierOverride>} modifierOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
    modifierId: string,
    kitchenId: string,
    modifierOverride: Array<ModifierOverride>,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
        modifierId,
        kitchenId,
        modifierOverride,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Modifier
   * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModifiersApi
   */
  public updateModifierKitchenMenusModifiersUpdatePost(
    devorMenuModifierUpdate: DevorMenuModifierUpdate,
    options?: any
  ) {
    return ModifiersApiFp(this.configuration)
      .updateModifierKitchenMenusModifiersUpdatePost(
        devorMenuModifierUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
