import {
  AuthenticationApiFactory,
  KitchensApiFactory,
  ScopeType,
} from '@/api-client'
import Vue from 'vue'
import { IS_PROD_BUILD } from './globals'
import { isValue } from './helpers'
import axios from 'axios'

type UserInfo = { username: string; password: string }

export const authAPI = AuthenticationApiFactory(undefined, '')
const kitchensAPI = KitchensApiFactory(undefined, '')

export enum LogInError {
  InvalidParameters,
  InvalidUsernamePasswordPair,
  NoKitchenAccess,
  AmbiguousKitchenAccess,
  FirebaseError,
  NoResponse,
  Other,
}

export function isLoggedInToKitchen(): boolean {
  return (
    Vue.$accessor.auth.isLoggedIn &&
    !!Vue.$accessor.settings.lastSelectedKitchen
  )
}

/** Read the Vuex store and update the Sentry user accordingly. */
export function updateSentryUser() {
  const user = Vue.$accessor.auth.user
  if (user) {
    Vue.$sentry.setUser({ username: user.username })
    Vue.$sentry.setTag(
      'kitchen',
      Vue.$accessor.settings.lastSelectedKitchen ?? undefined
    )
  } else {
    Vue.$sentry.setUser(null)
    Vue.$sentry.setTag('kitchen', undefined)
  }
}

export async function logIn({
  username,
  password,
}: UserInfo): Promise<LogInError | null> {
  try {
    const { data: loggedInUser } = await authAPI.loginAuthLoginPost(
      username,
      password,
      IS_PROD_BUILD ? ScopeType.Kitchen : undefined
    )

    const firebasePromise = authAPI
      .getFirebaseTokenAuthFirebaseAuthGet()
      .then(({ data: { firebase_token: firebaseToken } }) =>
        Vue.$firebase.auth().signInWithCustomToken(firebaseToken)
      )

    // While firebase is authenticating...
    // ...save new user
    Vue.$accessor.auth.setUser(loggedInUser)
    // ...save kitchen ID
    const kitchenIDs = loggedInUser.scopes
      .filter((scope) => scope.scope_type === ScopeType.Kitchen)
      .map((scope) => scope.scope_id)
      .filter(isValue)
    if (kitchenIDs.length === 0) {
      return LogInError.NoKitchenAccess
    } else if (kitchenIDs.length == 1)
      // Only one kitchen available, so select it
      Vue.$accessor.settings.setKitchenSelection(kitchenIDs[0])

    // Set Sentry context
    updateSentryUser()

    try {
      await firebasePromise
      if (kitchenIDs.length > 1) return LogInError.AmbiguousKitchenAccess
      else return null
    } catch (error) {
      // Firebase Log-in error
      Vue.$sentry.captureException(error)
      // Reset user
      await Vue.$accessor.auth.resetUser()
      Vue.$accessor.settings.resetKitchenSelection()
      updateSentryUser()
      return LogInError.FirebaseError
    }
  } catch (error) {
    await Vue.$accessor.auth.resetUser()
    Vue.$accessor.settings.resetKitchenSelection()
    updateSentryUser()
    if (axios.isAxiosError(error) && error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      switch (error.response.status) {
        case 400:
        case 422:
        case 403:
          return LogInError.InvalidParameters
        case 401:
          return LogInError.InvalidUsernamePasswordPair
        default:
          Vue.$sentry.captureException(error, (scope) => {
            scope.setUser({ username })
            return scope
          })
          return LogInError.Other
      }
    } else {
      Vue.$sentry.captureException(error, (scope) => {
        scope.setUser({ username })
        return scope
      })
      return LogInError.NoResponse
    }
  }
}

export async function logOut() {
  const logOutPromise = authAPI.logoutAuthDelete()

  // Remove from store
  const resetUserPromise = Vue.$accessor.auth.resetUser()
  Vue.$accessor.settings.resetKitchenSelection()

  try {
    await logOutPromise
  } catch (error) {
    Vue.$sentry.captureException(error)
    updateSentryUser()
    // Nothing else to do
    return false
  }
  await resetUserPromise
  updateSentryUser()
  return true
}

export async function getUser() {
  try {
    const { data } = await authAPI.getAuthenticatedUserAuthGet()
    return data
  } catch {
    return null
  }
}

export async function getKitchens() {
  try {
    const { data } = await kitchensAPI.getKitchensKitchensGet()
    return data
  } catch {
    return null
  }
}
