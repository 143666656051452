/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An Enum of allergens
 * @export
 * @enum {string}
 */
export enum DevorAllergenType {
  Gluten = 'gluten',
  Peanuts = 'peanuts',
  Nuts = 'nuts',
  Eggs = 'eggs',
  Fish = 'fish',
  Soybeans = 'soybeans',
  Milk = 'milk',
  Crustaceans = 'crustaceans',
  Molluscs = 'molluscs',
  Celery = 'celery',
  Mustard = 'mustard',
  SesameSeeds = 'sesame_seeds',
  Lupin = 'lupin',
  SulphurDioxideSulphites = 'sulphur_dioxide_sulphites',
}
