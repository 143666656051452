/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BrandSubscriptionInDB } from '../model'
// @ts-ignore
import { BrandSubscriptionStatus } from '../model'
// @ts-ignore
import { FranchiseClient } from '../model'
// @ts-ignore
import { FranchiseCreationResponse } from '../model'
// @ts-ignore
import { FranchiseInDB } from '../model'
// @ts-ignore
import { FranchiseeInfoCreate } from '../model'
// @ts-ignore
import { FranchiseeInfoUpdate } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { StoreBrandCreateValidated } from '../model'
// @ts-ignore
import { StoreBrandInDb } from '../model'
// @ts-ignore
import { StoreBrandRequestResponse } from '../model'
// @ts-ignore
import { StoreBrandUpdate } from '../model'
/**
 * BackofficeApi - axios parameter creator
 * @export
 */
export const BackofficeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Franchisee
     * @param {FranchiseeInfoCreate} franchiseeInfoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFranchiseeFranchiseesPost: async (
      franchiseeInfoCreate: FranchiseeInfoCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeInfoCreate' is not null or undefined
      assertParamExists(
        'createFranchiseeFranchiseesPost',
        'franchiseeInfoCreate',
        franchiseeInfoCreate
      )
      const localVarPath = `/franchisees`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        franchiseeInfoCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Store Brand
     * @param {StoreBrandCreateValidated} storeBrandCreateValidated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStoreBrandBrandsStorePost: async (
      storeBrandCreateValidated: StoreBrandCreateValidated,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeBrandCreateValidated' is not null or undefined
      assertParamExists(
        'createStoreBrandBrandsStorePost',
        'storeBrandCreateValidated',
        storeBrandCreateValidated
      )
      const localVarPath = `/brands-store`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeBrandCreateValidated,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Franchisee
     * @param {string} franchiseeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFranchiseeFranchiseesFranchiseeIdDelete: async (
      franchiseeId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'deleteFranchiseeFranchiseesFranchiseeIdDelete',
        'franchiseeId',
        franchiseeId
      )
      const localVarPath = `/franchisees/{franchisee_id}`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete one specific store brand. (Meant to be used by Backoffice users)
     * @summary Delete Store Brand
     * @param {string} storeBrandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreBrandBrandsStoreStoreBrandIdDelete: async (
      storeBrandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeBrandId' is not null or undefined
      assertParamExists(
        'deleteStoreBrandBrandsStoreStoreBrandIdDelete',
        'storeBrandId',
        storeBrandId
      )
      const localVarPath = `/brands-store/{store_brand_id}`.replace(
        `{${'store_brand_id'}}`,
        encodeURIComponent(String(storeBrandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete a request to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Delete Store Brand Request
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete: async (
      requestId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists(
        'deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete',
        'requestId',
        requestId
      )
      const localVarPath = `/brands-store/requests/{request_id}`.replace(
        `{${'request_id'}}`,
        encodeURIComponent(String(requestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return all store brands. (Meant to be used by Backoffice users)
     * @summary Get All Store Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStoreBrandsBrandsStoreGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands-store`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return any request to subscribe on a brand.  Use this to know if there are any requests to subscribe on a brand.
     * @summary Get Any Store Brand Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands-store/requests/any-pending`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesFullFranchiseesFullGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees/full`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesLightFranchiseesGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return one specific store brand. (Meant to be used by Backoffice users)
     * @summary Get Store Brand
     * @param {string} storeBrandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreBrandBrandsStoreStoreBrandIdGet: async (
      storeBrandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeBrandId' is not null or undefined
      assertParamExists(
        'getStoreBrandBrandsStoreStoreBrandIdGet',
        'storeBrandId',
        storeBrandId
      )
      const localVarPath = `/brands-store/{store_brand_id}`.replace(
        `{${'store_brand_id'}}`,
        encodeURIComponent(String(storeBrandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return all requests to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Get Store Brand Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreBrandRequestsBrandsStoreRequestsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands-store/requests`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Franchisee From Backoffice
     * @param {string} franchiseeId
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut: async (
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut',
        'franchiseeId',
        franchiseeId
      )
      // verify required parameter 'franchiseeInfoUpdate' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut',
        'franchiseeInfoUpdate',
        franchiseeInfoUpdate
      )
      const localVarPath = `/franchisees/{franchisee_id}`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        franchiseeInfoUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update the status of a request to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Update Request Status
     * @param {string} requestId
     * @param {BrandSubscriptionStatus} newStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost: async (
      requestId: string,
      newStatus: BrandSubscriptionStatus,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists(
        'updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost',
        'requestId',
        requestId
      )
      // verify required parameter 'newStatus' is not null or undefined
      assertParamExists(
        'updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost',
        'newStatus',
        newStatus
      )
      const localVarPath = `/brands-store/requests/{request_id}/update-state`.replace(
        `{${'request_id'}}`,
        encodeURIComponent(String(requestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (newStatus !== undefined) {
        localVarQueryParameter['new_status'] = newStatus
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Store Brand
     * @param {StoreBrandUpdate} storeBrandUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreBrandBrandsStorePut: async (
      storeBrandUpdate: StoreBrandUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeBrandUpdate' is not null or undefined
      assertParamExists(
        'updateStoreBrandBrandsStorePut',
        'storeBrandUpdate',
        storeBrandUpdate
      )
      const localVarPath = `/brands-store`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeBrandUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BackofficeApi - functional programming interface
 * @export
 */
export const BackofficeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BackofficeApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Create Franchisee
     * @param {FranchiseeInfoCreate} franchiseeInfoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFranchiseeFranchiseesPost(
      franchiseeInfoCreate: FranchiseeInfoCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FranchiseCreationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFranchiseeFranchiseesPost(
        franchiseeInfoCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Store Brand
     * @param {StoreBrandCreateValidated} storeBrandCreateValidated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStoreBrandBrandsStorePost(
      storeBrandCreateValidated: StoreBrandCreateValidated,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreBrandInDb>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createStoreBrandBrandsStorePost(
        storeBrandCreateValidated,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Franchisee
     * @param {string} franchiseeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFranchiseeFranchiseesFranchiseeIdDelete(
      franchiseeId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFranchiseeFranchiseesFranchiseeIdDelete(
        franchiseeId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Delete one specific store brand. (Meant to be used by Backoffice users)
     * @summary Delete Store Brand
     * @param {string} storeBrandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStoreBrandBrandsStoreStoreBrandIdDelete(
      storeBrandId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreBrandBrandsStoreStoreBrandIdDelete(
        storeBrandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Delete a request to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Delete Store Brand Request
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
      requestId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
        requestId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return all store brands. (Meant to be used by Backoffice users)
     * @summary Get All Store Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllStoreBrandsBrandsStoreGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreBrandInDb>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStoreBrandsBrandsStoreGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return any request to subscribe on a brand.  Use this to know if there are any requests to subscribe on a brand.
     * @summary Get Any Store Brand Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<StoreBrandRequestResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseesFullFranchiseesFullGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseClient>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseesFullFranchiseesFullGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseesLightFranchiseesGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseesLightFranchiseesGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return one specific store brand. (Meant to be used by Backoffice users)
     * @summary Get Store Brand
     * @param {string} storeBrandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreBrandBrandsStoreStoreBrandIdGet(
      storeBrandId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreBrandInDb>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreBrandBrandsStoreStoreBrandIdGet(
        storeBrandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return all requests to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Get Store Brand Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStoreBrandRequestsBrandsStoreRequestsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandSubscriptionInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreBrandRequestsBrandsStoreRequestsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Franchisee From Backoffice
     * @param {string} franchiseeId
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Update the status of a request to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Update Request Status
     * @param {string} requestId
     * @param {BrandSubscriptionStatus} newStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
      requestId: string,
      newStatus: BrandSubscriptionStatus,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
        requestId,
        newStatus,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Store Brand
     * @param {StoreBrandUpdate} storeBrandUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStoreBrandBrandsStorePut(
      storeBrandUpdate: StoreBrandUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStoreBrandBrandsStorePut(
        storeBrandUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * BackofficeApi - factory interface
 * @export
 */
export const BackofficeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BackofficeApiFp(configuration)
  return {
    /**
     *
     * @summary Create Franchisee
     * @param {FranchiseeInfoCreate} franchiseeInfoCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFranchiseeFranchiseesPost(
      franchiseeInfoCreate: FranchiseeInfoCreate,
      options?: any
    ): AxiosPromise<FranchiseCreationResponse> {
      return localVarFp
        .createFranchiseeFranchiseesPost(franchiseeInfoCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Store Brand
     * @param {StoreBrandCreateValidated} storeBrandCreateValidated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStoreBrandBrandsStorePost(
      storeBrandCreateValidated: StoreBrandCreateValidated,
      options?: any
    ): AxiosPromise<StoreBrandInDb> {
      return localVarFp
        .createStoreBrandBrandsStorePost(storeBrandCreateValidated, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Franchisee
     * @param {string} franchiseeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFranchiseeFranchiseesFranchiseeIdDelete(
      franchiseeId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFranchiseeFranchiseesFranchiseeIdDelete(franchiseeId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Delete one specific store brand. (Meant to be used by Backoffice users)
     * @summary Delete Store Brand
     * @param {string} storeBrandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreBrandBrandsStoreStoreBrandIdDelete(
      storeBrandId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteStoreBrandBrandsStoreStoreBrandIdDelete(storeBrandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Delete a request to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Delete Store Brand Request
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
      requestId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
          requestId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Return all store brands. (Meant to be used by Backoffice users)
     * @summary Get All Store Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStoreBrandsBrandsStoreGet(
      options?: any
    ): AxiosPromise<Array<StoreBrandInDb>> {
      return localVarFp
        .getAllStoreBrandsBrandsStoreGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return any request to subscribe on a brand.  Use this to know if there are any requests to subscribe on a brand.
     * @summary Get Any Store Brand Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(
      options?: any
    ): AxiosPromise<StoreBrandRequestResponse> {
      return localVarFp
        .getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesFullFranchiseesFullGet(
      options?: any
    ): AxiosPromise<Array<FranchiseClient>> {
      return localVarFp
        .getFranchiseesFullFranchiseesFullGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseesLightFranchiseesGet(
      options?: any
    ): AxiosPromise<Array<FranchiseInDB>> {
      return localVarFp
        .getFranchiseesLightFranchiseesGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return one specific store brand. (Meant to be used by Backoffice users)
     * @summary Get Store Brand
     * @param {string} storeBrandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreBrandBrandsStoreStoreBrandIdGet(
      storeBrandId: string,
      options?: any
    ): AxiosPromise<StoreBrandInDb> {
      return localVarFp
        .getStoreBrandBrandsStoreStoreBrandIdGet(storeBrandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return all requests to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Get Store Brand Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStoreBrandRequestsBrandsStoreRequestsGet(
      options?: any
    ): AxiosPromise<Array<BrandSubscriptionInDB>> {
      return localVarFp
        .getStoreBrandRequestsBrandsStoreRequestsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Franchisee From Backoffice
     * @param {string} franchiseeId
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
          franchiseeId,
          franchiseeInfoUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Update the status of a request to subscribe on a brand. (Meant to be used by Backoffice users)
     * @summary Update Request Status
     * @param {string} requestId
     * @param {BrandSubscriptionStatus} newStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
      requestId: string,
      newStatus: BrandSubscriptionStatus,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
          requestId,
          newStatus,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Store Brand
     * @param {StoreBrandUpdate} storeBrandUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStoreBrandBrandsStorePut(
      storeBrandUpdate: StoreBrandUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateStoreBrandBrandsStorePut(storeBrandUpdate, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * BackofficeApi - interface
 * @export
 * @interface BackofficeApi
 */
export interface BackofficeApiInterface {
  /**
   *
   * @summary Create Franchisee
   * @param {FranchiseeInfoCreate} franchiseeInfoCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  createFranchiseeFranchiseesPost(
    franchiseeInfoCreate: FranchiseeInfoCreate,
    options?: any
  ): AxiosPromise<FranchiseCreationResponse>

  /**
   *
   * @summary Create Store Brand
   * @param {StoreBrandCreateValidated} storeBrandCreateValidated
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  createStoreBrandBrandsStorePost(
    storeBrandCreateValidated: StoreBrandCreateValidated,
    options?: any
  ): AxiosPromise<StoreBrandInDb>

  /**
   *
   * @summary Delete Franchisee
   * @param {string} franchiseeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  deleteFranchiseeFranchiseesFranchiseeIdDelete(
    franchiseeId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Delete one specific store brand. (Meant to be used by Backoffice users)
   * @summary Delete Store Brand
   * @param {string} storeBrandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  deleteStoreBrandBrandsStoreStoreBrandIdDelete(
    storeBrandId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Delete a request to subscribe on a brand. (Meant to be used by Backoffice users)
   * @summary Delete Store Brand Request
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
    requestId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Return all store brands. (Meant to be used by Backoffice users)
   * @summary Get All Store Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  getAllStoreBrandsBrandsStoreGet(
    options?: any
  ): AxiosPromise<Array<StoreBrandInDb>>

  /**
   * Return any request to subscribe on a brand.  Use this to know if there are any requests to subscribe on a brand.
   * @summary Get Any Store Brand Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(
    options?: any
  ): AxiosPromise<StoreBrandRequestResponse>

  /**
   *
   * @summary Get Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  getFranchiseesFullFranchiseesFullGet(
    options?: any
  ): AxiosPromise<Array<FranchiseClient>>

  /**
   *
   * @summary Get Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  getFranchiseesLightFranchiseesGet(
    options?: any
  ): AxiosPromise<Array<FranchiseInDB>>

  /**
   * Return one specific store brand. (Meant to be used by Backoffice users)
   * @summary Get Store Brand
   * @param {string} storeBrandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  getStoreBrandBrandsStoreStoreBrandIdGet(
    storeBrandId: string,
    options?: any
  ): AxiosPromise<StoreBrandInDb>

  /**
   * Return all requests to subscribe on a brand. (Meant to be used by Backoffice users)
   * @summary Get Store Brand Requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  getStoreBrandRequestsBrandsStoreRequestsGet(
    options?: any
  ): AxiosPromise<Array<BrandSubscriptionInDB>>

  /**
   *
   * @summary Update Franchisee From Backoffice
   * @param {string} franchiseeId
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   * Update the status of a request to subscribe on a brand. (Meant to be used by Backoffice users)
   * @summary Update Request Status
   * @param {string} requestId
   * @param {BrandSubscriptionStatus} newStatus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
    requestId: string,
    newStatus: BrandSubscriptionStatus,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Store Brand
   * @param {StoreBrandUpdate} storeBrandUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApiInterface
   */
  updateStoreBrandBrandsStorePut(
    storeBrandUpdate: StoreBrandUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * BackofficeApi - object-oriented interface
 * @export
 * @class BackofficeApi
 * @extends {BaseAPI}
 */
export class BackofficeApi extends BaseAPI implements BackofficeApiInterface {
  /**
   *
   * @summary Create Franchisee
   * @param {FranchiseeInfoCreate} franchiseeInfoCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public createFranchiseeFranchiseesPost(
    franchiseeInfoCreate: FranchiseeInfoCreate,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .createFranchiseeFranchiseesPost(franchiseeInfoCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Store Brand
   * @param {StoreBrandCreateValidated} storeBrandCreateValidated
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public createStoreBrandBrandsStorePost(
    storeBrandCreateValidated: StoreBrandCreateValidated,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .createStoreBrandBrandsStorePost(storeBrandCreateValidated, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Franchisee
   * @param {string} franchiseeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public deleteFranchiseeFranchiseesFranchiseeIdDelete(
    franchiseeId: string,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .deleteFranchiseeFranchiseesFranchiseeIdDelete(franchiseeId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete one specific store brand. (Meant to be used by Backoffice users)
   * @summary Delete Store Brand
   * @param {string} storeBrandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public deleteStoreBrandBrandsStoreStoreBrandIdDelete(
    storeBrandId: string,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .deleteStoreBrandBrandsStoreStoreBrandIdDelete(storeBrandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete a request to subscribe on a brand. (Meant to be used by Backoffice users)
   * @summary Delete Store Brand Request
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
    requestId: string,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .deleteStoreBrandRequestBrandsStoreRequestsRequestIdDelete(
        requestId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return all store brands. (Meant to be used by Backoffice users)
   * @summary Get All Store Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public getAllStoreBrandsBrandsStoreGet(options?: any) {
    return BackofficeApiFp(this.configuration)
      .getAllStoreBrandsBrandsStoreGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return any request to subscribe on a brand.  Use this to know if there are any requests to subscribe on a brand.
   * @summary Get Any Store Brand Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .getAnyStoreBrandRequestBrandsStoreRequestsAnyPendingGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public getFranchiseesFullFranchiseesFullGet(options?: any) {
    return BackofficeApiFp(this.configuration)
      .getFranchiseesFullFranchiseesFullGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public getFranchiseesLightFranchiseesGet(options?: any) {
    return BackofficeApiFp(this.configuration)
      .getFranchiseesLightFranchiseesGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return one specific store brand. (Meant to be used by Backoffice users)
   * @summary Get Store Brand
   * @param {string} storeBrandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public getStoreBrandBrandsStoreStoreBrandIdGet(
    storeBrandId: string,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .getStoreBrandBrandsStoreStoreBrandIdGet(storeBrandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return all requests to subscribe on a brand. (Meant to be used by Backoffice users)
   * @summary Get Store Brand Requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public getStoreBrandRequestsBrandsStoreRequestsGet(options?: any) {
    return BackofficeApiFp(this.configuration)
      .getStoreBrandRequestsBrandsStoreRequestsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Franchisee From Backoffice
   * @param {string} franchiseeId
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .updateFranchiseeFromBackofficeFranchiseesFranchiseeIdPut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update the status of a request to subscribe on a brand. (Meant to be used by Backoffice users)
   * @summary Update Request Status
   * @param {string} requestId
   * @param {BrandSubscriptionStatus} newStatus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
    requestId: string,
    newStatus: BrandSubscriptionStatus,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .updateRequestStatusBrandsStoreRequestsRequestIdUpdateStatePost(
        requestId,
        newStatus,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Store Brand
   * @param {StoreBrandUpdate} storeBrandUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackofficeApi
   */
  public updateStoreBrandBrandsStorePut(
    storeBrandUpdate: StoreBrandUpdate,
    options?: any
  ) {
    return BackofficeApiFp(this.configuration)
      .updateStoreBrandBrandsStorePut(storeBrandUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
