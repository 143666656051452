/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
/**
 * CRONTasksApi - axios parameter creator
 * @export
 */
export const CRONTasksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Move all orders that are more than 24 hours old from Firestore to Mongo.
     * @summary Clean Firestore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanFirestoreCronCleanFirestorePost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/cron/clean-firestore`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingPingGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate and push deepsight data for yesterday.  This endpoint is meant to be called every night.
     * @summary Push Deepsight Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushDeepsightDataCronUpdateDeepsightPost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/cron/update-deepsight`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Reset availabilities for providers that don\'t do it automatically (like Deliveroo)
     * @summary Reset Availabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetAvailabilitiesCronResetAvailabilitiesPost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/cron/reset-availabilities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CRONTasksApi - functional programming interface
 * @export
 */
export const CRONTasksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CRONTasksApiAxiosParamCreator(configuration)
  return {
    /**
     * Move all orders that are more than 24 hours old from Firestore to Mongo.
     * @summary Clean Firestore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cleanFirestoreCronCleanFirestorePost(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cleanFirestoreCronCleanFirestorePost(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pingPingGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pingPingGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Generate and push deepsight data for yesterday.  This endpoint is meant to be called every night.
     * @summary Push Deepsight Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pushDeepsightDataCronUpdateDeepsightPost(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pushDeepsightDataCronUpdateDeepsightPost(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Reset availabilities for providers that don\'t do it automatically (like Deliveroo)
     * @summary Reset Availabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetAvailabilitiesCronResetAvailabilitiesPost(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetAvailabilitiesCronResetAvailabilitiesPost(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * CRONTasksApi - factory interface
 * @export
 */
export const CRONTasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CRONTasksApiFp(configuration)
  return {
    /**
     * Move all orders that are more than 24 hours old from Firestore to Mongo.
     * @summary Clean Firestore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cleanFirestoreCronCleanFirestorePost(options?: any): AxiosPromise<void> {
      return localVarFp
        .cleanFirestoreCronCleanFirestorePost(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pingPingGet(options?: any): AxiosPromise<any> {
      return localVarFp
        .pingPingGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate and push deepsight data for yesterday.  This endpoint is meant to be called every night.
     * @summary Push Deepsight Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushDeepsightDataCronUpdateDeepsightPost(
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .pushDeepsightDataCronUpdateDeepsightPost(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Reset availabilities for providers that don\'t do it automatically (like Deliveroo)
     * @summary Reset Availabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetAvailabilitiesCronResetAvailabilitiesPost(
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resetAvailabilitiesCronResetAvailabilitiesPost(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CRONTasksApi - interface
 * @export
 * @interface CRONTasksApi
 */
export interface CRONTasksApiInterface {
  /**
   * Move all orders that are more than 24 hours old from Firestore to Mongo.
   * @summary Clean Firestore
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApiInterface
   */
  cleanFirestoreCronCleanFirestorePost(options?: any): AxiosPromise<void>

  /**
   *
   * @summary Ping
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApiInterface
   */
  pingPingGet(options?: any): AxiosPromise<any>

  /**
   * Generate and push deepsight data for yesterday.  This endpoint is meant to be called every night.
   * @summary Push Deepsight Data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApiInterface
   */
  pushDeepsightDataCronUpdateDeepsightPost(options?: any): AxiosPromise<void>

  /**
   * Reset availabilities for providers that don\'t do it automatically (like Deliveroo)
   * @summary Reset Availabilities
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApiInterface
   */
  resetAvailabilitiesCronResetAvailabilitiesPost(
    options?: any
  ): AxiosPromise<void>
}

/**
 * CRONTasksApi - object-oriented interface
 * @export
 * @class CRONTasksApi
 * @extends {BaseAPI}
 */
export class CRONTasksApi extends BaseAPI implements CRONTasksApiInterface {
  /**
   * Move all orders that are more than 24 hours old from Firestore to Mongo.
   * @summary Clean Firestore
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApi
   */
  public cleanFirestoreCronCleanFirestorePost(options?: any) {
    return CRONTasksApiFp(this.configuration)
      .cleanFirestoreCronCleanFirestorePost(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Ping
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApi
   */
  public pingPingGet(options?: any) {
    return CRONTasksApiFp(this.configuration)
      .pingPingGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate and push deepsight data for yesterday.  This endpoint is meant to be called every night.
   * @summary Push Deepsight Data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApi
   */
  public pushDeepsightDataCronUpdateDeepsightPost(options?: any) {
    return CRONTasksApiFp(this.configuration)
      .pushDeepsightDataCronUpdateDeepsightPost(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Reset availabilities for providers that don\'t do it automatically (like Deliveroo)
   * @summary Reset Availabilities
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CRONTasksApi
   */
  public resetAvailabilitiesCronResetAvailabilitiesPost(options?: any) {
    return CRONTasksApiFp(this.configuration)
      .resetAvailabilitiesCronResetAvailabilitiesPost(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
