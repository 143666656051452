/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BrandInDB } from '../model'
// @ts-ignore
import { BrandInDBWithMenu } from '../model'
// @ts-ignore
import { CreatedResponse } from '../model'
// @ts-ignore
import { DevorMenuCreate } from '../model'
// @ts-ignore
import { DevorMenuDraftInDB } from '../model'
// @ts-ignore
import { DevorMenuEntityTypeEnum } from '../model'
// @ts-ignore
import { DevorMenuItemCreate } from '../model'
// @ts-ignore
import { DevorMenuItemInDB } from '../model'
// @ts-ignore
import { DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides } from '../model'
// @ts-ignore
import { DevorMenuItemUpdate } from '../model'
// @ts-ignore
import { DevorMenuModifierCreate } from '../model'
// @ts-ignore
import { DevorMenuModifierInDB } from '../model'
// @ts-ignore
import { DevorMenuModifierUpdate } from '../model'
// @ts-ignore
import { DevorMenuPublishedInDB } from '../model'
// @ts-ignore
import { DevorMenuUpdate } from '../model'
// @ts-ignore
import { GetItemPriceOverridesResponse } from '../model'
// @ts-ignore
import { GetModifierPriceOverridesResponse } from '../model'
// @ts-ignore
import { GroupingTagCreate } from '../model'
// @ts-ignore
import { GroupingTagInDB } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { ItemOverride } from '../model'
// @ts-ignore
import { MissingOverridesResponse } from '../model'
// @ts-ignore
import { ModifierOverride } from '../model'
// @ts-ignore
import { StagedItemResponse } from '../model'
// @ts-ignore
import { StagedModifierResponse } from '../model'
// @ts-ignore
import { UnpublishedBrands } from '../model'
/**
 * KitchenMenusApi - axios parameter creator
 * @export
 */
export const KitchenMenusApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Archive Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveMenuKitchenMenusMenusMenuIdArchivePost: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'archiveMenuKitchenMenusMenusMenuIdArchivePost',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}/archive`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Compute and return a menu as it would be if this middleoffice user were to publish it.
     * @summary Compute Publishable Menu
     * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost: async (
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuStagingId' is not null or undefined
      assertParamExists(
        'computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost',
        'menuStagingId',
        menuStagingId
      )
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost',
        'brandId',
        brandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_staging_id}/preview-publishable/{kitchen_id}/{brand_id}`
        .replace(
          `{${'menu_staging_id'}}`,
          encodeURIComponent(String(menuStagingId))
        )
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
     * @summary Compute Staging Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}/preview-staging`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Grouping Tag
     * @param {GroupingTagCreate} groupingTagCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGroupingTagKitchenMenusGroupingTagsPost: async (
      groupingTagCreate: GroupingTagCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagCreate' is not null or undefined
      assertParamExists(
        'createGroupingTagKitchenMenusGroupingTagsPost',
        'groupingTagCreate',
        groupingTagCreate
      )
      const localVarPath = `/kitchen-menus/grouping-tags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        groupingTagCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Item
     * @param {DevorMenuItemCreate} devorMenuItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createItemKitchenMenusItemsPost: async (
      devorMenuItemCreate: DevorMenuItemCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuItemCreate' is not null or undefined
      assertParamExists(
        'createItemKitchenMenusItemsPost',
        'devorMenuItemCreate',
        devorMenuItemCreate
      )
      const localVarPath = `/kitchen-menus/items`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuItemCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Menu
     * @param {DevorMenuCreate} devorMenuCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMenuKitchenMenusMenusPost: async (
      devorMenuCreate: DevorMenuCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuCreate' is not null or undefined
      assertParamExists(
        'createMenuKitchenMenusMenusPost',
        'devorMenuCreate',
        devorMenuCreate
      )
      const localVarPath = `/kitchen-menus/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Modifier
     * @param {DevorMenuModifierCreate} devorMenuModifierCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModifierKitchenMenusModifiersPost: async (
      devorMenuModifierCreate: DevorMenuModifierCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuModifierCreate' is not null or undefined
      assertParamExists(
        'createModifierKitchenMenusModifiersPost',
        'devorMenuModifierCreate',
        devorMenuModifierCreate
      )
      const localVarPath = `/kitchen-menus/modifiers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuModifierCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete: async (
      groupingTagId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagId' is not null or undefined
      assertParamExists(
        'deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete',
        'groupingTagId',
        groupingTagId
      )
      const localVarPath = `/kitchen-menus/grouping-tags/{grouping_tag_id}`.replace(
        `{${'grouping_tag_id'}}`,
        encodeURIComponent(String(groupingTagId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteItemKitchenMenusItemsItemIdDelete: async (
      itemId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'deleteItemKitchenMenusItemsItemIdDelete',
        'itemId',
        itemId
      )
      const localVarPath = `/kitchen-menus/items/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMenuKitchenMenusMenusMenuIdDelete: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'deleteMenuKitchenMenusMenusMenuIdDelete',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModifierKitchenMenusModifiersModifierIdDelete: async (
      modifierId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'deleteModifierKitchenMenusModifiersModifierIdDelete',
        'modifierId',
        modifierId
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}`.replace(
        `{${'modifier_id'}}`,
        encodeURIComponent(String(modifierId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get All Menus
     * @param {boolean} [archived] If True, the response will include menus that are archived.
     * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMenusKitchenMenusMenusGet: async (
      archived?: boolean,
      nonArchived?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (archived !== undefined) {
        localVarQueryParameter['archived'] = archived
      }

      if (nonArchived !== undefined) {
        localVarQueryParameter['non_archived'] = nonArchived
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandKitchenMenusBrandsBrandIdGet: async (
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getBrandKitchenMenusBrandsBrandIdGet',
        'brandId',
        brandId
      )
      const localVarPath = `/kitchen-menus/brands/{brand_id}`.replace(
        `{${'brand_id'}}`,
        encodeURIComponent(String(brandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet: async (
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet',
        'brandId',
        brandId
      )
      const localVarPath = `/kitchen-menus/brands/menus/{brand_id}`.replace(
        `{${'brand_id'}}`,
        encodeURIComponent(String(brandId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsKitchenMenusBrandsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/brands`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsWithStagedMenuKitchenMenusBrandsMenusGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/brands/menus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Entities Used In An Active Menu
     * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet: async (
      entityTypes: Array<DevorMenuEntityTypeEnum>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityTypes' is not null or undefined
      assertParamExists(
        'getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet',
        'entityTypes',
        entityTypes
      )
      const localVarPath = `/kitchen-menus/menus/entities/used-in-active-menu`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (entityTypes) {
        localVarQueryParameter['entity_types'] = entityTypes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet: async (
      groupingTagId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagId' is not null or undefined
      assertParamExists(
        'getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet',
        'groupingTagId',
        groupingTagId
      )
      const localVarPath = `/kitchen-menus/grouping-tags/{grouping_tag_id}`.replace(
        `{${'grouping_tag_id'}}`,
        encodeURIComponent(String(groupingTagId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagsKitchenMenusGroupingTagsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/grouping-tags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemKitchenMenusItemsItemIdGet: async (
      itemId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('getItemKitchenMenusItemsItemIdGet', 'itemId', itemId)
      const localVarPath = `/kitchen-menus/items/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet: async (
      itemId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet',
        'itemId',
        itemId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/items/{item_id}/price-overrides/{kitchen_id}`
        .replace(`{${'item_id'}}`, encodeURIComponent(String(itemId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemsKitchenMenusItemsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/items`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenuKitchenMenusMenusMenuIdGet: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists('getMenuKitchenMenusMenusMenuIdGet', 'menuId', menuId)
      const localVarPath = `/kitchen-menus/menus/{menu_id}`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Missing Staged Menu Overrides
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet: async (
      brandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet',
        'brandId',
        brandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/menus/missing-overrides/{kitchen_id}/{brand_id}`
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierKitchenMenusModifiersModifierIdGet: async (
      modifierId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'getModifierKitchenMenusModifiersModifierIdGet',
        'modifierId',
        modifierId
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}`.replace(
        `{${'modifier_id'}}`,
        encodeURIComponent(String(modifierId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet: async (
      modifierId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet',
        'modifierId',
        modifierId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}/price-overrides/{kitchen_id}`
        .replace(`{${'modifier_id'}}`, encodeURIComponent(String(modifierId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Modifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifiersKitchenMenusModifiersGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/modifiers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Published Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedMenusKitchenMenusMenusPublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/menus/published`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet: async (
      brandId: string,
      itemId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet',
        'brandId',
        brandId
      )
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet',
        'itemId',
        itemId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/items/staged/{brand_id}/{item_id}/{kitchen_id}`
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'item_id'}}`, encodeURIComponent(String(itemId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet: async (
      brandId: string,
      modifierId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet',
        'brandId',
        brandId
      )
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet',
        'modifierId',
        modifierId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/modifiers/staged/{brand_id}/{modifier_id}/{kitchen_id}`
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'modifier_id'}}`, encodeURIComponent(String(modifierId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/brands/unpublished`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Publish Brand Menu For Kitchen
     * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost: async (
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuStagingId' is not null or undefined
      assertParamExists(
        'publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost',
        'menuStagingId',
        menuStagingId
      )
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost',
        'brandId',
        brandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_staging_id}/publish/{kitchen_id}/{brand_id}`
        .replace(
          `{${'menu_staging_id'}}`,
          encodeURIComponent(String(menuStagingId))
        )
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Set Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ItemOverride>} itemOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost: async (
      itemId: string,
      kitchenId: string,
      itemOverride: Array<ItemOverride>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists(
        'setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost',
        'itemId',
        itemId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'itemOverride' is not null or undefined
      assertParamExists(
        'setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost',
        'itemOverride',
        itemOverride
      )
      const localVarPath = `/kitchen-menus/items/{item_id}/price-overrides/{kitchen_id}`
        .replace(`{${'item_id'}}`, encodeURIComponent(String(itemId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        itemOverride,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Set Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ModifierOverride>} modifierOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost: async (
      modifierId: string,
      kitchenId: string,
      modifierOverride: Array<ModifierOverride>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modifierId' is not null or undefined
      assertParamExists(
        'setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost',
        'modifierId',
        modifierId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'modifierOverride' is not null or undefined
      assertParamExists(
        'setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost',
        'modifierOverride',
        modifierOverride
      )
      const localVarPath = `/kitchen-menus/modifiers/{modifier_id}/price-overrides/{kitchen_id}`
        .replace(`{${'modifier_id'}}`, encodeURIComponent(String(modifierId)))
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        modifierOverride,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Stage For Publication
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost: async (
      menuId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'menuId' is not null or undefined
      assertParamExists(
        'stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost',
        'menuId',
        menuId
      )
      const localVarPath = `/kitchen-menus/menus/{menu_id}/stage-for-publication`.replace(
        `{${'menu_id'}}`,
        encodeURIComponent(String(menuId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Item
     * @param {DevorMenuItemUpdate} devorMenuItemUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateItemKitchenMenusItemsUpdatePost: async (
      devorMenuItemUpdate: DevorMenuItemUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuItemUpdate' is not null or undefined
      assertParamExists(
        'updateItemKitchenMenusItemsUpdatePost',
        'devorMenuItemUpdate',
        devorMenuItemUpdate
      )
      const localVarPath = `/kitchen-menus/items/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuItemUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Menu
     * @param {DevorMenuUpdate} devorMenuUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMenuKitchenMenusMenusUpdatePost: async (
      devorMenuUpdate: DevorMenuUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuUpdate' is not null or undefined
      assertParamExists(
        'updateMenuKitchenMenusMenusUpdatePost',
        'devorMenuUpdate',
        devorMenuUpdate
      )
      const localVarPath = `/kitchen-menus/menus/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Modifier
     * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModifierKitchenMenusModifiersUpdatePost: async (
      devorMenuModifierUpdate: DevorMenuModifierUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'devorMenuModifierUpdate' is not null or undefined
      assertParamExists(
        'updateModifierKitchenMenusModifiersUpdatePost',
        'devorMenuModifierUpdate',
        devorMenuModifierUpdate
      )
      const localVarPath = `/kitchen-menus/modifiers/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorMenuModifierUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * KitchenMenusApi - functional programming interface
 * @export
 */
export const KitchenMenusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = KitchenMenusApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Archive Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveMenuKitchenMenusMenusMenuIdArchivePost(
      menuId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveMenuKitchenMenusMenusMenuIdArchivePost(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Compute and return a menu as it would be if this middleoffice user were to publish it.
     * @summary Compute Publishable Menu
     * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuPublishedInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
     * @summary Compute Staging Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
      menuId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuPublishedInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Grouping Tag
     * @param {GroupingTagCreate} groupingTagCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGroupingTagKitchenMenusGroupingTagsPost(
      groupingTagCreate: GroupingTagCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupingTagInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupingTagKitchenMenusGroupingTagsPost(
        groupingTagCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Item
     * @param {DevorMenuItemCreate} devorMenuItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createItemKitchenMenusItemsPost(
      devorMenuItemCreate: DevorMenuItemCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createItemKitchenMenusItemsPost(
        devorMenuItemCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Menu
     * @param {DevorMenuCreate} devorMenuCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMenuKitchenMenusMenusPost(
      devorMenuCreate: DevorMenuCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMenuKitchenMenusMenusPost(
        devorMenuCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Modifier
     * @param {DevorMenuModifierCreate} devorMenuModifierCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createModifierKitchenMenusModifiersPost(
      devorMenuModifierCreate: DevorMenuModifierCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createModifierKitchenMenusModifiersPost(
        devorMenuModifierCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
      groupingTagId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
        groupingTagId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteItemKitchenMenusItemsItemIdDelete(
      itemId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemKitchenMenusItemsItemIdDelete(
        itemId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMenuKitchenMenusMenusMenuIdDelete(
      menuId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMenuKitchenMenusMenusMenuIdDelete(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteModifierKitchenMenusModifiersModifierIdDelete(
      modifierId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModifierKitchenMenusModifiersModifierIdDelete(
        modifierId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get All Menus
     * @param {boolean} [archived] If True, the response will include menus that are archived.
     * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllMenusKitchenMenusMenusGet(
      archived?: boolean,
      nonArchived?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorMenuDraftInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMenusKitchenMenusMenusGet(
        archived,
        nonArchived,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandKitchenMenusBrandsBrandIdGet(
      brandId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandKitchenMenusBrandsBrandIdGet(
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
      brandId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BrandInDBWithMenu>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandsKitchenMenusBrandsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsKitchenMenusBrandsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandInDBWithMenu>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Entities Used In An Active Menu
     * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
      entityTypes: Array<DevorMenuEntityTypeEnum>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
        entityTypes,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
      groupingTagId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupingTagInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
        groupingTagId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupingTagsKitchenMenusGroupingTagsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<GroupingTagInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupingTagsKitchenMenusGroupingTagsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemKitchenMenusItemsItemIdGet(
      itemId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuItemInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getItemKitchenMenusItemsItemIdGet(
        itemId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
      itemId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetItemPriceOverridesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
        itemId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getItemsKitchenMenusItemsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        Array<DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides>
      >
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsKitchenMenusItemsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMenuKitchenMenusMenusMenuIdGet(
      menuId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuDraftInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuKitchenMenusMenusMenuIdGet(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Missing Staged Menu Overrides
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
      brandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MissingOverridesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
        brandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModifierKitchenMenusModifiersModifierIdGet(
      modifierId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DevorMenuModifierInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModifierKitchenMenusModifiersModifierIdGet(
        modifierId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
      modifierId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetModifierPriceOverridesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
        modifierId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Modifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModifiersKitchenMenusModifiersGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<DevorMenuModifierInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModifiersKitchenMenusModifiersGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Published Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPublishedMenusKitchenMenusMenusPublishedGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedMenusKitchenMenusMenusPublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
      brandId: string,
      itemId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<StagedItemResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
        brandId,
        itemId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
      brandId: string,
      modifierId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<StagedModifierResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
        brandId,
        modifierId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<UnpublishedBrands>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Publish Brand Menu For Kitchen
     * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Set Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ItemOverride>} itemOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
      itemId: string,
      kitchenId: string,
      itemOverride: Array<ItemOverride>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
        itemId,
        kitchenId,
        itemOverride,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Set Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ModifierOverride>} modifierOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
      modifierId: string,
      kitchenId: string,
      modifierOverride: Array<ModifierOverride>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
        modifierId,
        kitchenId,
        modifierOverride,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Stage For Publication
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
      menuId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
        menuId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Item
     * @param {DevorMenuItemUpdate} devorMenuItemUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateItemKitchenMenusItemsUpdatePost(
      devorMenuItemUpdate: DevorMenuItemUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemKitchenMenusItemsUpdatePost(
        devorMenuItemUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Menu
     * @param {DevorMenuUpdate} devorMenuUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMenuKitchenMenusMenusUpdatePost(
      devorMenuUpdate: DevorMenuUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMenuKitchenMenusMenusUpdatePost(
        devorMenuUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Modifier
     * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModifierKitchenMenusModifiersUpdatePost(
      devorMenuModifierUpdate: DevorMenuModifierUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateModifierKitchenMenusModifiersUpdatePost(
        devorMenuModifierUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * KitchenMenusApi - factory interface
 * @export
 */
export const KitchenMenusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = KitchenMenusApiFp(configuration)
  return {
    /**
     *
     * @summary Archive Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveMenuKitchenMenusMenusMenuIdArchivePost(
      menuId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .archiveMenuKitchenMenusMenusMenuIdArchivePost(menuId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Compute and return a menu as it would be if this middleoffice user were to publish it.
     * @summary Compute Publishable Menu
     * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<DevorMenuPublishedInDB> {
      return localVarFp
        .computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
          menuStagingId,
          brandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
     * @summary Compute Staging Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
      menuId: string,
      options?: any
    ): AxiosPromise<DevorMenuPublishedInDB> {
      return localVarFp
        .computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
          menuId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Grouping Tag
     * @param {GroupingTagCreate} groupingTagCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGroupingTagKitchenMenusGroupingTagsPost(
      groupingTagCreate: GroupingTagCreate,
      options?: any
    ): AxiosPromise<GroupingTagInDB> {
      return localVarFp
        .createGroupingTagKitchenMenusGroupingTagsPost(
          groupingTagCreate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Item
     * @param {DevorMenuItemCreate} devorMenuItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createItemKitchenMenusItemsPost(
      devorMenuItemCreate: DevorMenuItemCreate,
      options?: any
    ): AxiosPromise<CreatedResponse> {
      return localVarFp
        .createItemKitchenMenusItemsPost(devorMenuItemCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Menu
     * @param {DevorMenuCreate} devorMenuCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMenuKitchenMenusMenusPost(
      devorMenuCreate: DevorMenuCreate,
      options?: any
    ): AxiosPromise<CreatedResponse> {
      return localVarFp
        .createMenuKitchenMenusMenusPost(devorMenuCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Modifier
     * @param {DevorMenuModifierCreate} devorMenuModifierCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModifierKitchenMenusModifiersPost(
      devorMenuModifierCreate: DevorMenuModifierCreate,
      options?: any
    ): AxiosPromise<CreatedResponse> {
      return localVarFp
        .createModifierKitchenMenusModifiersPost(
          devorMenuModifierCreate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
      groupingTagId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
          groupingTagId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteItemKitchenMenusItemsItemIdDelete(
      itemId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteItemKitchenMenusItemsItemIdDelete(itemId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMenuKitchenMenusMenusMenuIdDelete(
      menuId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteMenuKitchenMenusMenusMenuIdDelete(menuId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModifierKitchenMenusModifiersModifierIdDelete(
      modifierId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteModifierKitchenMenusModifiersModifierIdDelete(
          modifierId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get All Menus
     * @param {boolean} [archived] If True, the response will include menus that are archived.
     * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllMenusKitchenMenusMenusGet(
      archived?: boolean,
      nonArchived?: boolean,
      options?: any
    ): AxiosPromise<Array<DevorMenuDraftInDB>> {
      return localVarFp
        .getAllMenusKitchenMenusMenusGet(archived, nonArchived, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brand
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandKitchenMenusBrandsBrandIdGet(
      brandId: string,
      options?: any
    ): AxiosPromise<BrandInDB> {
      return localVarFp
        .getBrandKitchenMenusBrandsBrandIdGet(brandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brand With Menu
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
      brandId: string,
      options?: any
    ): AxiosPromise<BrandInDBWithMenu> {
      return localVarFp
        .getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(brandId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsKitchenMenusBrandsGet(
      options?: any
    ): AxiosPromise<Array<BrandInDB>> {
      return localVarFp
        .getBrandsKitchenMenusBrandsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Brands With Staged Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
      options?: any
    ): AxiosPromise<Array<BrandInDBWithMenu>> {
      return localVarFp
        .getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Entities Used In An Active Menu
     * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
      entityTypes: Array<DevorMenuEntityTypeEnum>,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
          entityTypes,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
      groupingTagId: string,
      options?: any
    ): AxiosPromise<GroupingTagInDB> {
      return localVarFp
        .getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
          groupingTagId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagsKitchenMenusGroupingTagsGet(
      options?: any
    ): AxiosPromise<Array<GroupingTagInDB>> {
      return localVarFp
        .getGroupingTagsKitchenMenusGroupingTagsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Item
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemKitchenMenusItemsItemIdGet(
      itemId: string,
      options?: any
    ): AxiosPromise<DevorMenuItemInDB> {
      return localVarFp
        .getItemKitchenMenusItemsItemIdGet(itemId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
      itemId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<GetItemPriceOverridesResponse> {
      return localVarFp
        .getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
          itemId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getItemsKitchenMenusItemsGet(
      options?: any
    ): AxiosPromise<
      Array<DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides>
    > {
      return localVarFp
        .getItemsKitchenMenusItemsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Menu
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenuKitchenMenusMenusMenuIdGet(
      menuId: string,
      options?: any
    ): AxiosPromise<DevorMenuDraftInDB> {
      return localVarFp
        .getMenuKitchenMenusMenusMenuIdGet(menuId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Missing Staged Menu Overrides
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
      brandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<MissingOverridesResponse> {
      return localVarFp
        .getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
          brandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Modifier
     * @param {string} modifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierKitchenMenusModifiersModifierIdGet(
      modifierId: string,
      options?: any
    ): AxiosPromise<DevorMenuModifierInDB> {
      return localVarFp
        .getModifierKitchenMenusModifiersModifierIdGet(modifierId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
      modifierId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<GetModifierPriceOverridesResponse> {
      return localVarFp
        .getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
          modifierId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Modifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModifiersKitchenMenusModifiersGet(
      options?: any
    ): AxiosPromise<Array<DevorMenuModifierInDB>> {
      return localVarFp
        .getModifiersKitchenMenusModifiersGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Published Menus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedMenusKitchenMenusMenusPublishedGet(
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getPublishedMenusKitchenMenusMenusPublishedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
      brandId: string,
      itemId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<StagedItemResponse> {
      return localVarFp
        .getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
          brandId,
          itemId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Staged Item
     * @param {string} brandId
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
      brandId: string,
      modifierId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<StagedModifierResponse> {
      return localVarFp
        .getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
          brandId,
          modifierId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Unpublished Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
      options?: any
    ): AxiosPromise<Array<UnpublishedBrands>> {
      return localVarFp
        .getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Publish Brand Menu For Kitchen
     * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
     * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
      menuStagingId: string,
      brandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
          menuStagingId,
          brandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Set Item Price Overrides
     * @param {string} itemId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ItemOverride>} itemOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
      itemId: string,
      kitchenId: string,
      itemOverride: Array<ItemOverride>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
          itemId,
          kitchenId,
          itemOverride,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Set Modifier Price Overrides
     * @param {string} modifierId
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<ModifierOverride>} modifierOverride
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
      modifierId: string,
      kitchenId: string,
      modifierOverride: Array<ModifierOverride>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
          modifierId,
          kitchenId,
          modifierOverride,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Stage For Publication
     * @param {string} menuId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
      menuId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
          menuId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Item
     * @param {DevorMenuItemUpdate} devorMenuItemUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateItemKitchenMenusItemsUpdatePost(
      devorMenuItemUpdate: DevorMenuItemUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateItemKitchenMenusItemsUpdatePost(devorMenuItemUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Menu
     * @param {DevorMenuUpdate} devorMenuUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMenuKitchenMenusMenusUpdatePost(
      devorMenuUpdate: DevorMenuUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateMenuKitchenMenusMenusUpdatePost(devorMenuUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Modifier
     * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModifierKitchenMenusModifiersUpdatePost(
      devorMenuModifierUpdate: DevorMenuModifierUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateModifierKitchenMenusModifiersUpdatePost(
          devorMenuModifierUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * KitchenMenusApi - interface
 * @export
 * @interface KitchenMenusApi
 */
export interface KitchenMenusApiInterface {
  /**
   *
   * @summary Archive Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  archiveMenuKitchenMenusMenusMenuIdArchivePost(
    menuId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Compute and return a menu as it would be if this middleoffice user were to publish it.
   * @summary Compute Publishable Menu
   * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<DevorMenuPublishedInDB>

  /**
   * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
   * @summary Compute Staging Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
    menuId: string,
    options?: any
  ): AxiosPromise<DevorMenuPublishedInDB>

  /**
   *
   * @summary Create Grouping Tag
   * @param {GroupingTagCreate} groupingTagCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  createGroupingTagKitchenMenusGroupingTagsPost(
    groupingTagCreate: GroupingTagCreate,
    options?: any
  ): AxiosPromise<GroupingTagInDB>

  /**
   *
   * @summary Create Item
   * @param {DevorMenuItemCreate} devorMenuItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  createItemKitchenMenusItemsPost(
    devorMenuItemCreate: DevorMenuItemCreate,
    options?: any
  ): AxiosPromise<CreatedResponse>

  /**
   *
   * @summary Create Menu
   * @param {DevorMenuCreate} devorMenuCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  createMenuKitchenMenusMenusPost(
    devorMenuCreate: DevorMenuCreate,
    options?: any
  ): AxiosPromise<CreatedResponse>

  /**
   *
   * @summary Create Modifier
   * @param {DevorMenuModifierCreate} devorMenuModifierCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  createModifierKitchenMenusModifiersPost(
    devorMenuModifierCreate: DevorMenuModifierCreate,
    options?: any
  ): AxiosPromise<CreatedResponse>

  /**
   *
   * @summary Delete Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
    groupingTagId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Delete Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  deleteItemKitchenMenusItemsItemIdDelete(
    itemId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Delete Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  deleteMenuKitchenMenusMenusMenuIdDelete(
    menuId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Delete Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  deleteModifierKitchenMenusModifiersModifierIdDelete(
    modifierId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get All Menus
   * @param {boolean} [archived] If True, the response will include menus that are archived.
   * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getAllMenusKitchenMenusMenusGet(
    archived?: boolean,
    nonArchived?: boolean,
    options?: any
  ): AxiosPromise<Array<DevorMenuDraftInDB>>

  /**
   *
   * @summary Get Brand
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getBrandKitchenMenusBrandsBrandIdGet(
    brandId: string,
    options?: any
  ): AxiosPromise<BrandInDB>

  /**
   *
   * @summary Get Brand With Menu
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
    brandId: string,
    options?: any
  ): AxiosPromise<BrandInDBWithMenu>

  /**
   *
   * @summary Get Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getBrandsKitchenMenusBrandsGet(options?: any): AxiosPromise<Array<BrandInDB>>

  /**
   *
   * @summary Get Brands With Staged Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(
    options?: any
  ): AxiosPromise<Array<BrandInDBWithMenu>>

  /**
   *
   * @summary Get Entities Used In An Active Menu
   * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
    entityTypes: Array<DevorMenuEntityTypeEnum>,
    options?: any
  ): AxiosPromise<Array<string>>

  /**
   *
   * @summary Get Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
    groupingTagId: string,
    options?: any
  ): AxiosPromise<GroupingTagInDB>

  /**
   *
   * @summary Get Grouping Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getGroupingTagsKitchenMenusGroupingTagsGet(
    options?: any
  ): AxiosPromise<Array<GroupingTagInDB>>

  /**
   *
   * @summary Get Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getItemKitchenMenusItemsItemIdGet(
    itemId: string,
    options?: any
  ): AxiosPromise<DevorMenuItemInDB>

  /**
   *
   * @summary Get Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
    itemId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<GetItemPriceOverridesResponse>

  /**
   *
   * @summary Get Items
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getItemsKitchenMenusItemsGet(
    options?: any
  ): AxiosPromise<Array<DevorMenuItemInDBDevorMenuPriceRulesWithoutOverrides>>

  /**
   *
   * @summary Get Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getMenuKitchenMenusMenusMenuIdGet(
    menuId: string,
    options?: any
  ): AxiosPromise<DevorMenuDraftInDB>

  /**
   *
   * @summary Get Missing Staged Menu Overrides
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
    brandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<MissingOverridesResponse>

  /**
   *
   * @summary Get Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getModifierKitchenMenusModifiersModifierIdGet(
    modifierId: string,
    options?: any
  ): AxiosPromise<DevorMenuModifierInDB>

  /**
   *
   * @summary Get Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
    modifierId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<GetModifierPriceOverridesResponse>

  /**
   *
   * @summary Get Modifiers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getModifiersKitchenMenusModifiersGet(
    options?: any
  ): AxiosPromise<Array<DevorMenuModifierInDB>>

  /**
   *
   * @summary Get Published Menus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getPublishedMenusKitchenMenusMenusPublishedGet(
    options?: any
  ): AxiosPromise<Array<string>>

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
    brandId: string,
    itemId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<StagedItemResponse>

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
    brandId: string,
    modifierId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<StagedModifierResponse>

  /**
   *
   * @summary Get Unpublished Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(
    options?: any
  ): AxiosPromise<Array<UnpublishedBrands>>

  /**
   *
   * @summary Publish Brand Menu For Kitchen
   * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Set Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ItemOverride>} itemOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
    itemId: string,
    kitchenId: string,
    itemOverride: Array<ItemOverride>,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Set Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ModifierOverride>} modifierOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
    modifierId: string,
    kitchenId: string,
    modifierOverride: Array<ModifierOverride>,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Stage For Publication
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
    menuId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Item
   * @param {DevorMenuItemUpdate} devorMenuItemUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  updateItemKitchenMenusItemsUpdatePost(
    devorMenuItemUpdate: DevorMenuItemUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Menu
   * @param {DevorMenuUpdate} devorMenuUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  updateMenuKitchenMenusMenusUpdatePost(
    devorMenuUpdate: DevorMenuUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Modifier
   * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApiInterface
   */
  updateModifierKitchenMenusModifiersUpdatePost(
    devorMenuModifierUpdate: DevorMenuModifierUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * KitchenMenusApi - object-oriented interface
 * @export
 * @class KitchenMenusApi
 * @extends {BaseAPI}
 */
export class KitchenMenusApi
  extends BaseAPI
  implements KitchenMenusApiInterface {
  /**
   *
   * @summary Archive Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public archiveMenuKitchenMenusMenusMenuIdArchivePost(
    menuId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .archiveMenuKitchenMenusMenusMenuIdArchivePost(menuId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Compute and return a menu as it would be if this middleoffice user were to publish it.
   * @summary Compute Publishable Menu
   * @param {string} menuStagingId The staging ID of the menu to fake-publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to fake-publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .computePublishableMenuKitchenMenusMenusMenuStagingIdPreviewPublishableKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Compute and return a menu as it would be if a middleoffice user were to publish it with all the maximum prices.
   * @summary Compute Staging Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
    menuId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .computeStagingMenuKitchenMenusMenusMenuIdPreviewStagingPost(
        menuId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Grouping Tag
   * @param {GroupingTagCreate} groupingTagCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public createGroupingTagKitchenMenusGroupingTagsPost(
    groupingTagCreate: GroupingTagCreate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .createGroupingTagKitchenMenusGroupingTagsPost(groupingTagCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Item
   * @param {DevorMenuItemCreate} devorMenuItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public createItemKitchenMenusItemsPost(
    devorMenuItemCreate: DevorMenuItemCreate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .createItemKitchenMenusItemsPost(devorMenuItemCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Menu
   * @param {DevorMenuCreate} devorMenuCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public createMenuKitchenMenusMenusPost(
    devorMenuCreate: DevorMenuCreate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .createMenuKitchenMenusMenusPost(devorMenuCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Modifier
   * @param {DevorMenuModifierCreate} devorMenuModifierCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public createModifierKitchenMenusModifiersPost(
    devorMenuModifierCreate: DevorMenuModifierCreate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .createModifierKitchenMenusModifiersPost(devorMenuModifierCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
    groupingTagId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
        groupingTagId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public deleteItemKitchenMenusItemsItemIdDelete(
    itemId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .deleteItemKitchenMenusItemsItemIdDelete(itemId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public deleteMenuKitchenMenusMenusMenuIdDelete(
    menuId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .deleteMenuKitchenMenusMenusMenuIdDelete(menuId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public deleteModifierKitchenMenusModifiersModifierIdDelete(
    modifierId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .deleteModifierKitchenMenusModifiersModifierIdDelete(modifierId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get All Menus
   * @param {boolean} [archived] If True, the response will include menus that are archived.
   * @param {boolean} [nonArchived] If True, the response will include menus that are not archived.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getAllMenusKitchenMenusMenusGet(
    archived?: boolean,
    nonArchived?: boolean,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getAllMenusKitchenMenusMenusGet(archived, nonArchived, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brand
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getBrandKitchenMenusBrandsBrandIdGet(brandId: string, options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getBrandKitchenMenusBrandsBrandIdGet(brandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brand With Menu
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(
    brandId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getBrandWithMenuKitchenMenusBrandsMenusBrandIdGet(brandId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getBrandsKitchenMenusBrandsGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getBrandsKitchenMenusBrandsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Brands With Staged Menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getBrandsWithStagedMenuKitchenMenusBrandsMenusGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Entities Used In An Active Menu
   * @param {Array<DevorMenuEntityTypeEnum>} entityTypes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
    entityTypes: Array<DevorMenuEntityTypeEnum>,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getEntitiesUsedInAnActiveMenuKitchenMenusMenusEntitiesUsedInActiveMenuGet(
        entityTypes,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
    groupingTagId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
        groupingTagId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Grouping Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getGroupingTagsKitchenMenusGroupingTagsGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getGroupingTagsKitchenMenusGroupingTagsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Item
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getItemKitchenMenusItemsItemIdGet(itemId: string, options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getItemKitchenMenusItemsItemIdGet(itemId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
    itemId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdGet(
        itemId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Items
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getItemsKitchenMenusItemsGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getItemsKitchenMenusItemsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Menu
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getMenuKitchenMenusMenusMenuIdGet(menuId: string, options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getMenuKitchenMenusMenusMenuIdGet(menuId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Missing Staged Menu Overrides
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
    brandId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getMissingStagedMenuOverridesKitchenMenusMenusMissingOverridesKitchenIdBrandIdGet(
        brandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Modifier
   * @param {string} modifierId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getModifierKitchenMenusModifiersModifierIdGet(
    modifierId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getModifierKitchenMenusModifiersModifierIdGet(modifierId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
    modifierId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdGet(
        modifierId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Modifiers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getModifiersKitchenMenusModifiersGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getModifiersKitchenMenusModifiersGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Published Menus
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getPublishedMenusKitchenMenusMenusPublishedGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getPublishedMenusKitchenMenusMenusPublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
    brandId: string,
    itemId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getStagedItemKitchenMenusItemsStagedBrandIdItemIdKitchenIdGet(
        brandId,
        itemId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Staged Item
   * @param {string} brandId
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
    brandId: string,
    modifierId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .getStagedItemKitchenMenusModifiersStagedBrandIdModifierIdKitchenIdGet(
        brandId,
        modifierId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Unpublished Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(options?: any) {
    return KitchenMenusApiFp(this.configuration)
      .getUnpublishedBrandsKitchenMenusBrandsUnpublishedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Publish Brand Menu For Kitchen
   * @param {string} menuStagingId The staging ID of the menu to publish. Used as a failguard in case a new menu was pushed in the meantime.
   * @param {string} brandId The ID of the brand to publish. Combined with the &#x60;kitchen_id&#x60;, the pair uniquely identifies a restaurant.
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
    menuStagingId: string,
    brandId: string,
    kitchenId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .publishBrandMenuForKitchenKitchenMenusMenusMenuStagingIdPublishKitchenIdBrandIdPost(
        menuStagingId,
        brandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Set Item Price Overrides
   * @param {string} itemId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ItemOverride>} itemOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
    itemId: string,
    kitchenId: string,
    itemOverride: Array<ItemOverride>,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .setItemPriceOverridesKitchenMenusItemsItemIdPriceOverridesKitchenIdPost(
        itemId,
        kitchenId,
        itemOverride,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Set Modifier Price Overrides
   * @param {string} modifierId
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<ModifierOverride>} modifierOverride
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
    modifierId: string,
    kitchenId: string,
    modifierOverride: Array<ModifierOverride>,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .setModifierPriceOverridesKitchenMenusModifiersModifierIdPriceOverridesKitchenIdPost(
        modifierId,
        kitchenId,
        modifierOverride,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Stage For Publication
   * @param {string} menuId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
    menuId: string,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .stageForPublicationKitchenMenusMenusMenuIdStageForPublicationPost(
        menuId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Item
   * @param {DevorMenuItemUpdate} devorMenuItemUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public updateItemKitchenMenusItemsUpdatePost(
    devorMenuItemUpdate: DevorMenuItemUpdate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .updateItemKitchenMenusItemsUpdatePost(devorMenuItemUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Menu
   * @param {DevorMenuUpdate} devorMenuUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public updateMenuKitchenMenusMenusUpdatePost(
    devorMenuUpdate: DevorMenuUpdate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .updateMenuKitchenMenusMenusUpdatePost(devorMenuUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Modifier
   * @param {DevorMenuModifierUpdate} devorMenuModifierUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenMenusApi
   */
  public updateModifierKitchenMenusModifiersUpdatePost(
    devorMenuModifierUpdate: DevorMenuModifierUpdate,
    options?: any
  ) {
    return KitchenMenusApiFp(this.configuration)
      .updateModifierKitchenMenusModifiersUpdatePost(
        devorMenuModifierUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
