import _Vue from 'vue'
import firebase from 'firebase/app'
// Required for side-effects
import 'firebase/firestore'
import 'firebase/auth'
export interface GlobalFirebaseT {
  auth: () => firebase.auth.Auth
  firestore: () => firebase.firestore.Firestore
}

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function FirestorePlugin(Vue: typeof _Vue): void {
  // Initialize App
  firebase.initializeApp({
    apiKey: 'AIzaSyBSP_O4nawbCBdSP1veXNoEw6j1tfge1jk',
    authDomain: 'devor-fr.firebaseapp.com',
    projectId: 'devor-fr',
    storageBucket: 'devor-fr.appspot.com',
    messagingSenderId: '821224845393',
    appId: '1:821224845393:web:9687577ec833ad4b46c870',
  })

  // Install Firebase as global variables
  Vue.$firebase = Vue.prototype.$firebase = {
    auth: () => firebase.auth(),
    firestore: () => firebase.firestore(),
  }
}
