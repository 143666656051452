/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { DevorCancellationRequest } from '../model'
// @ts-ignore
import { DevorOrderPreparationState } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
     * @summary Acknowledge Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost: async (
      firestoreOrderId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'firestoreOrderId' is not null or undefined
      assertParamExists(
        'acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost',
        'firestoreOrderId',
        firestoreOrderId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/{firestore_order_id}/acknowledge`
        .replace(
          `{${'firestore_order_id'}}`,
          encodeURIComponent(String(firestoreOrderId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
     * @summary Acknowledge Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost: async (
      kitchenId: string,
      requestBody: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists(
        'acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost',
        'requestBody',
        requestBody
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/acknowledge`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Cancel an order from its Firestore ID.
     * @summary Cancel Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorCancellationRequest} devorCancellationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost: async (
      firestoreOrderId: string,
      kitchenId: string,
      devorCancellationRequest: DevorCancellationRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'firestoreOrderId' is not null or undefined
      assertParamExists(
        'cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost',
        'firestoreOrderId',
        firestoreOrderId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'devorCancellationRequest' is not null or undefined
      assertParamExists(
        'cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost',
        'devorCancellationRequest',
        devorCancellationRequest
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/{firestore_order_id}/cancel`
        .replace(
          `{${'firestore_order_id'}}`,
          encodeURIComponent(String(firestoreOrderId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorCancellationRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Clear Old Firestore Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {string} [ordersBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost: async (
      kitchenId: string,
      ordersBefore?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/clear`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ordersBefore !== undefined) {
        localVarQueryParameter['orders_before'] =
          (ordersBefore as any) instanceof Date
            ? (ordersBefore as any).toISOString()
            : ordersBefore
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
     * @summary Update Preparation State
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost: async (
      firestoreOrderId: string,
      kitchenId: string,
      newPreparationState: DevorOrderPreparationState,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'firestoreOrderId' is not null or undefined
      assertParamExists(
        'updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost',
        'firestoreOrderId',
        firestoreOrderId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'newPreparationState' is not null or undefined
      assertParamExists(
        'updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost',
        'newPreparationState',
        newPreparationState
      )
      const localVarPath = `/kitchens/{kitchen_id}/orders/{firestore_order_id}/preparation-state`
        .replace(
          `{${'firestore_order_id'}}`,
          encodeURIComponent(String(firestoreOrderId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (newPreparationState !== undefined) {
        localVarQueryParameter['new_preparation_state'] = newPreparationState
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
  return {
    /**
     * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
     * @summary Acknowledge Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
      firestoreOrderId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
        firestoreOrderId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
     * @summary Acknowledge Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
      kitchenId: string,
      requestBody: Array<string>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
        kitchenId,
        requestBody,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Cancel an order from its Firestore ID.
     * @summary Cancel Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorCancellationRequest} devorCancellationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
      firestoreOrderId: string,
      kitchenId: string,
      devorCancellationRequest: DevorCancellationRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
        firestoreOrderId,
        kitchenId,
        devorCancellationRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Clear Old Firestore Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {string} [ordersBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
      kitchenId: string,
      ordersBefore?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
        kitchenId,
        ordersBefore,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
     * @summary Update Preparation State
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
      firestoreOrderId: string,
      kitchenId: string,
      newPreparationState: DevorOrderPreparationState,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
        firestoreOrderId,
        kitchenId,
        newPreparationState,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrdersApiFp(configuration)
  return {
    /**
     * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
     * @summary Acknowledge Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
      firestoreOrderId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
          firestoreOrderId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
     * @summary Acknowledge Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
      kitchenId: string,
      requestBody: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
          kitchenId,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Cancel an order from its Firestore ID.
     * @summary Cancel Order
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorCancellationRequest} devorCancellationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
      firestoreOrderId: string,
      kitchenId: string,
      devorCancellationRequest: DevorCancellationRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
          firestoreOrderId,
          kitchenId,
          devorCancellationRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Clear Old Firestore Orders
     * @param {string} kitchenId The ID of the kitchen
     * @param {string} [ordersBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
      kitchenId: string,
      ordersBefore?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
          kitchenId,
          ordersBefore,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
     * @summary Update Preparation State
     * @param {string} firestoreOrderId The ID of the order in the Firestore DB
     * @param {string} kitchenId The ID of the kitchen
     * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
      firestoreOrderId: string,
      kitchenId: string,
      newPreparationState: DevorOrderPreparationState,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
          firestoreOrderId,
          kitchenId,
          newPreparationState,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * OrdersApi - interface
 * @export
 * @interface OrdersApi
 */
export interface OrdersApiInterface {
  /**
   * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
   * @summary Acknowledge Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApiInterface
   */
  acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
    firestoreOrderId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
   * @summary Acknowledge Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApiInterface
   */
  acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
    kitchenId: string,
    requestBody: Array<string>,
    options?: any
  ): AxiosPromise<void>

  /**
   * Cancel an order from its Firestore ID.
   * @summary Cancel Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorCancellationRequest} devorCancellationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApiInterface
   */
  cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
    firestoreOrderId: string,
    kitchenId: string,
    devorCancellationRequest: DevorCancellationRequest,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Clear Old Firestore Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {string} [ordersBefore]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApiInterface
   */
  clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
    kitchenId: string,
    ordersBefore?: string,
    options?: any
  ): AxiosPromise<void>

  /**
   * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
   * @summary Update Preparation State
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApiInterface
   */
  updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
    firestoreOrderId: string,
    kitchenId: string,
    newPreparationState: DevorOrderPreparationState,
    options?: any
  ): AxiosPromise<void>
}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI implements OrdersApiInterface {
  /**
   * Acknowledge a single order.  If the Order does not exist, this operation is a no-op and will _not_ return an error.
   * @summary Acknowledge Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
    firestoreOrderId: string,
    kitchenId: string,
    options?: any
  ) {
    return OrdersApiFp(this.configuration)
      .acknowledgeOrderKitchensKitchenIdOrdersFirestoreOrderIdAcknowledgePost(
        firestoreOrderId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Acknowledge a list of Order IDs.  If an ID does not exist, it will simply be skipped and will _not_ return an error.
   * @summary Acknowledge Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
    kitchenId: string,
    requestBody: Array<string>,
    options?: any
  ) {
    return OrdersApiFp(this.configuration)
      .acknowledgeOrdersKitchensKitchenIdOrdersAcknowledgePost(
        kitchenId,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Cancel an order from its Firestore ID.
   * @summary Cancel Order
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorCancellationRequest} devorCancellationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
    firestoreOrderId: string,
    kitchenId: string,
    devorCancellationRequest: DevorCancellationRequest,
    options?: any
  ) {
    return OrdersApiFp(this.configuration)
      .cancelOrderKitchensKitchenIdOrdersFirestoreOrderIdCancelPost(
        firestoreOrderId,
        kitchenId,
        devorCancellationRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Clear Old Firestore Orders
   * @param {string} kitchenId The ID of the kitchen
   * @param {string} [ordersBefore]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
    kitchenId: string,
    ordersBefore?: string,
    options?: any
  ) {
    return OrdersApiFp(this.configuration)
      .clearOldFirestoreOrdersKitchensKitchenIdOrdersClearPost(
        kitchenId,
        ordersBefore,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Change state of a specified order to the specified new state.  These operations only edit the object, and do not return an error if the expected state was already reached. This makes this operation idempotent, meaning two identical consecutive calls will reach the same state as one call.
   * @summary Update Preparation State
   * @param {string} firestoreOrderId The ID of the order in the Firestore DB
   * @param {string} kitchenId The ID of the kitchen
   * @param {DevorOrderPreparationState} newPreparationState The new state to reach.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
    firestoreOrderId: string,
    kitchenId: string,
    newPreparationState: DevorOrderPreparationState,
    options?: any
  ) {
    return OrdersApiFp(this.configuration)
      .updatePreparationStateKitchensKitchenIdOrdersFirestoreOrderIdPreparationStatePost(
        firestoreOrderId,
        kitchenId,
        newPreparationState,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
