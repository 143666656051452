/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BrandCreate } from '../model'
// @ts-ignore
import { BrandInDB } from '../model'
// @ts-ignore
import { BrandUpdate } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
/**
 * BrandsManagementApi - axios parameter creator
 * @export
 */
export const BrandsManagementApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Brand
     * @param {BrandCreate} brandCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBrandBrandsPost: async (
      brandCreate: BrandCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandCreate' is not null or undefined
      assertParamExists('createBrandBrandsPost', 'brandCreate', brandCreate)
      const localVarPath = `/brands`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        brandCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Brand
     * @param {string} kitchenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBrandBrandsKitchenIdDelete: async (
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'deleteBrandBrandsKitchenIdDelete',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/brands/{kitchen_id}`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Brand
     * @param {BrandUpdate} brandUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBrandBrandsPut: async (
      brandUpdate: BrandUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brandUpdate' is not null or undefined
      assertParamExists('updateBrandBrandsPut', 'brandUpdate', brandUpdate)
      const localVarPath = `/brands`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        brandUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BrandsManagementApi - functional programming interface
 * @export
 */
export const BrandsManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BrandsManagementApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Create Brand
     * @param {BrandCreate} brandCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBrandBrandsPost(
      brandCreate: BrandCreate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBrandBrandsPost(
        brandCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Brand
     * @param {string} kitchenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBrandBrandsKitchenIdDelete(
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrandBrandsKitchenIdDelete(
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Brand
     * @param {BrandUpdate} brandUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBrandBrandsPut(
      brandUpdate: BrandUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrandBrandsPut(
        brandUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * BrandsManagementApi - factory interface
 * @export
 */
export const BrandsManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BrandsManagementApiFp(configuration)
  return {
    /**
     *
     * @summary Create Brand
     * @param {BrandCreate} brandCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBrandBrandsPost(
      brandCreate: BrandCreate,
      options?: any
    ): AxiosPromise<BrandInDB> {
      return localVarFp
        .createBrandBrandsPost(brandCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Brand
     * @param {string} kitchenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBrandBrandsKitchenIdDelete(
      kitchenId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteBrandBrandsKitchenIdDelete(kitchenId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Brand
     * @param {BrandUpdate} brandUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBrandBrandsPut(
      brandUpdate: BrandUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateBrandBrandsPut(brandUpdate, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * BrandsManagementApi - interface
 * @export
 * @interface BrandsManagementApi
 */
export interface BrandsManagementApiInterface {
  /**
   *
   * @summary Create Brand
   * @param {BrandCreate} brandCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsManagementApiInterface
   */
  createBrandBrandsPost(
    brandCreate: BrandCreate,
    options?: any
  ): AxiosPromise<BrandInDB>

  /**
   *
   * @summary Delete Brand
   * @param {string} kitchenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsManagementApiInterface
   */
  deleteBrandBrandsKitchenIdDelete(
    kitchenId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Brand
   * @param {BrandUpdate} brandUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsManagementApiInterface
   */
  updateBrandBrandsPut(
    brandUpdate: BrandUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * BrandsManagementApi - object-oriented interface
 * @export
 * @class BrandsManagementApi
 * @extends {BaseAPI}
 */
export class BrandsManagementApi
  extends BaseAPI
  implements BrandsManagementApiInterface {
  /**
   *
   * @summary Create Brand
   * @param {BrandCreate} brandCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsManagementApi
   */
  public createBrandBrandsPost(brandCreate: BrandCreate, options?: any) {
    return BrandsManagementApiFp(this.configuration)
      .createBrandBrandsPost(brandCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Brand
   * @param {string} kitchenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsManagementApi
   */
  public deleteBrandBrandsKitchenIdDelete(kitchenId: string, options?: any) {
    return BrandsManagementApiFp(this.configuration)
      .deleteBrandBrandsKitchenIdDelete(kitchenId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Brand
   * @param {BrandUpdate} brandUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BrandsManagementApi
   */
  public updateBrandBrandsPut(brandUpdate: BrandUpdate, options?: any) {
    return BrandsManagementApiFp(this.configuration)
      .updateBrandBrandsPut(brandUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
