/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UberMenuTaxLabels {
  TempHeated = 'TEMP_HEATED',
  TempUnheated = 'TEMP_UNHEATED',
  TempCold = 'TEMP_COLD',
  CatPreparedFood = 'CAT_PREPARED_FOOD',
  CatDeliPlatter = 'CAT_DELI_PLATTER',
  CatFoodByWtVol = 'CAT_FOOD_BY_WT_VOL',
  CatSandwich = 'CAT_SANDWICH',
  CatIcecream = 'CAT_ICECREAM',
  CatPrepackagedFood = 'CAT_PREPACKAGED_FOOD',
  CatSnack = 'CAT_SNACK',
  CatCandy = 'CAT_CANDY',
  CatAlcohol = 'CAT_ALCOHOL',
  CatJuice = 'CAT_JUICE',
  TraitPct100 = 'TRAIT_PCT_100',
  CatWater = 'CAT_WATER',
  TraitNoncarb = 'TRAIT_NONCARB',
  TraitFlvSwt = 'TRAIT_FLV_SWT',
  TraitUnflvUnswt = 'TRAIT_UNFLV_UNSWT',
  ContainerBottled = 'CONTAINER_BOTTLED',
}
