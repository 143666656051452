/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { DevorServiceAvailabilityUpdate } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { KitchenCreate } from '../model'
// @ts-ignore
import { KitchenLightInDB } from '../model'
// @ts-ignore
import { KitchenUpdate } from '../model'
// @ts-ignore
import { RestaurantCreate } from '../model'
// @ts-ignore
import { RestaurantLightInDB } from '../model'
// @ts-ignore
import { RestaurantUpdate } from '../model'
/**
 * KitchenManagementApi - axios parameter creator
 * @export
 */
export const KitchenManagementApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Kitchen
     * @param {KitchenCreate} kitchenCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createKitchenKitchensPost: async (
      kitchenCreate: KitchenCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenCreate' is not null or undefined
      assertParamExists(
        'createKitchenKitchensPost',
        'kitchenCreate',
        kitchenCreate
      )
      const localVarPath = `/kitchens`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        kitchenCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create Restaurant
     * @param {string} kitchenId
     * @param {RestaurantCreate} restaurantCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRestaurantKitchensKitchenIdRestaurantPost: async (
      kitchenId: string,
      restaurantCreate: RestaurantCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'createRestaurantKitchensKitchenIdRestaurantPost',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'restaurantCreate' is not null or undefined
      assertParamExists(
        'createRestaurantKitchensKitchenIdRestaurantPost',
        'restaurantCreate',
        restaurantCreate
      )
      const localVarPath = `/kitchens/{kitchen_id}/restaurant`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        restaurantCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Kitchen
     * @param {string} kitchenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteKitchenKitchensKitchenIdDelete: async (
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'deleteKitchenKitchensKitchenIdDelete',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/kitchens/{kitchen_id}`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Restaurant
     * @param {string} kitchenId
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete: async (
      kitchenId: string,
      brandId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete',
        'brandId',
        brandId
      )
      const localVarPath = `/kitchens/{kitchen_id}/restaurant/{brand_id}`
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Kitchen
     * @param {KitchenUpdate} kitchenUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKitchenKitchensPut: async (
      kitchenUpdate: KitchenUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenUpdate' is not null or undefined
      assertParamExists(
        'updateKitchenKitchensPut',
        'kitchenUpdate',
        kitchenUpdate
      )
      const localVarPath = `/kitchens`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        kitchenUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Restaurant Hours
     * @param {string} kitchenId
     * @param {string} brandId
     * @param {DevorServiceAvailabilityUpdate} devorServiceAvailabilityUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut: async (
      kitchenId: string,
      brandId: string,
      devorServiceAvailabilityUpdate: DevorServiceAvailabilityUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'brandId' is not null or undefined
      assertParamExists(
        'updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut',
        'brandId',
        brandId
      )
      // verify required parameter 'devorServiceAvailabilityUpdate' is not null or undefined
      assertParamExists(
        'updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut',
        'devorServiceAvailabilityUpdate',
        devorServiceAvailabilityUpdate
      )
      const localVarPath = `/kitchens/{kitchen_id}/restaurant-hours/{brand_id}`
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
        .replace(`{${'brand_id'}}`, encodeURIComponent(String(brandId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        devorServiceAvailabilityUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Restaurant
     * @param {string} kitchenId
     * @param {RestaurantUpdate} restaurantUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRestaurantKitchensKitchenIdRestaurantPut: async (
      kitchenId: string,
      restaurantUpdate: RestaurantUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'updateRestaurantKitchensKitchenIdRestaurantPut',
        'kitchenId',
        kitchenId
      )
      // verify required parameter 'restaurantUpdate' is not null or undefined
      assertParamExists(
        'updateRestaurantKitchensKitchenIdRestaurantPut',
        'restaurantUpdate',
        restaurantUpdate
      )
      const localVarPath = `/kitchens/{kitchen_id}/restaurant`.replace(
        `{${'kitchen_id'}}`,
        encodeURIComponent(String(kitchenId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        restaurantUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * KitchenManagementApi - functional programming interface
 * @export
 */
export const KitchenManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = KitchenManagementApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Create Kitchen
     * @param {KitchenCreate} kitchenCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createKitchenKitchensPost(
      kitchenCreate: KitchenCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<KitchenLightInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createKitchenKitchensPost(
        kitchenCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Create Restaurant
     * @param {string} kitchenId
     * @param {RestaurantCreate} restaurantCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRestaurantKitchensKitchenIdRestaurantPost(
      kitchenId: string,
      restaurantCreate: RestaurantCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RestaurantLightInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRestaurantKitchensKitchenIdRestaurantPost(
        kitchenId,
        restaurantCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Kitchen
     * @param {string} kitchenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteKitchenKitchensKitchenIdDelete(
      kitchenId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKitchenKitchensKitchenIdDelete(
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Restaurant
     * @param {string} kitchenId
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
      kitchenId: string,
      brandId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
        kitchenId,
        brandId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Kitchen
     * @param {KitchenUpdate} kitchenUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateKitchenKitchensPut(
      kitchenUpdate: KitchenUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateKitchenKitchensPut(
        kitchenUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Restaurant Hours
     * @param {string} kitchenId
     * @param {string} brandId
     * @param {DevorServiceAvailabilityUpdate} devorServiceAvailabilityUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
      kitchenId: string,
      brandId: string,
      devorServiceAvailabilityUpdate: DevorServiceAvailabilityUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
        kitchenId,
        brandId,
        devorServiceAvailabilityUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Restaurant
     * @param {string} kitchenId
     * @param {RestaurantUpdate} restaurantUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRestaurantKitchensKitchenIdRestaurantPut(
      kitchenId: string,
      restaurantUpdate: RestaurantUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRestaurantKitchensKitchenIdRestaurantPut(
        kitchenId,
        restaurantUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * KitchenManagementApi - factory interface
 * @export
 */
export const KitchenManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = KitchenManagementApiFp(configuration)
  return {
    /**
     *
     * @summary Create Kitchen
     * @param {KitchenCreate} kitchenCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createKitchenKitchensPost(
      kitchenCreate: KitchenCreate,
      options?: any
    ): AxiosPromise<KitchenLightInDB> {
      return localVarFp
        .createKitchenKitchensPost(kitchenCreate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create Restaurant
     * @param {string} kitchenId
     * @param {RestaurantCreate} restaurantCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRestaurantKitchensKitchenIdRestaurantPost(
      kitchenId: string,
      restaurantCreate: RestaurantCreate,
      options?: any
    ): AxiosPromise<RestaurantLightInDB> {
      return localVarFp
        .createRestaurantKitchensKitchenIdRestaurantPost(
          kitchenId,
          restaurantCreate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Kitchen
     * @param {string} kitchenId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteKitchenKitchensKitchenIdDelete(
      kitchenId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteKitchenKitchensKitchenIdDelete(kitchenId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Restaurant
     * @param {string} kitchenId
     * @param {string} brandId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
      kitchenId: string,
      brandId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
          kitchenId,
          brandId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Kitchen
     * @param {KitchenUpdate} kitchenUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKitchenKitchensPut(
      kitchenUpdate: KitchenUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateKitchenKitchensPut(kitchenUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Restaurant Hours
     * @param {string} kitchenId
     * @param {string} brandId
     * @param {DevorServiceAvailabilityUpdate} devorServiceAvailabilityUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
      kitchenId: string,
      brandId: string,
      devorServiceAvailabilityUpdate: DevorServiceAvailabilityUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
          kitchenId,
          brandId,
          devorServiceAvailabilityUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Restaurant
     * @param {string} kitchenId
     * @param {RestaurantUpdate} restaurantUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRestaurantKitchensKitchenIdRestaurantPut(
      kitchenId: string,
      restaurantUpdate: RestaurantUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateRestaurantKitchensKitchenIdRestaurantPut(
          kitchenId,
          restaurantUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * KitchenManagementApi - interface
 * @export
 * @interface KitchenManagementApi
 */
export interface KitchenManagementApiInterface {
  /**
   *
   * @summary Create Kitchen
   * @param {KitchenCreate} kitchenCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  createKitchenKitchensPost(
    kitchenCreate: KitchenCreate,
    options?: any
  ): AxiosPromise<KitchenLightInDB>

  /**
   *
   * @summary Create Restaurant
   * @param {string} kitchenId
   * @param {RestaurantCreate} restaurantCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  createRestaurantKitchensKitchenIdRestaurantPost(
    kitchenId: string,
    restaurantCreate: RestaurantCreate,
    options?: any
  ): AxiosPromise<RestaurantLightInDB>

  /**
   *
   * @summary Delete Kitchen
   * @param {string} kitchenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  deleteKitchenKitchensKitchenIdDelete(
    kitchenId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Delete Restaurant
   * @param {string} kitchenId
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
    kitchenId: string,
    brandId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Kitchen
   * @param {KitchenUpdate} kitchenUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  updateKitchenKitchensPut(
    kitchenUpdate: KitchenUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Restaurant Hours
   * @param {string} kitchenId
   * @param {string} brandId
   * @param {DevorServiceAvailabilityUpdate} devorServiceAvailabilityUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
    kitchenId: string,
    brandId: string,
    devorServiceAvailabilityUpdate: DevorServiceAvailabilityUpdate,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Update Restaurant
   * @param {string} kitchenId
   * @param {RestaurantUpdate} restaurantUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApiInterface
   */
  updateRestaurantKitchensKitchenIdRestaurantPut(
    kitchenId: string,
    restaurantUpdate: RestaurantUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * KitchenManagementApi - object-oriented interface
 * @export
 * @class KitchenManagementApi
 * @extends {BaseAPI}
 */
export class KitchenManagementApi
  extends BaseAPI
  implements KitchenManagementApiInterface {
  /**
   *
   * @summary Create Kitchen
   * @param {KitchenCreate} kitchenCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public createKitchenKitchensPost(
    kitchenCreate: KitchenCreate,
    options?: any
  ) {
    return KitchenManagementApiFp(this.configuration)
      .createKitchenKitchensPost(kitchenCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create Restaurant
   * @param {string} kitchenId
   * @param {RestaurantCreate} restaurantCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public createRestaurantKitchensKitchenIdRestaurantPost(
    kitchenId: string,
    restaurantCreate: RestaurantCreate,
    options?: any
  ) {
    return KitchenManagementApiFp(this.configuration)
      .createRestaurantKitchensKitchenIdRestaurantPost(
        kitchenId,
        restaurantCreate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Kitchen
   * @param {string} kitchenId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public deleteKitchenKitchensKitchenIdDelete(
    kitchenId: string,
    options?: any
  ) {
    return KitchenManagementApiFp(this.configuration)
      .deleteKitchenKitchensKitchenIdDelete(kitchenId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Restaurant
   * @param {string} kitchenId
   * @param {string} brandId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
    kitchenId: string,
    brandId: string,
    options?: any
  ) {
    return KitchenManagementApiFp(this.configuration)
      .deleteRestaurantKitchensKitchenIdRestaurantBrandIdDelete(
        kitchenId,
        brandId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Kitchen
   * @param {KitchenUpdate} kitchenUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public updateKitchenKitchensPut(kitchenUpdate: KitchenUpdate, options?: any) {
    return KitchenManagementApiFp(this.configuration)
      .updateKitchenKitchensPut(kitchenUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Restaurant Hours
   * @param {string} kitchenId
   * @param {string} brandId
   * @param {DevorServiceAvailabilityUpdate} devorServiceAvailabilityUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
    kitchenId: string,
    brandId: string,
    devorServiceAvailabilityUpdate: DevorServiceAvailabilityUpdate,
    options?: any
  ) {
    return KitchenManagementApiFp(this.configuration)
      .updateRestaurantHoursKitchensKitchenIdRestaurantHoursBrandIdPut(
        kitchenId,
        brandId,
        devorServiceAvailabilityUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Restaurant
   * @param {string} kitchenId
   * @param {RestaurantUpdate} restaurantUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KitchenManagementApi
   */
  public updateRestaurantKitchensKitchenIdRestaurantPut(
    kitchenId: string,
    restaurantUpdate: RestaurantUpdate,
    options?: any
  ) {
    return KitchenManagementApiFp(this.configuration)
      .updateRestaurantKitchensKitchenIdRestaurantPut(
        kitchenId,
        restaurantUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
