/* eslint no-console: ["warn", { allow: ["info"] }] */
import { FirestoreDevorOrder } from '@/interfaces/firestore'
import { sortOrder } from '@/utils/sort'
import { buildTestOrder } from '@/utils/test-ticket-order'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Severity } from '@sentry/browser'
import { ProviderType } from '@/api-client'
import { fulfillmentTypeFormatted, moneyFormatted } from './formatting-routines'
import { IS_PROD_BUILD } from './globals'

export const ePosMessages = {
  yes: 'Yes',
  no: 'No',
  close: 'Close',
  ok: 'OK',
  line: 'Line',
  success: 'Print Success',
  failure: 'Print Failure',
  epos_code: 'Error Code',
  epos_status: 'HTTP Status Code',
  epos_error: 'Send Error',
  epos_online: 'Online',
  epos_offline: 'Offline',
  epos_poweroff: 'No Response',
  epos_coverok: 'Cover Closed',
  epos_coveropen: 'Cover Open',
  epos_paperok: 'Paper Adequate',
  epos_papernearend: 'Paper Near End',
  epos_paperend: 'Paper End',
  epos_drawerclosed: 'Drawer Closed',
  epos_draweropen: 'Drawer Open',
  epos_batterylow: 'Battery Low (TM-P60II, TM-P80, TM-P20)',
  epos_batteryok: 'Battery Adequate (TM-P60II, TM-P80, TM-P20)',
  epos_batterystatus: 'Battery Status',
  epos_send: 'Send',
  epos_open: 'Open',
  epos_close: 'Close',
  epos_jobid: 'Print Job ID',
  import_noelem: 'The root element is not found.',
  import_noattr:
    ' element has been ignored. The element requires the following attributes: ',
  import_inelem: ' element has been ignored. The element is invalid.',
  import_inattr:
    ' element has been ignored. The following attribute is invalid: ',
  import_intext: ' element has been ignored. The content is incorrect.',
  import_complete: 'The import process has been completed.',
  import_abort: 'The import process has been aborted.',
  unload: 'Data you are editing will be deleted.',
}

export type PrinterStatus = 'connected' | 'connecting' | 'disconnected'

/**
 * Matches a string to check if it is an IPv4 address.
 *
 * From: https://stackoverflow.com/a/27434991
 * @param s The string to match against
 * @returns True if `s` matches an IPv4 address.
 */
export function matchesIPv4Address(s: string): boolean {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    s
  )
}
export interface EPosT {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  printer: any | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  device: any
}

/** The category to log to Sentry breadcrumbs */
const category = 'epos'

/**
 * Connect to an ePOS printer from an address and a port.
 * @param userInteractive If false, prevents a Sentry event from being sent on a connection error
 */
export function connectPrinter(
  { address, port }: { address: string; port: string },
  ePos: EPosT,
  userInteractive = true
): Promise<void> {
  // Dismiss call if settings look incorrect
  if (
    !(
      typeof address === 'string' &&
      address.length > 0 &&
      typeof port === 'string' &&
      port.length > 0 &&
      parseInt(port) > 0
    )
  ) {
    Sentry.addBreadcrumb({
      category,
      message: 'Printer settings are invalid. Dismissing connection request...',
      data: { address, port },
    })
    console.info(
      'Dismissing printer connection request. Settings are invalid.',
      { address, port }
    )
    return Promise.resolve()
  }

  Sentry.addBreadcrumb({
    category,
    message: 'Connecting to printer...',
    data: { address, port },
  })

  return new Promise((resolve) => {
    function createDeviceCallback(
      printer: EPosT['printer'] | null,
      returnCode: string
    ) {
      if (printer !== null && returnCode === 'OK') {
        Sentry.addBreadcrumb({
          category,
          message: 'Printer is connected.',
          data: { address, port },
        })
        Sentry.setContext('printer settings', { address, port })

        printer.timeout = 30_000

        // Set up callbacks
        printer.ononline = () => {
          if (Vue.$ePos.printer !== null) Vue.$accessor.printer.setConnected()
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_online,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_online)
        }
        printer.onoffline = () => {
          Vue.$accessor.printer.setDisconnected()
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_offline,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_offline)
        }
        printer.onpoweroff = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_poweroff,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_poweroff)
        }
        printer.oncoverok = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_coverok,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_coverok)
        }
        printer.oncoveropen = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_coveropen,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_coveropen)
        }
        printer.onpaperok = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_paperok,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_paperok)
        }
        printer.onpapernearend = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_papernearend,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_papernearend)
        }
        printer.onpaperend = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_paperend,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_paperend)
        }
        printer.ondrawerclosed = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_drawerclosed,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_drawerclosed)
        }
        printer.ondraweropen = () => {
          Sentry.addBreadcrumb({
            category: category + '.event',
            message: ePosMessages.epos_draweropen,
            level: Severity.Log,
          })
          console.info(ePosMessages.epos_draweropen)
        }

        ePos.printer = printer
        Vue.$accessor.printer.setConnected()

        resolve()
      } else {
        Sentry.captureMessage('Unable to create printer.', {
          extra: { returnCode, address, port },
        })
        console.info(
          'createDeviceCallback returned: ' +
            ePosMessages.epos_error +
            ' (' +
            returnCode +
            ')'
        )
        Vue.$accessor.printer.setDisconnected()
        resolve()
      }
    }

    function connectCallback(b: string) {
      const sentryData = { address, port, callbackArgument: b, userInteractive }
      if (b == 'OK' || b == 'SSL_CONNECT_OK') {
        Sentry.addBreadcrumb({
          category,
          message: 'Connection OK.',
          data: sentryData,
          level: Severity.Log,
        })
        ePos.device.createDevice(
          'local_printer',
          ePos.device.DEVICE_TYPE_PRINTER,
          { crypto: false, buffer: false },
          // createDeviceCallback will call Vue.$accessor.printer.setConnected
          createDeviceCallback
        )
      } else {
        if (userInteractive)
          Sentry.captureMessage('Unable to connect to printer.', {
            extra: sentryData,
            level: Severity.Warning,
          })
        else
          Sentry.addBreadcrumb({
            category,
            message: 'Unable to connect to printer.',
            data: sentryData,
            level: Severity.Log,
          })
        Vue.$accessor.printer.setDisconnected()
        resolve()
      }
    }

    ePos.device.ondisconnect = () => {
      Sentry.addBreadcrumb({
        category,
        message: 'Printer was disconnected.',
        data: { address, port },
      })
      ePos.printer = null
      Vue.$accessor.printer.setDisconnected()
    }

    Vue.$accessor.printer.setConnecting()
    ePos.device.connect(address, port, connectCallback, { eposprint: true })
  })
}

const [logoWidth, logoHeight] = [576, 150]

function getLogoContext(): Promise<CanvasRenderingContext2D | null> {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    canvas.width = logoWidth
    canvas.height = logoHeight
    const context = canvas.getContext('2d')
    const base_image = new Image(400, 200)
    base_image.onload = () => {
      context?.drawImage(base_image, 0, 0, canvas.width, canvas.height)
      resolve(context)
    }
    base_image.src = require('@/assets/images/Devor_Logo_Black.svg')
  })
}

export async function sendToPrinter(order: FirestoreDevorOrder) {
  // Ensure the printer is connected
  if (
    Vue.$accessor.printer.printerStatus === 'disconnected' ||
    Vue.$ePos.printer === null
  ) {
    if (Vue.$ePos.printer === null) Vue.$accessor.printer.setDisconnected()
    console.info('Printer is disconnected. Attempting to reconnect...')
    if (Vue.$accessor.settings.printerIP) {
      await connectPrinter(
        {
          address: Vue.$accessor.settings.printerIP,
          port: Vue.$accessor.settings.printerPort,
        },
        Vue.$ePos
      )
      // Note: will throw if there was an error connecting
    } else {
      Sentry.addBreadcrumb({
        type: 'user',
        category,
        message:
          'User tried to submit print request without configuring the printer.',
      })
      console.info('IP is empty, unable to connect')
    }
  }

  Vue.$accessor.printer.notifyBusy()
  // Print order
  try {
    const getLogoContextPromise = getLogoContext()
    const extraSentryData = {
      kitchenID: order.restaurant.kitchen_id,
      orderID: order.firestoreID,
    }
    Sentry.addBreadcrumb({
      category,
      message: 'Building print request...',
      data: extraSentryData,
    })

    {
      const currentPrinter = Vue.$ePos.printer
      try {
        // Print restaurant name
        currentPrinter.addTextSize(2, 2)
        currentPrinter.addTextAlign(currentPrinter.ALIGN_CENTER)
        currentPrinter.addText(order.restaurant.short_name + '\n')

        // Print Devor Logo
        currentPrinter.brightness = 1.0
        currentPrinter.halftone = currentPrinter.HALFTONE_ERROR_DIFFUSION
        currentPrinter.addImage(
          await getLogoContextPromise,
          0,
          0,
          logoWidth,
          logoHeight,
          currentPrinter.COLOR_1,
          currentPrinter.MODE_MONO
        )

        // Add provider and delivery type
        currentPrinter.addTextSize(2, 2)
        currentPrinter.addTextAlign(currentPrinter.ALIGN_CENTER)
        let providerString: string
        switch (order.provider) {
          case ProviderType.UberEats:
            providerString = 'Uber Eats'
            break
          case ProviderType.Deliveroo:
            providerString = 'Deliveroo'
            break
          case ProviderType.JustEat:
            providerString = 'JustEat'
            break
          default: {
            const unknownProvider: never = order.provider
            Sentry.captureMessage(
              `Unexpected provider encountered: ${unknownProvider}`
            )
            providerString = '(Inconnu)'
          }
        }
        const fulfillmentString = fulfillmentTypeFormatted(
          order.fulfillment_type
        )
        currentPrinter.addText(`${providerString} - ${fulfillmentString}\n`)

        // display ID
        currentPrinter.addTextSize(5, 5)
        currentPrinter.addText('\n#' + order.display_id + '\n')
        // eater name
        if (order.eater) {
          currentPrinter.addTextSize(3, 3)
          const { first_name: firstName, last_name: lastName } = order.eater
          currentPrinter.addText(
            firstName + (lastName ? ' ' + lastName : '') + '\n\n'
          )
        }
        // times
        currentPrinter.addTextSize(1, 1)
        currentPrinter.addTextAlign(currentPrinter.ALIGN_LEFT)
        currentPrinter.addText(
          '\nCommande passée : ' +
            order.placed_at.toDate().toLocaleDateString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }) +
            '\n'
        )
        const estimatedReadyForPickupString =
          order.estimated_ready_for_pickup_at
            ? order.estimated_ready_for_pickup_at
                .toDate()
                .toLocaleDateString(undefined, {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
            : 'non transmis'
        currentPrinter.addText(
          'À préparer pour : ' + estimatedReadyForPickupString + '\n'
        )
        // items
        const ticketOrder = sortOrder(order)
        currentPrinter.addTextStyle(false, false, false, currentPrinter.COLOR_1)
        currentPrinter.addTextSize(2, 2)
        let itemCount = 0
        ticketOrder.ticketItemGroups.forEach((groupItemTitle) => {
          groupItemTitle.content.forEach((itemTitle) => {
            currentPrinter.addText(
              '\n' + itemTitle.quantity + 'x ' + itemTitle.title + '\n'
            )
            itemTitle.subItems.forEach((subItem) => {
              currentPrinter.addText(
                '\t- ' + itemTitle.quantity + 'x ' + subItem + '\n'
              )
            })
            itemCount = itemCount + itemTitle.quantity
          })
        })

        // notes
        currentPrinter.addTextSize(1, 1)
        currentPrinter.addTextStyle(false, false, false, currentPrinter.COLOR_1)
        currentPrinter.addText('\nNotes du client : ')
        currentPrinter.addTextStyle(false, false, true, currentPrinter.COLOR_1)

        if (
          ticketOrder.notes.length === 1 &&
          ticketOrder.notes[0].notes.length === 0
        ) {
          currentPrinter.addText('\nAucune note')
        } else {
          ticketOrder.notes.forEach((note) => {
            if (note.title) {
              currentPrinter.addText(
                '\n' +
                  note.quantity +
                  'x ' +
                  note.title +
                  ': ' +
                  note.notes.join(', ')
              )
            } else {
              if (note.notes.length > 0) {
                currentPrinter.addText(
                  '\nNote Globale: ' + note.notes.join(', ')
                )
              }
            }
          })
        }

        // number of items
        currentPrinter.addTextStyle(false, false, false, currentPrinter.COLOR_1)
        currentPrinter.addText('\n\nNombre de produits : ')
        currentPrinter.addTextStyle(false, false, true, currentPrinter.COLOR_1)
        currentPrinter.addText(itemCount)

        // total
        currentPrinter.addTextStyle(false, false, false, currentPrinter.COLOR_1)
        currentPrinter.addText('\nTotal commande : ')
        currentPrinter.addTextStyle(false, false, true, currentPrinter.COLOR_1)
        currentPrinter.addTextAlign(currentPrinter.ALIGN_RIGHT)
        currentPrinter.addText(moneyFormatted(order.total_price))

        // send to printer
        currentPrinter.addFeedLine(2) // Feed some lines before cutting (at least 1 is required)
        currentPrinter.addCut(currentPrinter.CUT_FEED)
        Sentry.addBreadcrumb({
          category,
          message: 'Sending print request...',
          data: extraSentryData,
        })

        await new Promise<void>((resolve) => {
          // Set callbacks
          currentPrinter.onreceive = (res: {
            success: boolean
            code: string
          }) => {
            Sentry.addBreadcrumb({
              category: category + '.print',
              message: 'Print result received.',
              data: res,
              level: Severity.Log,
            })
            if (!res.success) Sentry.captureException(new Error(res.code))
            resolve()
          }
          currentPrinter.onerror = (res: unknown, sq?: unknown) => {
            Vue.$accessor.printer.setDisconnected()
            console.info('Unable to send request to printer.')
            Sentry.captureMessage('Printer reported Error.', {
              level: Severity.Error,
              extra: { error: res, sq },
            })
            resolve()
          }

          currentPrinter.send()
        })
      } catch (error) {
        // The printer probably disconnected
        Vue.$ePos.device = null
        Vue.$accessor.printer.setDisconnected()

        if (!IS_PROD_BUILD) console.info('Unable to print. Error:', error)
        Sentry.captureException(error, {
          extra: { printedOrderFirestoreID: order.firestoreID },
        })
      }
    }
  } finally {
    Vue.$accessor.printer.notifyNotBusy()
  }
}

export async function sendTestTicket() {
  await sendToPrinter(buildTestOrder())
}
