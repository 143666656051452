




import Vue, { PropType } from 'vue'
import LogoVariant from '@/utils/enum-logo-variant.ts'

export default Vue.extend({
  name: 'Logo',
  props: {
    variant: {
      type: Number as PropType<LogoVariant>,
      default: () => LogoVariant.DARK,
    },
  },
  computed: {
    src() {
      switch (this.variant) {
        case LogoVariant.BEIGE:
          return require('@/assets/images/Devor_Logo_Beige.svg')
        case LogoVariant.DARK:
        default:
          return require('@/assets/images/Devor_Logo_Black.svg')
      }
    },
  },
})
