/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UberEatsAllergenType {
  Dairy = 'DAIRY',
  Eggs = 'EGGS',
  Fish = 'FISH',
  Shellfish = 'SHELLFISH',
  Treenuts = 'TREENUTS',
  Peanuts = 'PEANUTS',
  Gluten = 'GLUTEN',
  Soy = 'SOY',
  Other = 'OTHER',
}
