/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Meta } from './meta'

/**
 *
 * @export
 * @interface UberWebhookRequestNewOrder
 */
export interface UberWebhookRequestNewOrder {
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestNewOrder
   */
  event_id: string
  /**
   *
   * @type {Meta}
   * @memberof UberWebhookRequestNewOrder
   */
  meta: Meta
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestNewOrder
   */
  event_time: string
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestNewOrder
   */
  resource_href: string
  /**
   *
   * @type {string}
   * @memberof UberWebhookRequestNewOrder
   */
  event_type: UberWebhookRequestNewOrderEventTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum UberWebhookRequestNewOrderEventTypeEnum {
  OrdersNotification = 'orders.notification',
}
