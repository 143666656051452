import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { PrinterStatus } from '@/utils/epos-printing'

const state = (): { printerStatus: PrinterStatus; busy: boolean } => ({
  printerStatus: 'disconnected',
  busy: false,
})

const getters = getterTree(state, {
  printerStatus: (state) => state.printerStatus,
  busy: (state) => state.busy,
})

const mutations = mutationTree(state, {
  setConnected(state) {
    state.printerStatus = 'connected'
    state.busy = false // Reset busy state
  },
  setDisconnected(state) {
    state.printerStatus = 'disconnected'
    state.busy = false // Reset busy state
  },
  setConnecting(state) {
    state.printerStatus = 'connecting'
  },
  notifyBusy(state) {
    state.busy = true
  },
  notifyNotBusy(state) {
    state.busy = false
  },
})

const actions = actionTree({ state, getters, mutations }, {})

export const printer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
