/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { BrandSubscriptionInDB } from '../model'
// @ts-ignore
import { FranchiseClient } from '../model'
// @ts-ignore
import { FranchiseInDB } from '../model'
// @ts-ignore
import { FranchiseeInfoUpdate } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
// @ts-ignore
import { StoreBrandInDb } from '../model'
// @ts-ignore
import { StoreBrandRequestResponse } from '../model'
/**
 * MiddleofficeApi - axios parameter creator
 * @export
 */
export const MiddleofficeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get Associated Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesFullFranchiseesAssociatedGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees/associated`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Associated Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesLightFranchiseesAssociatedLightGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/franchisees/associated/light`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return all store brands available to Middleoffice users.
     * @summary Get Available Store Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableStoreBrandsBrandsStoreAvailableGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands-store/available`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Return all requests to subscribe on a brand for the user\'s franchisee.
     * @summary Get Franchisee Store Brand Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/brands-store/requests/franchisee`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Request a subscription to a store brand, and send an email to the emails registered for the Store brand.  If the sending of the email fails, this endpoint will return a 206 status code.
     * @summary Request Store Brand For Kitchen
     * @param {string} storeBrandId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost: async (
      storeBrandId: string,
      kitchenId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeBrandId' is not null or undefined
      assertParamExists(
        'requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost',
        'storeBrandId',
        storeBrandId
      )
      // verify required parameter 'kitchenId' is not null or undefined
      assertParamExists(
        'requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost',
        'kitchenId',
        kitchenId
      )
      const localVarPath = `/brands-store/{store_brand_id}/request/{kitchen_id}`
        .replace(
          `{${'store_brand_id'}}`,
          encodeURIComponent(String(storeBrandId))
        )
        .replace(`{${'kitchen_id'}}`, encodeURIComponent(String(kitchenId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update Franchisee From Middleoffice
     * @param {string} franchiseeId The ID of the franchisee
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut: async (
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'franchiseeId' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut',
        'franchiseeId',
        franchiseeId
      )
      // verify required parameter 'franchiseeInfoUpdate' is not null or undefined
      assertParamExists(
        'updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut',
        'franchiseeInfoUpdate',
        franchiseeInfoUpdate
      )
      const localVarPath = `/franchisees/{franchisee_id}/me`.replace(
        `{${'franchisee_id'}}`,
        encodeURIComponent(String(franchiseeId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        franchiseeInfoUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MiddleofficeApi - functional programming interface
 * @export
 */
export const MiddleofficeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MiddleofficeApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Get Associated Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociatedFranchiseesFullFranchiseesAssociatedGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseClient>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedFranchiseesFullFranchiseesAssociatedGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Associated Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<FranchiseInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return all store brands available to Middleoffice users.
     * @summary Get Available Store Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableStoreBrandsBrandsStoreAvailableGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreBrandInDb>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableStoreBrandsBrandsStoreAvailableGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Return all requests to subscribe on a brand for the user\'s franchisee.
     * @summary Get Franchisee Store Brand Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BrandSubscriptionInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * Request a subscription to a store brand, and send an email to the emails registered for the Store brand.  If the sending of the email fails, this endpoint will return a 206 status code.
     * @summary Request Store Brand For Kitchen
     * @param {string} storeBrandId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
      storeBrandId: string,
      kitchenId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<StoreBrandRequestResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
        storeBrandId,
        kitchenId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Update Franchisee From Middleoffice
     * @param {string} franchiseeId The ID of the franchisee
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * MiddleofficeApi - factory interface
 * @export
 */
export const MiddleofficeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MiddleofficeApiFp(configuration)
  return {
    /**
     *
     * @summary Get Associated Franchisees Full
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesFullFranchiseesAssociatedGet(
      options?: any
    ): AxiosPromise<Array<FranchiseClient>> {
      return localVarFp
        .getAssociatedFranchiseesFullFranchiseesAssociatedGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Associated Franchisees Light
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
      options?: any
    ): AxiosPromise<Array<FranchiseInDB>> {
      return localVarFp
        .getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return all store brands available to Middleoffice users.
     * @summary Get Available Store Brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableStoreBrandsBrandsStoreAvailableGet(
      options?: any
    ): AxiosPromise<Array<StoreBrandInDb>> {
      return localVarFp
        .getAvailableStoreBrandsBrandsStoreAvailableGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Return all requests to subscribe on a brand for the user\'s franchisee.
     * @summary Get Franchisee Store Brand Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(
      options?: any
    ): AxiosPromise<Array<BrandSubscriptionInDB>> {
      return localVarFp
        .getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Request a subscription to a store brand, and send an email to the emails registered for the Store brand.  If the sending of the email fails, this endpoint will return a 206 status code.
     * @summary Request Store Brand For Kitchen
     * @param {string} storeBrandId
     * @param {string} kitchenId The ID of the kitchen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
      storeBrandId: string,
      kitchenId: string,
      options?: any
    ): AxiosPromise<StoreBrandRequestResponse> {
      return localVarFp
        .requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
          storeBrandId,
          kitchenId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update Franchisee From Middleoffice
     * @param {string} franchiseeId The ID of the franchisee
     * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
      franchiseeId: string,
      franchiseeInfoUpdate: FranchiseeInfoUpdate,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
          franchiseeId,
          franchiseeInfoUpdate,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * MiddleofficeApi - interface
 * @export
 * @interface MiddleofficeApi
 */
export interface MiddleofficeApiInterface {
  /**
   *
   * @summary Get Associated Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApiInterface
   */
  getAssociatedFranchiseesFullFranchiseesAssociatedGet(
    options?: any
  ): AxiosPromise<Array<FranchiseClient>>

  /**
   *
   * @summary Get Associated Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApiInterface
   */
  getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
    options?: any
  ): AxiosPromise<Array<FranchiseInDB>>

  /**
   * Return all store brands available to Middleoffice users.
   * @summary Get Available Store Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApiInterface
   */
  getAvailableStoreBrandsBrandsStoreAvailableGet(
    options?: any
  ): AxiosPromise<Array<StoreBrandInDb>>

  /**
   * Return all requests to subscribe on a brand for the user\'s franchisee.
   * @summary Get Franchisee Store Brand Requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApiInterface
   */
  getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(
    options?: any
  ): AxiosPromise<Array<BrandSubscriptionInDB>>

  /**
   * Request a subscription to a store brand, and send an email to the emails registered for the Store brand.  If the sending of the email fails, this endpoint will return a 206 status code.
   * @summary Request Store Brand For Kitchen
   * @param {string} storeBrandId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApiInterface
   */
  requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
    storeBrandId: string,
    kitchenId: string,
    options?: any
  ): AxiosPromise<StoreBrandRequestResponse>

  /**
   *
   * @summary Update Franchisee From Middleoffice
   * @param {string} franchiseeId The ID of the franchisee
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApiInterface
   */
  updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ): AxiosPromise<void>
}

/**
 * MiddleofficeApi - object-oriented interface
 * @export
 * @class MiddleofficeApi
 * @extends {BaseAPI}
 */
export class MiddleofficeApi
  extends BaseAPI
  implements MiddleofficeApiInterface {
  /**
   *
   * @summary Get Associated Franchisees Full
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApi
   */
  public getAssociatedFranchiseesFullFranchiseesAssociatedGet(options?: any) {
    return MiddleofficeApiFp(this.configuration)
      .getAssociatedFranchiseesFullFranchiseesAssociatedGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Associated Franchisees Light
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApi
   */
  public getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(
    options?: any
  ) {
    return MiddleofficeApiFp(this.configuration)
      .getAssociatedFranchiseesLightFranchiseesAssociatedLightGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return all store brands available to Middleoffice users.
   * @summary Get Available Store Brands
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApi
   */
  public getAvailableStoreBrandsBrandsStoreAvailableGet(options?: any) {
    return MiddleofficeApiFp(this.configuration)
      .getAvailableStoreBrandsBrandsStoreAvailableGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Return all requests to subscribe on a brand for the user\'s franchisee.
   * @summary Get Franchisee Store Brand Requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApi
   */
  public getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(
    options?: any
  ) {
    return MiddleofficeApiFp(this.configuration)
      .getFranchiseeStoreBrandRequestsBrandsStoreRequestsFranchiseeGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Request a subscription to a store brand, and send an email to the emails registered for the Store brand.  If the sending of the email fails, this endpoint will return a 206 status code.
   * @summary Request Store Brand For Kitchen
   * @param {string} storeBrandId
   * @param {string} kitchenId The ID of the kitchen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApi
   */
  public requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
    storeBrandId: string,
    kitchenId: string,
    options?: any
  ) {
    return MiddleofficeApiFp(this.configuration)
      .requestStoreBrandForKitchenBrandsStoreStoreBrandIdRequestKitchenIdPost(
        storeBrandId,
        kitchenId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update Franchisee From Middleoffice
   * @param {string} franchiseeId The ID of the franchisee
   * @param {FranchiseeInfoUpdate} franchiseeInfoUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiddleofficeApi
   */
  public updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
    franchiseeId: string,
    franchiseeInfoUpdate: FranchiseeInfoUpdate,
    options?: any
  ) {
    return MiddleofficeApiFp(this.configuration)
      .updateFranchiseeFromMiddleofficeFranchiseesFranchiseeIdMePut(
        franchiseeId,
        franchiseeInfoUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
