/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base'
// @ts-ignore
import { GroupingTagCreate } from '../model'
// @ts-ignore
import { GroupingTagInDB } from '../model'
// @ts-ignore
import { HTTPValidationError } from '../model'
/**
 * GroupingTagsApi - axios parameter creator
 * @export
 */
export const GroupingTagsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create Grouping Tag
     * @param {GroupingTagCreate} groupingTagCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGroupingTagKitchenMenusGroupingTagsPost: async (
      groupingTagCreate: GroupingTagCreate,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagCreate' is not null or undefined
      assertParamExists(
        'createGroupingTagKitchenMenusGroupingTagsPost',
        'groupingTagCreate',
        groupingTagCreate
      )
      const localVarPath = `/kitchen-menus/grouping-tags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        groupingTagCreate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete: async (
      groupingTagId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagId' is not null or undefined
      assertParamExists(
        'deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete',
        'groupingTagId',
        groupingTagId
      )
      const localVarPath = `/kitchen-menus/grouping-tags/{grouping_tag_id}`.replace(
        `{${'grouping_tag_id'}}`,
        encodeURIComponent(String(groupingTagId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagGroupingTagsGroupingTagIdGet: async (
      groupingTagId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagId' is not null or undefined
      assertParamExists(
        'getGroupingTagGroupingTagsGroupingTagIdGet',
        'groupingTagId',
        groupingTagId
      )
      const localVarPath = `/grouping-tags/{grouping_tag_id}`.replace(
        `{${'grouping_tag_id'}}`,
        encodeURIComponent(String(groupingTagId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet: async (
      groupingTagId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupingTagId' is not null or undefined
      assertParamExists(
        'getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet',
        'groupingTagId',
        groupingTagId
      )
      const localVarPath = `/kitchen-menus/grouping-tags/{grouping_tag_id}`.replace(
        `{${'grouping_tag_id'}}`,
        encodeURIComponent(String(groupingTagId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagsGroupingTagsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/grouping-tags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagsKitchenMenusGroupingTagsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/kitchen-menus/grouping-tags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GroupingTagsApi - functional programming interface
 * @export
 */
export const GroupingTagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GroupingTagsApiAxiosParamCreator(
    configuration
  )
  return {
    /**
     *
     * @summary Create Grouping Tag
     * @param {GroupingTagCreate} groupingTagCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGroupingTagKitchenMenusGroupingTagsPost(
      groupingTagCreate: GroupingTagCreate,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupingTagInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupingTagKitchenMenusGroupingTagsPost(
        groupingTagCreate,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Delete Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
      groupingTagId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
        groupingTagId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupingTagGroupingTagsGroupingTagIdGet(
      groupingTagId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupingTagInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupingTagGroupingTagsGroupingTagIdGet(
        groupingTagId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
      groupingTagId: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupingTagInDB>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
        groupingTagId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupingTagsGroupingTagsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<GroupingTagInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupingTagsGroupingTagsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupingTagsKitchenMenusGroupingTagsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<GroupingTagInDB>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupingTagsKitchenMenusGroupingTagsGet(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * GroupingTagsApi - factory interface
 * @export
 */
export const GroupingTagsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GroupingTagsApiFp(configuration)
  return {
    /**
     *
     * @summary Create Grouping Tag
     * @param {GroupingTagCreate} groupingTagCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGroupingTagKitchenMenusGroupingTagsPost(
      groupingTagCreate: GroupingTagCreate,
      options?: any
    ): AxiosPromise<GroupingTagInDB> {
      return localVarFp
        .createGroupingTagKitchenMenusGroupingTagsPost(
          groupingTagCreate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
      groupingTagId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
          groupingTagId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagGroupingTagsGroupingTagIdGet(
      groupingTagId: string,
      options?: any
    ): AxiosPromise<GroupingTagInDB> {
      return localVarFp
        .getGroupingTagGroupingTagsGroupingTagIdGet(groupingTagId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Grouping Tag
     * @param {string} groupingTagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
      groupingTagId: string,
      options?: any
    ): AxiosPromise<GroupingTagInDB> {
      return localVarFp
        .getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
          groupingTagId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagsGroupingTagsGet(
      options?: any
    ): AxiosPromise<Array<GroupingTagInDB>> {
      return localVarFp
        .getGroupingTagsGroupingTagsGet(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Grouping Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupingTagsKitchenMenusGroupingTagsGet(
      options?: any
    ): AxiosPromise<Array<GroupingTagInDB>> {
      return localVarFp
        .getGroupingTagsKitchenMenusGroupingTagsGet(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GroupingTagsApi - interface
 * @export
 * @interface GroupingTagsApi
 */
export interface GroupingTagsApiInterface {
  /**
   *
   * @summary Create Grouping Tag
   * @param {GroupingTagCreate} groupingTagCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApiInterface
   */
  createGroupingTagKitchenMenusGroupingTagsPost(
    groupingTagCreate: GroupingTagCreate,
    options?: any
  ): AxiosPromise<GroupingTagInDB>

  /**
   *
   * @summary Delete Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApiInterface
   */
  deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
    groupingTagId: string,
    options?: any
  ): AxiosPromise<void>

  /**
   *
   * @summary Get Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApiInterface
   */
  getGroupingTagGroupingTagsGroupingTagIdGet(
    groupingTagId: string,
    options?: any
  ): AxiosPromise<GroupingTagInDB>

  /**
   *
   * @summary Get Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApiInterface
   */
  getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
    groupingTagId: string,
    options?: any
  ): AxiosPromise<GroupingTagInDB>

  /**
   *
   * @summary Get Grouping Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApiInterface
   */
  getGroupingTagsGroupingTagsGet(
    options?: any
  ): AxiosPromise<Array<GroupingTagInDB>>

  /**
   *
   * @summary Get Grouping Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApiInterface
   */
  getGroupingTagsKitchenMenusGroupingTagsGet(
    options?: any
  ): AxiosPromise<Array<GroupingTagInDB>>
}

/**
 * GroupingTagsApi - object-oriented interface
 * @export
 * @class GroupingTagsApi
 * @extends {BaseAPI}
 */
export class GroupingTagsApi
  extends BaseAPI
  implements GroupingTagsApiInterface {
  /**
   *
   * @summary Create Grouping Tag
   * @param {GroupingTagCreate} groupingTagCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApi
   */
  public createGroupingTagKitchenMenusGroupingTagsPost(
    groupingTagCreate: GroupingTagCreate,
    options?: any
  ) {
    return GroupingTagsApiFp(this.configuration)
      .createGroupingTagKitchenMenusGroupingTagsPost(groupingTagCreate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApi
   */
  public deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
    groupingTagId: string,
    options?: any
  ) {
    return GroupingTagsApiFp(this.configuration)
      .deleteGroupingTagKitchenMenusGroupingTagsGroupingTagIdDelete(
        groupingTagId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApi
   */
  public getGroupingTagGroupingTagsGroupingTagIdGet(
    groupingTagId: string,
    options?: any
  ) {
    return GroupingTagsApiFp(this.configuration)
      .getGroupingTagGroupingTagsGroupingTagIdGet(groupingTagId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Grouping Tag
   * @param {string} groupingTagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApi
   */
  public getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
    groupingTagId: string,
    options?: any
  ) {
    return GroupingTagsApiFp(this.configuration)
      .getGroupingTagKitchenMenusGroupingTagsGroupingTagIdGet(
        groupingTagId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Grouping Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApi
   */
  public getGroupingTagsGroupingTagsGet(options?: any) {
    return GroupingTagsApiFp(this.configuration)
      .getGroupingTagsGroupingTagsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Grouping Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupingTagsApi
   */
  public getGroupingTagsKitchenMenusGroupingTagsGet(options?: any) {
    return GroupingTagsApiFp(this.configuration)
      .getGroupingTagsKitchenMenusGroupingTagsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
