/* tslint:disable */
/* eslint-disable */
/**
 * Devor Aggregator Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0rc28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Enumeration of cancellation reasons.  Those are the same as Uber Eats, but lowercase for coherence.
 * @export
 * @enum {string}
 */
export enum DevorCancellationReason {
  OutOfItems = 'out_of_items',
  KitchenClosed = 'kitchen_closed',
  CustomerCalledToCancel = 'customer_called_to_cancel',
  RestaurantTooBusy = 'restaurant_too_busy',
  CannotCompleteCustomerNote = 'cannot_complete_customer_note',
  Other = 'other',
}
